import React, {useState} from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, StyledMap} from '@react-google-maps/api';
import reactHtmlParser from "react-html-parser";
import {useLocation} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Button from "../Button";


const GoogleMapsComponent = ({data, long, lat}) => {

    // const mapdata = data?.posts?.list?.find(f => f.data?.slug === 'maps')

    const title = "Sheltech";
    const description = "Sheltech Tower,\n" +
        "60 Sheikh Russel Square, West Panthapath,\n" +
        "Dhaka-1205, Bangladesh"
    const location = useLocation();


    const mapStyles = {
        height: '100%',
        width: '100%',
    };

    const defaultCenter = {
        lat: lat ? Number(lat) : 23.752389042120637, // Replace with marker latitude
        lng: long ? Number(long) : 90.38094146884751, // Replace with marker longitude
    };

    const [selectedMarker, setSelectedMarker] = useState(null);


    const markers =
        [
            {
                id: 1,
                position: {lat: lat ? Number(lat) : 23.752389042120637, lng: long ? Number(long) : 90.38094146884751},
                icon: '/images/static/map.svg', // Replace with the path to your icon image
            },
            // Add more markers if needed
        ]


    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
    };


    const customMapStyle = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]; // Paste your copied JSON style here

    const mapOptions = {
        styles: customMapStyle,
    };

    const [mapLoaded, setMapLoaded] = useState(false);

    const handleMapButtonClick = () => {
        setMapLoaded(true);
    };

    return (
        <>
            {!mapLoaded && (
                <div className={"map-overlay"}>
                    <div className="map-overlay__btn" onClick={handleMapButtonClick} style={{width: "fit-content"}}>
                        <Button margin={"0"} text={"Click to load the map"} src={'#'}/>
                    </div>
                    {/*// <button onClick={handleMapButtonClick}>Load Map</button>*/}
                </div>

            )}
            {
                mapLoaded ?
                    <>
                        <LoadScript googleMapsApiKey="AIzaSyB3nEqKsliw_EQSpaxAq6R8hOUwpGyxJ9s"
                                    onLoad={() => console.log('Google Maps API loaded successfully')}
                                    onError={(error) => console.error('Error Laoding Google Map', error)}
                        >
                            <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={14}
                                center={defaultCenter}
                                options={mapOptions}

                            >
                                {markers.map((marker) => (
                                    <Marker
                                        key={marker.id}
                                        position={marker.position}
                                        icon={marker.icon}
                                        onClick={() => handleMarkerClick(marker)}
                                    />
                                ))}

                                {selectedMarker && (
                                    <InfoWindow
                                        position={selectedMarker.position}
                                        onCloseClick={handleInfoWindowClose}
                                    >
                                        <div className={"map-pupup"}>

                                            <div>
                                                {/*<p>{reactHtmlParser(title)}</p>*/}
                                                <p>{reactHtmlParser(data?.short_desc ? data?.short_desc : description)}</p>
                                            </div>


                                        </div>
                                    </InfoWindow>
                                )}
                            </GoogleMap>
                        </LoadScript>
                    </>
                    : null
            }
        </>

    );
};


export default React.memo(GoogleMapsComponent)