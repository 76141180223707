import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import {title} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'mission-vision pt-150 no-line'}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <div className="mission-vision__img">
                            <Img src={data?.posts?.list?.[0]?.images?.[0]?.full_path}/>
                        </div>
                    </Col>
                    <Col lg={5} className={'mission-vision__text'}>
                        <div className="mission-vision__text__content">
                            <h4>{reactHtmlParser(data?.posts?.list?.[0]?.data?.title)}</h4>
                            <p>{reactHtmlParser(data?.posts?.list?.[0]?.data?.description)}</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={7}>
                        <div className="mission-vision__img">
                            <Img src={data?.posts?.list?.[1]?.images?.[0]?.full_path}/>
                        </div>
                    </Col>
                    <Col lg={5} className={'mission-vision__text'}>
                        <div className="mission-vision__text__content">
                            <h4>{reactHtmlParser(data?.posts?.list?.[1]?.data?.title)}</h4>
                            <p>{reactHtmlParser(data?.posts?.list?.[1]?.data?.description)}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .mission-vision__img {
    padding-top: calc(470 / 670 * 100%);
    position: relative;
    margin-top: 40px;
  }

  .mission-vision__text {
    padding-left: 70px;


    h4 {
      font-size: 36px;
      line-height: 44px;
      font-family: ${title};
      margin-bottom: 30px;
      text-transform: uppercase;

    }

    p {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .row {
    &:nth-of-type(even) {
      flex-direction: row-reverse;
      transform: translateY(-25%);

      .mission-vision__img {
        margin-top: 0;
        margin-bottom: 40px;
      }

      .mission-vision__text {
        padding-left: 15px;
        padding-right: 70px;
        display: flex;
        align-items: end;
      }
    }
  }

  @media (max-width: 991px) {
    .row {
      transform: none !important;
      flex-direction: column-reverse !important;

      &:nth-of-type(1) {
        margin-bottom: 40px;
      }

      &:nth-of-type(2) {
        .mission-vision__img {
          margin-top: 40px;
        }
      }
    }

    .mission-vision__text {
      padding-left: 15px !important;
      padding-right: 15px !important;

      &__content {
        h4 {
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }
`;


export default MyComponent;
