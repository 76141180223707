import {Redirect} from 'react-router-dom';

export const RequireAuth = ({children}) => {
    const geToken = localStorage.getItem('sheltechuser')
    if (geToken) {
        return <Redirect to={'/'}/>
    }
    return children
}

