import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {hover, text, whites} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import CloseButton from "../CloseButton";
import SimpleBar from "simplebar-react";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    const subtitle = data?.data?.data?.page_data?.description;
    const list = data?.data?.data?.sections?.find(f => f.section_data?.id == 117).posts?.list;
    const Banner = data?.data?.data?.sections?.find(f => f.section_data?.id == 117).images?.list[0]?.full_path;

    const [modalData, setModalData] = useState(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setModalData(e)
    }

    // const List = getPost?.data?.data?.sections?.find(f => f.section_data?.id == 117);

    return (
        <StyledComponent className={'make-best pt-120 land-slider'}>

            <Container>
                <Title margin={'0 0 80px 0'} center
                       text={subtitle}/>
            </Container>

            <div className="make-best__inner">
                <Img src={Banner ? Banner : '/images/dynamic/land-bg.jpg'}/>
                <Container>
                    {list && list?.length > 0 &&
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                slidesPerGroup={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                            // allowTouchMove={false}
                                speed={900}
                                infinity={true}
                                pagination={{
                                    clickable: true
                                }}
                                navigation={{
                                    prevEl: '.slider_prev',
                                    nextEl: '.slider_next ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}

                                breakpoints={{
                                    1150: {
                                        slidesPerGroup: 3,
                                        slidesPerView: 5,
                                        spaceBetween: 30,
                                        loop: false,
                                    },
                                    950: {
                                        slidesPerGroup: 3,
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },

                                    600: {
                                        slidesPerGroup: 3,
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },


                                }}>
                            >
                            {
                                list && list?.length > 0 && list?.map((item) => {
                                    return (
                                        <SwiperSlide>
                                            <div onClick={() => handleShow(item)} className="make-best__slider">
                                                <div className="make-best__slider__content">
                                                    <img src={item?.images?.[0]?.full_path} alt=""/>
                                                    <p>{item?.data?.title}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    }
                </Container>
            </div>


            <Modal className={'team-modal as-landowner'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>
                <SimpleBar style={{maxHeight: window.innerHeight - 150 + 'px'}}>
                    <div className="team-modal__content">
                        {/*<img src={'img'} alt=""/>*/}
                        <h4>{modalData?.data?.title}</h4>
                        {/*<h5>{'deg'}</h5>*/}
                        <p>{reactHtmlParser(modalData?.data?.description)}</p>

                    </div>
                </SimpleBar>

            </Modal>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: ${whites};

  .make-best__inner {
    position: relative;

    .container {
      padding-top: 150px;
      padding-bottom: 150px;
    }
  }

  .make-best__slider {
    background-color: rgb(0 0 0 / 10%);
    backdrop-filter: blur(10px);
    transition: .6s ease;
    position: relative;
    transition-delay: .2s;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      top: 0;
      width: 0%;
      left: 0;
      background-color: #fff;
      transition: all 150ms ease;
      transition-delay: 450ms;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0%;
      top: 0;
      width: 2px;
      right: 0;
      background-color: #fff;
      transition: all 150ms linear;
      transition-delay: 300ms;
      z-index: 2;
    }

    &__content {
      padding-top: calc(250 / 210 * 100%);
      position: relative;
      background-color: transparent;


      &:before {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        width: 0%;
        right: 0;
        background-color: #fff;
        transition: all 150ms ease;
        transition-delay: 150ms;
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        height: 0%;
        bottom: 0;
        width: 2px;
        left: 0;
        background-color: #fff;
        transition: all 150ms ease;
        transition-delay: 0s;
        z-index: 2;
      }


      img {
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0;
        margin: auto;
        transition: .4s ease;
        height: 70px;
      }

      p {
        position: absolute;
        bottom: 20px;
        left: 15px;
        right: 15px;
        font-size: 18px;
        line-height: 20px;
        color: ${whites};
        text-align: center;
      }
    }

    //hover
    &:hover {
      background-color: rgba(255, 255, 255, 0.75);

      &:before {
        width: 100%;
        transition-delay: 0s;
      }

      &:after {
        height: 100%;
        transition-delay: 150ms;
      }

      .make-best__slider__content {


        img {
          filter: invert(100%) sepia(5%) saturate(21%) hue-rotate(64deg) brightness(104%) contrast(104%);
        }

        p {
          color: ${text};
          transition: .5s ease;
        }

        &:before {
          width: 100%;
          transition-delay: 300ms;
        }

        &:after {
          height: 100%;
          transition-delay: 450ms;
        }
      }
    }
  }

  .swiper {
    padding-bottom: 30px;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: .5;
    height: 5px;
    padding: 0;
    border-radius: 2px;
    width: 20px;
    margin-right: 20px !important;
    @media (max-width: 600px) {
      width: 15px;
      margin-right: 10px !important;
    }

    &.swiper-pagination-bullet-active {
      width: 35px;
      opacity: 1;
      @media (max-width: 600px) {
        width: 25px;
      }
    }
  }

  @media (max-width: 600px) {
    .container {
      //padding-right: 0;
    }

    .make-best__slider__content img {
      top: 60px;
    }

    .swiper {
      padding-right: 100px;
    }

    padding-top: 50px;
    .swiper-pagination {
      text-align: left;
    }

    .make-best__inner .container {
      padding-bottom: 90px;
      padding-top: 80px;
    }
  }
`;

export default MyComponent;
