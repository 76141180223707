import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    data: [],
    error: '',
    management: [],
}


export const fetchManagement = createAsyncThunk("fetchManagement", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'management',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchManagement.pending, (state) => {
            state.loading = true
            state.management = []
            state.error = ''
        })
        builder.addCase(fetchManagement.fulfilled, (state, action) => {
            state.loading = false
            state.management = action.payload
            state.error = ''
        })
        builder.addCase(fetchManagement.rejected, (state, action) => {
            state.loading = false
            state.management = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
