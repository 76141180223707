import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import "lightgallery.js/dist/css/lightgallery.css";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";

const MyComponent = ({data, id}) => {

    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img alt='' src={image} style={{width: "100%"}}/>
        </LightgalleryItem>
    );


    const gallery_img = data && data?.images;

    return (
        <StyledComponent id={id} className={'gallery pt-200 '}>
            <Title text={'gallery'} margin={'0 0 80px 0'} center/>
            <div className="gallery__items">
                {gallery_img?.length > 0 &&
                    <LightgalleryProvider
                        lightgallerySettings={
                            {
                                download: false,
                                thumbnail: false,
                                fullScreen: true,
                                share: false
                            }
                        }
                    >
                        {gallery_img?.length > 0 &&
                            <>
                                {
                                    gallery_img?.length > 0 && gallery_img.map((item, index) => (
                                        <LightgalleryItem key={index} group={'any'} src={item?.full_path}>
                                            <div className="gallery-single">
                                                <img alt={item?.full_path} src={item?.full_path}
                                                     style={{width: "100%"}}/>
                                            </div>
                                        </LightgalleryItem>
                                    ))
                                }
                            </>

                        } </LightgalleryProvider>
                }


            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #D6D6D6;

    .gallery__items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -2.5px;
        margin-right: -2.5px;

        img {
            width: 100%;
            object-fit: cover;
        }

        .react_lightgallery_item {
            width: 25%;
            position: relative;
            cursor: pointer;
            padding: 2.5px;
        }
    }


    .gallery-single {
        padding-top: 100%;
        position: relative;

        img {
            position: absolute;
            inset: 0;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    @media (max-width: 767px) {
        .gallery__items .react_lightgallery_item {
            width: 50%;
        }
    }
`;

export default MyComponent;
