import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import ProjectList from "../../components/project/ProjectList";
import InnerBanner from "../../components/InnerBanner";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";

import {title} from "../../styles/globalStyleVars";

const MyComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let getPost = useSelector((state) => state.projectReducer);

  // api config
  const endPoints = apiEndPoints.PROJECT;

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  // console.log("query", query.get("type"));
    const pageData = getPost?.data?.project_data?.page_data;

  return (
    <HelmetProvider>
      <Helmet>
          <meta charSet="utf-8"/>
          <meta property="og:title" content={pageData?.og_title}/>
          <meta property="og:description" content={pageData?.og_description}/>
          <meta name="description" content={pageData?.meta_description}/>
          <title>
              {pageData?.meta_title ? pageData?.meta_title : 'Projects | Sheltech Pvt Ltd.'}
          </title>
      </Helmet>

        <StyledComponent className={"project-page"}>
        <InnerBanner
          img={
            query.get("type") === "commercial"
              ? getPost?.data?.project_data?.page_images?.list?.find(
                    (f) => f.banner === "on"
                )?.full_path
              : getPost?.data?.project_data?.page_images?.list?.find(
                    (f) => f.desktop === "on"
                )?.full_path
          }
          subTitle={
              query.get("type") === "commercial"
                  ? getPost?.data?.project_data?.page_images?.list?.find(
                      (f) => f.banner === "on"
                  )?.short_title
                  : getPost?.data?.project_data?.page_images?.list?.find(
                      (f) => f.desktop === "on"
                  )?.short_title
          }
          title={
              query.get("type") === "commercial"
                  ? getPost?.data?.project_data?.page_images?.list?.find(
                      (f) => f.banner === "on"
                  )?.short_desc
                  : getPost?.data?.project_data?.page_images?.list?.find(
                      (f) => f.desktop === "on"
                  )?.short_desc
          }
        />
        <ProjectList data={getPost} />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  @media (max-width: 600px) {
    .InnerBanner {
      padding-top: calc(100%);
    }
  }

  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
