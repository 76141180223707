import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Title from "../Title";
import { Img } from "../Img";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import SliderNextWhite from "../SliderNextWhite";
import SliderPrevWhite from "../SliderPrevWhite";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import reactHtmlParser from "react-html-parser";
import CloseButton from "../CloseButton";
import SimpleBar from "simplebar-react";

const MyComponent = ({ data }) => {
  const swiperScroll = useRef(null);

  useEffect(() => {
    const sectionTop = document.querySelector(".awards").offsetTop;
    window.addEventListener("scroll", () => {
      if (window.pageYOffset >= sectionTop) {
        // swiperScroll.current.autoplay.stop()
        setTimeout(() => {
          swiperScroll.current.swiper.autoplay.start();
        }, 300);
      }
    });
  }, [data]);

  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(document.querySelector(".container").offsetLeft + 15);
    window.addEventListener("resize", () => {
      setOffset(document.querySelector(".container").offsetLeft + 15);
    });
  }, []);

  const [modalData, setModalData] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (i, e) => {
    setShow(true);
    setModalData(i, e);
  };

  return (
    <StyledComponent offset={offset} className={`awards pt-150 pb-150 no-line`}>
      <Container>
        <div className="awards__top">
          <Title text={data?.section_data?.subtitle} />
          <ul>
            <li className={"slider_prev"}>
              <SliderPrevWhite
                borderColor={"#08082B"}
                color={"#08082B"}
                hoverBackground={"#08082B"}
                hoverColor={"#FFF"}
              />
            </li>
            <li className={"slider_next"}>
              <SliderNextWhite
                borderColor={"#08082B"}
                color={"#08082B"}
                hoverBackground={"#08082B"}
                hoverColor={"#FFF"}
              />
            </li>
          </ul>
        </div>
      </Container>

      <Container
        fluid
        className={"pr-0"}
        style={{ paddingLeft: offset + "px" }}
      >
        {data?.posts?.list?.length > 0 && (
          <Swiper
            loop={false}
            spaceBetween={30}
            slidesPerView={1}
            allowSlideNext={true}
            allowSlidePrev={true}
            ref={swiperScroll}
            // allowTouchMove={false}
            speed={900}
            // pagination={{
            //     type: "fraction",
            // }}
            navigation={{
              prevEl: ".awards .slider_prev",
              nextEl: ".awards .slider_next ",
            }}
            modules={[Autoplay, Pagination, Navigation]}
            // autoplay={{
            //     delay: 1000,
            //     autoPlay: false
            // }}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
          >
            {data?.posts?.list?.map((i) => (
              <SwiperSlide>
                <div
                  className="awards__slider"
                  onClick={() => handleShow(i, i?.images?.[0]?.full_path)}
                >
                  <div className="awards__slider__item">
                    <Img src={i?.images?.[0]?.full_path} />
                  </div>
                  <p>{reactHtmlParser(i?.data?.title)}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Container>

      <Modal
        className={"team-modal as-landowner as-awards"}
        show={show}
        onHide={handleClose}
      >
        <div onClick={handleClose}>
          <CloseButton />
        </div>
        <SimpleBar style={{ maxHeight: window.innerHeight - 150 + "px" }}>
          <div className="team-modal__content">
            <img src={modalData?.images?.[0]?.full_path} alt="" />
            <h4>{modalData?.data?.title}</h4>
            {/*<h5>{'deg'}</h5>*/}
            <p>{reactHtmlParser(modalData?.data?.description)}</p>
          </div>
        </SimpleBar>
      </Modal>
    </StyledComponent>
  );
};
const StyledComponent = styled.section`
  background-color: #d6d6d6;

  .awards__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    ul {
      display: flex;
    }
  }

  .awards__slider {
    width: 100%;
    cursor: pointer;

    &__item {
      padding-top: calc(230 / 270 * 100%);
      position: relative;
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;

      img {
        object-fit: contain;
        margin: auto;
        max-width: calc(100% - 40px);
      }
    }

    p {
      //position: absolute;
      //inset: 0;
      //width: 64%;
      //margin: auto;
      //height: fit-content;
      //text-align: center;
      font-weight: bold;
      margin-top: 15px;
    }
  }

  .swiper {
    padding-right: ${(p) => p.offset}px;
  }

  .container-fluid {
    position: relative;
    /* padding-bottom: 200px; */
    /* &:after {
      content: "";
      background-color: rgb(245, 245, 245);
      bottom: 0px;
      left: 0;
      width: 100%;
      height: calc(50% + 100px);
      position: absolute;
    } */
  }

  @media (max-width: 990px) {
    .awards__top {
      flex-wrap: wrap;

      ul {
        width: 100%;
      }
    }
  }

  @media (max-width: 500px) {
    .swiper {
      padding-right: 80px;
    }
  }
`;
export default MyComponent;
