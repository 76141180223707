import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, whites} from "../../styles/globalStyleVars";
import Project from "../Project";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import SliderPrevWhite from "../SliderPrevWhite";
import SliderNextWhite from "../SliderNextWhite";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";

const MyComponent = ({data, list}) => {

    const [offset, setOffset] = useState(0)
    // offset
    useEffect(() => {
        setOffset(document.querySelector('.container')?.offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container')?.offsetLeft + 15)
        })

    }, [])
    

    return (
        <StyledComponent offset={offset} className={'sapphire pt-200'}>
            <Container className={'sapphire__top'}>
                <Row>
                    <Col md={{span: 8, offset: 2}} className={'text-center'}>
                        <img src={data?.images?.list?.[0]?.full_path} alt=""/>
                        <p className={'split-up'}>{reactHtmlParser(data?.section_data?.description)}</p>
                        <Button margin={' 30px auto 0 auto'} src={'/projects/sapphire-series?area=All&'}
                                text={'Explore'}/>
                    </Col>
                </Row>

            </Container>
            <Container fluid className={'p-0 sapphire__project'}>
                <ul className={'nav'}>
                    <li style={{left: offset + 'px'}} className={'slider_prev'}><SliderPrevWhite
                        hoverBackground={'#212158'} hoverBorder={'#212158'} hoverColor={'#FFF'} background={'#F5F5F5'}
                        color={'#212158'}/>
                    </li>
                    <li style={{right: offset + 'px'}} className={'slider_next'}><SliderNextWhite
                        hoverBackground={'#212158'} hoverBorder={'#212158'} hoverColor={'#FFF'} background={'#F5F5F5'}
                        color={'#212158'}/>
                    </li>
                </ul>

                {list && list?.list?.length > 0 &&

                    <Swiper
                        loop={true}
                        spaceBetween={30}
                        slidesPerView={1}
                        allowSlideNext={true}
                        allowSlidePrev={true}
                        loopAdditionalSlides={2}
                        // allowTouchMove={false}
                        speed={900}
                        infinity={true}
                        // pagination={{
                        //     type: "fraction",
                        // }}
                        navigation={{
                            prevEl: '.sapphire .slider_prev',
                            nextEl: '.sapphire .slider_next ',
                        }}
                        modules={[Autoplay, Pagination, Navigation]}

                        // autoplay={false}

                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            1650: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            991: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            650: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            }

                        }}>

                        {
                            list?.list?.length > 0 &&
                            list?.list.map((element, index) =>
                                (
                                    <SwiperSlide key={index}>
                                        <Project category={element?.product_data?.category_title} area={element?.product_data?.area}
                                                 src={`/project/${element?.product_data?.slug}`}
                                                 img={element?.images?.list?.[0]?.full_path}
                                                 title={element?.product_data?.title}/>
                                    </SwiperSlide>
                                )
                            )
                        }
                        {
                            list?.list?.length < 9 ?
                                list?.list.map((element, index) => (
                                    <SwiperSlide key={index}>
                                        <Project category={element?.product_data?.category_title} area={element?.product_data?.area}
                                                 src={`/project/${element?.product_data?.slug}`}
                                                 img={element?.images?.list?.[0]?.full_path}
                                                 title={element?.product_data?.title}/>
                                    </SwiperSlide>
                                    )
                                ) : ''
                        }


                        {/*{window.innerWidth > 1600 && list?.list?.length < 6 &&*/}
                        {/*    <>*/}
                        {/*        {list?.list?.length > 0 && list?.list?.map(i => (*/}
                        {/*            <SwiperSlide>*/}
                        {/*                <Project favourite={i?.product_data?.is_favorite} id={i?.product_data?.id}*/}
                        {/*                         category={i?.product_data?.category_title} area={i?.product_data?.area}*/}
                        {/*                         src={`/project/${i?.product_data?.slug}`}*/}
                        {/*                         img={i?.images?.list?.[0]?.full_path}*/}
                        {/*                         title={i?.product_data?.title}/>*/}
                        {/*            </SwiperSlide>*/}
                        {/*        ))}*/}
                        {/*    </>*/}
                        {/*}*/}


                    </Swiper>
                }
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${hover};

  .add-to-fav {
    display: none;
  }

  .excellence__img {

  }

  .sapphire__top {

    img {
      margin-bottom: 40px;
    }

    p {
      color: #ffffff;
      letter-spacing: 1.5px;
    }
  }

  .sapphire__project {
    margin-top: 80px;
    position: relative;

    &:after {
      position: absolute;
      height: 50%;
      width: 100%;
      background-color: ${whites};
      bottom: 0;
      left: 0;
      content: '';
    }
  }

  .swiper {
    padding-left: 20%;
    padding-right: 20%;
  }


  @media (min-width: 992px) {
    .nav {
      li {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
      }

      .slider_prev {
        left: 15px;
      }

      .slider_next {
        right: 15px;
      }

    }
  }

  @media (max-width: 991px) {
    .col-md-8 {
      min-width: 100%;
      margin: 0;
    }

    .nav {
      padding-left: ${p => p.offset}px;
      margin-bottom: 40px;
    }

    .swiper {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 500px) {
    .text-center {
      text-align: left !important;
    }

    .sapphire__project {
      margin-top: 40px;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .nav {
      padding-left: 0;
    }
  }
`;

export default MyComponent;
