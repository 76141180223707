import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "./Title";
import ReactHtmlParser from "react-html-parser";


const MyComponent = ({ data }) => {
    return (
        <StyledComponent>
            {/*<InnerBanner title={"Privacy Policy for <br/> Sheltech (Pvt.) Ltd."}/>*/}
            <Container className={'pt-150 pb-150'}>
                <Row>
                    <Col sm={12}>
                        <Title text={"Privacy Policy for  Sheltech (Pvt.) Ltd."}/>
                    </Col>
                    <Col sm={10}>
                        {
                            data?.section_data?.description &&
                            ReactHtmlParser(data?.section_data?.description)
                        }
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    h4 {
        font-size: 30px;
        margin: 30px 0 10px 0;
    }

    p {
        margin-bottom: 15px;
    }
`;

export default MyComponent;
