import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import TeamSingle from "../TeamSingle";
import {hover, whites} from "../../styles/globalStyleVars";
import CloseButton from "../CloseButton";
import SimpleBar from "simplebar-react";
import Title from "../Title";

const MyComponent = ({data}) => {

    const [title, setTitle] = useState();
    const [img, setImg] = useState();
    const [deg, setDeg] = useState();
    const [desc, setDesc] = useState();
    const [fb, setfb] = useState();
    const [linkedIn, setlinkedIn] = useState();
    const [instagram, setinstagram] = useState();
    const [x, setx] = useState();
    const [youtube, setyoutube] = useState();


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePopup = (content) => {
        const {title, img, deg, description, fb, linkedIn, instagram, x, youtube} = content;

        setTitle(title);
        setImg(img);
        setDeg(deg);
        setDesc(description);
        setfb(fb);
        setlinkedIn(linkedIn)
        setinstagram(instagram)
        setx(x)
        setyoutube(youtube)
    };


    return (
        <StyledComponent className={'management-team pt-150 pb-120'}>
            <Container>
                <Row>
                    <Col>
                        <Title margin={'0 0 80px 0'} color={'#08082B'} text={'Board of Directors'}/>
                    </Col>
                </Row>
                <Row>
                    {
                        data && data.length > 0 && data?.map((item) => {

                            return (
                                <Col key={item?.page_data?.id} md={3} sm={4} onClick={handleShow}>
                                    <TeamSingle handlePopup={handlePopup} title={item?.page_data?.title}
                                                deg={item?.page_data?.short_desc}
                                                img={item?.images?.list?.[0]?.full_path}
                                                description={item?.page_data?.description}
                                                fb={item?.page_data?.facebook}
                                                linkedIn={item?.page_data?.linkedin}
                                                instagram={item?.page_data?.instagram}
                                                x={item?.page_data?.x}
                                                youtube={item?.page_data?.youtube}
                                    />

                                </Col>
                            )
                        })
                    }

                </Row>
            </Container>

            <Modal className={'team-modal'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>
                <SimpleBar style={{maxHeight: window.innerHeight - 150 + 'px'}}>
                    <div className="team-modal__content">
                        <img src={img} alt=""/>
                        <h4>{title}</h4>
                        <h5>{deg}</h5>
                        <ul>
                            {fb && <li><a target={"_blank"} href={fb}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                    <g id="Group_22513" data-name="Group 22513" transform="translate(-98 -679)">
                                        <rect id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25"
                                              rx="12.5" transform="translate(98 679)" fill="#f5f5f5"/>
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-901.115 -3228.731)" fill="#212158"/>
                                    </g>
                                </svg>
                            </a></li>
                            }

                            {x && <li><a href={x}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                    <g id="Group_22514" data-name="Group 22514" transform="translate(-143 -679)">
                                        <rect id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25"
                                              rx="12.5" transform="translate(143 679)" fill="#f5f5f5"/>
                                        <g id="layer1" transform="translate(150.482 687)">
                                            <path id="path1009"
                                                  d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                                                  transform="translate(-281.5 -167.31)" fill="#212158"/>
                                        </g>
                                    </g>
                                </svg>

                            </a></li>
                            }

                            {instagram && <li><a href={instagram}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                    <g id="Group_24046" data-name="Group 24046" transform="translate(-188 -679)">
                                        <g id="Group_22515" data-name="Group 22515">
                                            <rect id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25"
                                                  rx="12.5" transform="translate(188 679)" fill="#f5f5f5"/>
                                            <g id="Group_13724" data-name="Group 13724"
                                               transform="translate(-1972 -8343)">
                                                <path id="Path_5912" data-name="Path 5912"
                                                      d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0"
                                                      transform="translate(2167 9029)" fill="#212158"/>
                                                <path id="Path_5913" data-name="Path 5913"
                                                      d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864"
                                                      transform="translate(2149.045 9011.045)" fill="#212158"/>
                                                <path id="Path_5914" data-name="Path 5914"
                                                      d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671"
                                                      transform="translate(2114.82 9016.224)" fill="#212158"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a></li>
                            }

                            {linkedIn && <li><a href={linkedIn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                    <g id="Group_22516" data-name="Group 22516" transform="translate(-233 -679)">
                                        <rect id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25"
                                              rx="12.5" transform="translate(233 679)" fill="#f5f5f5"/>
                                        <g id="Group_28" data-name="Group 28" transform="translate(240.801 686.756)">
                                            <path id="LinkedIn"
                                                  d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                                  transform="translate(-1087.435 -3916.306)" fill="#212158"/>
                                        </g>
                                    </g>
                                </svg>

                            </a></li>}

                            {youtube && <li><a href={youtube}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                    <g id="Group_22517" data-name="Group 22517" transform="translate(-278 -679)">
                                        <rect id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25"
                                              rx="12.5" transform="translate(278 679)" fill="#f5f5f5"/>
                                        <g id="Group_31" data-name="Group 31" transform="translate(285.515 687.71)">
                                            <path id="Path_29" data-name="Path 29"
                                                  d="M1176.069,3919.422c-.36-.425-1.025-.6-2.294-.6h-4.606c-1.3,0-1.974.185-2.333.638a3.24,3.24,0,0,0-.349,1.993v1.717c0,1.745.415,2.631,2.682,2.631h4.606a2.913,2.913,0,0,0,2.1-.529,2.759,2.759,0,0,0,.577-2.1v-1.717A3.158,3.158,0,0,0,1176.069,3919.422Zm-3.181,3.128-2.092,1.087a.322.322,0,0,1-.47-.284v-2.168a.32.32,0,0,1,.154-.273.325.325,0,0,1,.315-.011l2.092,1.081a.319.319,0,0,1,0,.568Z"
                                                  transform="translate(-1166.487 -3918.823)" fill="#212158"/>
                                        </g>
                                    </g>
                                </svg>

                            </a></li>
                            }
                        </ul>
                        <p>{desc}</p>

                    </div>
                </SimpleBar>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${whites};


  .team-single {
    margin-bottom: 80px;

    &__content {
      border-color: rgba(8, 8, 43, 0.5);

      h4 {
        color: #08082B;
      }

      p {
        color: rgba(8, 8, 43, 0.5);
      }
    }

    @media (max-width: 600px) {
      margin-bottom: 40px;
    }
  }
`;

export default MyComponent;
