import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Col, Container, Row} from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner";
import Title from "../../components/Title";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchPosts} from "../../api/redux/privacy";
import ReactHtmlParser from "react-html-parser";
import PlainText from "../../components/PlainText";
import {title} from "../../styles/globalStyleVars";

const MyComponent = () => {

    const dispatch = useDispatch();
    let getPost = useSelector((state) => state.privacyReducer);

    // api config
    const endPoints = apiEndPoints.SECTIONS;
    const params = {
        [ApiParam.type]: "slug",
        [ApiParam.value]: "privacy-policy",
        [ApiParam.get_section]: "yes",
        [ApiParam.image]: "yes",
        [ApiParam.post]: "yes",
    };

    // api call
    useEffect(() => {
        dispatch(fetchPosts([endPoints, params]));
    }, []);

    const textData = getPost?.data?.data?.sections?.find(
        (f) => f?.section_data?.id == 140
    );
    const pageData = getPost?.data?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:title" content={pageData?.og_title}/>
                <meta property="og:description" content={pageData?.og_description}/>
                <meta name="description" content={pageData?.meta_description}/>
                <title>
                    {pageData?.meta_title ? pageData?.meta_title : 'Privacy Policy | Sheltech Pvt Ltd.'}
                </title>
            </Helmet>
            <PlainText data={textData}/>
        </HelmetProvider>
    );
};

export default MyComponent;
