import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Img } from "./Img";
import { hover, whites } from "../styles/globalStyleVars";

const MyComponent = () => {
  return (
    <StyledComponent className={"global-footer"}>
      <Img src={"/images/static/footer.png"} />
      <Container>
        <Row>
          <Col>
            <div className="global-footer__content d-flex flex-wrap justify-content-center text-center">
              {/* <a className={'big-number'} href="callto:16550">
                                <img src="/images/static/hotline.svg" alt=""/>
                            </a> */}
              <a className={"big-number"} href="callto:16550">
                16550
              </a>
              <ul className={"social"}>
                <li>
                  <a href="https://www.facebook.com/sheltechprivatelimited">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4.877"
                      height="10.466"
                      viewBox="0 0 4.877 10.466"
                    >
                      <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                        transform="translate(-1009.116 -3914.995)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
                {/*<li><a href="https://twitter.com/?lang=en">*/}
                {/*    <svg id="layer1" xmlns="http://www.w3.org/2000/svg" width="11.035" height="10.328"*/}
                {/*         viewBox="0 0 11.035 10.328">*/}
                {/*        <path id="path1009"*/}
                {/*              d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"*/}
                {/*              transform="translate(-281.5 -167.31)" fill="#fff"/>*/}
                {/*    </svg>*/}

                {/*</a></li>*/}
                <li>
                  <a href="https://www.instagram.com/sheltechprivatelimited/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.186"
                      height="11.186"
                      viewBox="0 0 11.186 11.186"
                    >
                      <g
                        id="Group_13724"
                        data-name="Group 13724"
                        transform="translate(-2167 -9029)"
                      >
                        <path
                          id="Path_5912"
                          data-name="Path 5912"
                          d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0"
                          transform="translate(2167 9029)"
                          fill="#fff"
                        />
                        <path
                          id="Path_5913"
                          data-name="Path 5913"
                          d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864"
                          transform="translate(2149.045 9011.045)"
                          fill="#fff"
                        />
                        <path
                          id="Path_5914"
                          data-name="Path 5914"
                          d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671"
                          transform="translate(2114.82 9016.224)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/sheltechpvtltd">
                    <svg
                      id="Group_28"
                      data-name="Group 28"
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.063"
                      height="8.561"
                      viewBox="0 0 9.063 8.561"
                    >
                      <path
                        id="LinkedIn"
                        d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                        transform="translate(-1087.435 -3916.306)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/c/SheltechPvtLtd">
                    <svg
                      id="Group_31"
                      data-name="Group 31"
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.97"
                      height="6.979"
                      viewBox="0 0 9.97 6.979"
                    >
                      <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M1176.069,3919.422c-.36-.425-1.025-.6-2.294-.6h-4.606c-1.3,0-1.974.185-2.333.638a3.24,3.24,0,0,0-.349,1.993v1.717c0,1.745.415,2.631,2.682,2.631h4.606a2.913,2.913,0,0,0,2.1-.529,2.759,2.759,0,0,0,.577-2.1v-1.717A3.158,3.158,0,0,0,1176.069,3919.422Zm-3.181,3.128-2.092,1.087a.322.322,0,0,1-.47-.284v-2.168a.32.32,0,0,1,.154-.273.325.325,0,0,1,.315-.011l2.092,1.081a.319.319,0,0,1,0,.568Z"
                        transform="translate(-1166.487 -3918.823)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
              </ul>

              <a
                target={"_blank"}
                href="https://goo.gl/maps/eTLaKDHcx1waz76R7"
                className="map"
              >
                Sheltech Tower, <br />
                60 Sheikh Russel Square, West Panthapath, <br />
                Dhaka-1205, Bangladesh
              </a>

              <ul className="footer-menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/landowner">Landowner</Link>
                </li>
                <li>
                  <Link to="/media-center">Media Center</Link>
                </li>
                <li>
                  <Link to="/career">Career</Link>
                </li>
                <li>
                  <Link to="/customer">Customer Enquiry</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>

              <div className="copyright">
                <p>
                  © {new Date().getFullYear()} Sheltech (Pvt.) Ltd. All Right
                  Reserved
                </p>
                <a target={"_blank"} href="https://dcastalia.com/">
                  Designed & Developed by Dcastalia
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  padding: 60px 0 25px 0;
  position: relative;

  .big-number {
    font-size: 80px;
    line-height: 110px;
    color: ${whites};
    //width: 100%;
    margin-bottom: 10px;
  }

  a:hover {
    color: rgba(245, 245, 245, 0.5) !important;
  }

  .social {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    li {
      margin-right: 20px;
      height: 25px;
      width: 25px;
      border: 1px solid #fff;
      border-radius: 50%;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 0%;
        width: 0%;
        background-color: ${whites};
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        margin: auto;
        transition: 0.4s ease;
      }

      svg {
        height: 24px;
      }

      a {
        transition: 0.6s ease;
        display: flex;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 2;
        left: 0;
        right: 0px;
        top: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        @media (min-width: 1600px) {
          //padding-left: 2px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      &:hover {
        &:after {
          height: 100%;
          width: 100%;
        }

        svg {
          path {
            fill: ${hover};
          }
        }
      }
    }
  }

  .map {
    color: ${whites};
  }

  .footer-menu {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(245, 245, 245, 0.2);
    flex-wrap: wrap;

    li {
      margin-right: 20px;

      a {
        color: ${whites};
      }
    }
  }

  .copyright {
    margin-top: 20px;

    p {
      color: rgba(245, 245, 245, 0.5);
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 16px;
    }

    a {
      color: rgba(245, 245, 245, 0.5);
      font-size: 12px;
      line-height: 16px;

      &:hover {
        text-decoration: underline;
        color: rgba(245, 245, 245, 0.5) !important;
      }
    }
  }

  @media (max-width: 550px) {
    .global-footer__content {
      text-align: left !important;
      justify-content: left !important;

      .social {
        justify-content: left;
      }

      .footer-menu {
        //justify-content: space-between;
        justify-content: flex-start;
        margin-top: 25px;

        li {
          width: 50%;
          margin: 0 0 12px 0;

          &:nth-of-type("even") {
          }
        }
      }
    }
  }
`;

export default MyComponent;
