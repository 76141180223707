import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, whites} from "../../styles/globalStyleVars";
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import {Img} from "../Img";

const MyComponent = ({data}) => {


    return (
        <StyledComponent className={'counter pt-150 no-line'}>
            <img className={'shadow-img'} src={'/images/static/shadow-v2.svg'}/>
            <Container>
                <Row>
                    {data?.posts?.list?.length > 0 && data?.posts?.list?.map(i => (

                        <Col key={i?.data?.id} md={4} sm={6}>
                            <div className={'counter__single'}>

                                <CountUp
                                    start={0} end={Number(i?.data?.short_desc.replace(/\,/g, ''))}>
                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start}
                                                          offset={{top: -50}}
                                                          delayedCall>
                                            <h4 ref={countUpRef}>0</h4>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <p>{i?.data?.title}</p>
                            </div>
                        </Col>
                    ))

                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: ${hover};
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    .shadow-img {
        position: absolute;
        right: 0;
        height: 90%;
        //top: 0;
        bottom: 0;
    }

    .counter__single {
        margin-bottom: 100px;
        padding: 10px 0 10px 25px;
        border-left: 3px solid ${whites};

        h4 {
            font-size: 60px;
            line-height: 52px;
            margin-bottom: 20px;
            color: ${whites};
        }

        p {
            color: ${whites};
        }
    }

    @media (max-width: 991px) {
        .counter__single {
            margin-bottom: 35px;

            h4 {
                font-size: 35px;
                line-height: 40px;
                margin-bottom: 10px;
            }
        }
    }
`;

export default MyComponent;
