import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";


const initialState = {
    loading: false,
    data: [],
    error: '',
    formData: [],
    formLoading: false
}

export const fetchPosts = createAsyncThunk("FetchLand", (params) => {
    return get(params);
});

export const PostForm = createAsyncThunk("postLand", (params) => {
    return post(params);
});


const postSlice = createSlice({
    name: 'land',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchPosts.pending, (state) => {
            state.loading = true
            state.data = []
            state.error = ''
        })
        builder.addCase(fetchPosts.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchPosts.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error
        })
        builder.addCase(PostForm.pending, (state) => {
            state.formLoading = true
            state.formData = []
            state.error = ''
        })
        builder.addCase(PostForm.fulfilled, (state, action) => {
            state.formLoading = false
            state.formData = action.payload
            state.error = ''
        })
        builder.addCase(PostForm.rejected, (state, action) => {
            state.formLoading = false
            state.formData = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
