import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {whites, title} from "../styles/globalStyleVars";

const InnerBanner = ({img, title, subTitle}) => {
    return (
        <StyledInnerBanner className='InnerBanner'>
            <Img banner={true} src={img}/>
            <Container>
                <Row>
                    <Col md={{span: 10, offset: 1}} sm={12}>
                        <p>{subTitle}</p>
                        <h2 className={`anim-active fade-up`}>{reactHtmlParser(title)}</h2>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(600 / 1366 * 100%);
  position: relative;
  background-color: #DDD;
  overflow: hidden;
  //margin-bottom: -2px;

  .container {
    position: absolute;
    height: 100%;
    inset: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .row {
    width: 100%;
  }

  //&:after {
  //  content: '';
  //  position: absolute;
  //  background-color: rgba(0, 0, 0, 0.25);
  //  height: 100%;
  //  width: 100%;
  //  left: 0;
  //  top: 0;
  //}
  //
  p {
    color: ${whites};
    z-index: 3;
    position: relative;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 34px;
  }

  h2 {
    //position: absolute;
    left: 0px;
    //bottom: 94px;
    color: #ffffff;
    font-size: 44px;
    font-weight: 300;
    line-height: 52px;
    //text-transform: capitalize;
    z-index: 3;
    position: relative;
    text-transform: uppercase;
    font-family: ${title};
    margin-top: 15px;
    right: 0;
  }

  @media (max-width: 768px) {
    padding-top: calc(60%);
  }

  @media (max-width: 600px) {
    //padding-top: 0;
    padding-top: calc(600 / 375 * 100%);
    p {
      font-size: 20px;
    }

    h2 {
      font-size: 32px;
      line-height: 44px;
      margin-top: 5px;
    }
  }
`;

export default InnerBanner;
