import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Details from "../../components/MediaCenter/Details";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchDetail} from "../../api/redux/media";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";

const MyComponent = () => {
    const dispatch = useDispatch()
    let getPost = useSelector(state => state.mediaReducer);
    const location = useLocation()
    const endPoints = apiEndPoints.SECTIONS


    // api call
    useEffect(() => {
        // api config

        if (location.pathname) {
            const params = {
                [ApiParam.type]: 'slug',
                [ApiParam.value]: location.pathname.replace('/media-center/', ''),
                [ApiParam.get_section]: 'yes',
                [ApiParam.image]: 'yes',
                [ApiParam.post]: 'yes',
            }
            dispatch(fetchDetail([endPoints, params]))
        }

    }, [])


    return (
        <HelmetProvider>

            <Helmet>
                <meta charSet="utf-8"/>
                {getPost && getPost?.detail?.data?.page_data?.title ?
                    <title>{getPost?.detail?.data?.page_data?.title} | Sheltech Pvt Ltd</title> :
                    <title>Sheltech Pvt Ltd</title>
                }
                <meta name="description" content={getPost?.detail?.data?.page_data?.meta_description}/>
                <meta property="og:title" content={getPost?.detail?.data?.page_data?.og_title}/>
                <meta property="og:description" content={getPost?.detail?.data?.page_data?.og_description}/>
            </Helmet>

            <StyledComponent>
                <Details data={getPost?.detail?.data}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
