import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

const MyComponent = ({

                         onSubmit,
                         color,

                         background,

                         hoverBackground,
                         hoverBorder,
                         borderColor,
                         hoverColor
                     }) => {
    return (
        <StyledComponent className={'slider-next'}
                         color={color}
                         background={background}
                         hoverBackground={hoverBackground}
                         borderColor={borderColor}
                         hoverColor={hoverColor}
                         onSubmit={onSubmit}
                         hoverBorder={hoverBorder}
        >
            <svg className={'initial'} width="11.207" height="7.414" viewBox="0 0 11.207 7.414">
                <g id="Arrow" transform="translate(-0.5 0.707)">
                    <line id="Line_10751" data-name="Line 10751" x2="10" transform="translate(1 3)" fill="none"
                          stroke="#f5f5f5" stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_10752" data-name="Line 10752" x2="3" y2="3" transform="translate(8)" fill="none"
                          stroke="#f5f5f5" stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_10753" data-name="Line 10753" x1="3" y2="3" transform="translate(8 3)" fill="none"
                          stroke="#f5f5f5" stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>

            <svg className={'hovered'} xmlns="http://www.w3.org/2000/svg" width="11.207" height="7.414"
                 viewBox="0 0 11.207 7.414">
                <g id="Arrow" transform="translate(-0.5 0.707)">
                    <line id="Line_10751" data-name="Line 10751" x2="10" transform="translate(1 3)" fill="none"
                          stroke="#212158"
                          stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_10752" data-name="Line 10752" x2="3" y2="3" transform="translate(8)" fill="none"
                          stroke="#212158"
                          stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_10753" data-name="Line 10753" x1="3" y2="3" transform="translate(8 3)" fill="none"
                          stroke="#212158"
                          stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>

        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  color: ${props => props.color || `#FFF`};
  position: relative;
  z-index: 2;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid ${p => p.borderColor || `#FFF`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  overflow: hidden;
  background-color: ${p => p.background || 'transparent'};
  cursor: pointer;
  transition: all .5s ease;

  &:before {
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${p => p.hoverBackground || '#FFF'};
    border-radius: 50%;
    transform: translateX(-40px);
    transition: all .6s cubic-bezier(0.4, 0, 0, 1);
  }

  svg {
    transition: all .6s cubic-bezier(0.4, 0, 0, 1);

    &.initial {
      line {
        stroke: ${p => p.color};
      }
    }

    &.hovered {
      position: absolute;
      transform: translateX(-40px);

      line {
        stroke: ${p => p.hoverColor};
      }
    }
  }

  &:hover {
    color: ${p => p.color || '#FFF'} !important;
    border: 1px solid ${p => p.hoverBorder || ''};

    &:before {
      transform: none;
    }

    .initial {
      transform: translateX(40px);
    }

    .hovered {
      transform: none;
    }

  }
`;

export default MyComponent;
