import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "./Title";
import SliderPrevWhite from "./SliderPrevWhite";
import SliderNextWhite from "./SliderNextWhite";
import {Img} from "./Img";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";
import {hover, whites} from "../styles/globalStyleVars";

const MyComponent = ({data, testimonial}) => {

    const [show, setShow] = useState(false);
    const [videoShow, setVideoShow] = useState();


    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }


    const filterTestimonial = testimonial && testimonial?.filter(f => f.page_data?.is_landowner === "yes")


    return (
        <StyledComponent className={'testimonial pt-200 pb-200 no-line'}>
            <Container>
                <Row className={'testimonial__top'}>
                    <Col sm={12} md={6}>
                        <Title margin={'0'} text={data?.section_data?.title}/>
                    </Col>
                    <Col sm={12} md={{span: 6}} className={'d-flex align-items-end '}>
                        <ul>
                            <li className={'slider_prev'}><SliderPrevWhite color={'#08082B'} hoverBackground={'#08082B'}
                                                                           hoverColor={'#FFF'}
                                                                           borderColor={'#08082B'}/></li>
                            <li className={'slider_next'}><SliderNextWhite color={'#08082B'} hoverBackground={'#08082B'}
                                                                           hoverColor={'#FFF'}
                                                                           borderColor={'#08082B'}/></li>
                        </ul>
                    </Col>
                </Row>

                <div className={'testimonial__slider'}>
                    {testimonial?.length > 0 &&
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={900}

                                navigation={{
                                    prevEl: '.testimonial .slider_prev',
                                    nextEl: '.testimonial .slider_next',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                                autoplay={false}

                            // autoplay={{
                            //     delay: 5000,
                            //     disableOnInteraction: false,
                            // }}
                        >
                            {
                                testimonial?.length > 0 && testimonial?.map((item) => {

                                    return (
                                        <SwiperSlide key={item?.page_data?.id}
                                                     onClick={() => setVideoShow(item?.page_data?.short_desc)}>
                                            <div className="testimonial__slider__single flex-wrap d-flex">
                                                <Col sm={12} md={5} className={'p-0'}>
                                                    <div className="testimonial__slider__single__img"
                                                         onClick={handleShow}>
                                                        <Img src={item?.images?.list?.[0]?.full_path}/>
                                                        <div className="video-btn">
                                                            {
                                                                item?.page_data?.short_desc &&

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="70"
                                                                     height="70"
                                                                     viewBox="0 0 70 70">
                                                                    <g id="Group_24046" data-name="Group 24046"
                                                                       transform="translate(-298 -7617)">
                                                                        <g id="Group_22489" data-name="Group 22489">
                                                                            <circle id="Ellipse_627"
                                                                                    data-name="Ellipse 627"
                                                                                    cx="35" cy="35"
                                                                                    r="35"
                                                                                    transform="translate(298 7617)"
                                                                                    fill="#f5f5f5"/>
                                                                            <path id="Polygon_2" data-name="Polygon 2"
                                                                                  d="M9.186,1.139a1,1,0,0,1,1.627,0l8.057,11.28A1,1,0,0,1,18.057,14H1.943a1,1,0,0,1-.814-1.581Z"
                                                                                  transform="translate(342 7642) rotate(90)"
                                                                                  fill="#212158"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            }

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={12} md={{span: 6, offset: 1}}>
                                                    <div className="testimonial__slider__single__content">
                                                        <h4>{item?.page_data?.subtitle}</h4>
                                                        <img src="/images/static/quotes.svg" alt=""/>
                                                        <p>{reactHtmlParser(item?.page_data?.description)}</p>

                                                        <div className="testimonial__slider__single__content__bottom">
                                                            <p>{item?.images?.list?.[0]?.short_title}</p>
                                                            <p>{reactHtmlParser(item?.images?.list?.[0]?.short_desc)}</p>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    }
                </div>

            </Container>

            {/*modal*/}
            <Modal className={'modal-video'} show={show} onHide={handleClose}>

                <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
                     height="13.423"
                     viewBox="0 0 13.426 13.423">
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                          d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                          transform="translate(-11.285 -11.289)" fill="#fff"/>
                </svg>


                <Modal.Body>
                    <iframe width="560" height="315"
                            src={`https://www.youtube.com/embed/${videoShow}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </Modal.Body>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #D6D6D6;
  overflow: hidden;

  .testimonial__top {
    ul {
      display: flex;
    }
  }

  .testimonial__slider {
    margin-top: 65px;

    &__single {
      &__img {
        position: relative;
        padding-top: calc(320 / 470 * 100%);
        cursor: pointer;
        overflow: hidden;

        .video-btn {
          position: absolute;
          width: fit-content;
          height: fit-content;
          inset: 0 0 0 0;
          margin: auto;
        }

        &:hover {
          img {
            transform: scale(1.04);
          }
        }
      }

      &__content {
        position: relative;

        img {
          position: absolute;
          top: -14px;
          left: -26px;
        }

        h4 {
          margin-bottom: 25px;
          font-size: 24px;
          line-height: 32px;
        }

        a {
          color: ${hover} !important;

          &:hover {
            text-decoration: underline;
          }
        }

        &__bottom {
          margin-top: 60px;
        }
      }
    }
  }

  .swiper-slide {
    padding-top: 15px;
  }

  @media (max-width: 768px) {
    .video-btn {
      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  @media (max-width: 767px) {


    .testimonial__slider {
      margin-top: 40px;

      &__single__content {
        margin-top: 40px;
        margin-left: -15px;
        margin-right: -15px;

        img {
          left: 0px;
        }

        &__bottom {
          margin-top: 40px;
        }
      }
    }


  }
`;

export default MyComponent;
