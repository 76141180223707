import React from 'react';
import styled from 'styled-components';
import {hover, text} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const Button = ({
                    submitButton,
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    hoverColor
                }) => {


    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
        >
            {!submitButton ? (
                    <Link to={src || '#'}>
                        {text} <span><svg className={'initial'} width="11.207" height="7.414"
                                          viewBox="0 0 11.207 7.414">
  <g id="Arrow" transform="translate(-0.5 0.707)">
    <line id="Line_10751" data-name="Line 10751" x2="10" transform="translate(1 3)" fill="none" stroke="#f5f5f5"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10752" data-name="Line 10752" x2="3" y2="3" transform="translate(8)" fill="none" stroke="#f5f5f5"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10753" data-name="Line 10753" x1="3" y2="3" transform="translate(8 3)" fill="none" stroke="#f5f5f5"
          stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>


                    <svg className={'hovered'} xmlns="http://www.w3.org/2000/svg" width="11.207" height="7.414"
                         viewBox="0 0 11.207 7.414">
  <g id="Arrow" transform="translate(-0.5 0.707)">
    <line id="Line_10751" data-name="Line 10751" x2="10" transform="translate(1 3)" fill="none" stroke="#212158"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10752" data-name="Line 10752" x2="3" y2="3" transform="translate(8)" fill="none" stroke="#212158"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10753" data-name="Line 10753" x1="3" y2="3" transform="translate(8 3)" fill="none" stroke="#212158"
          stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>


</span>
                    </Link>
                ) :
                <button type={'submit'} onSubmit={onSubmit}>

                    {text} <span><svg className={'initial'} width="11.207" height="7.414"
                                      viewBox="0 0 11.207 7.414">
  <g id="Arrow" transform="translate(-0.5 0.707)">
    <line id="Line_10751" data-name="Line 10751" x2="10" transform="translate(1 3)" fill="none" stroke="#f5f5f5"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10752" data-name="Line 10752" x2="3" y2="3" transform="translate(8)" fill="none" stroke="#f5f5f5"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10753" data-name="Line 10753" x1="3" y2="3" transform="translate(8 3)" fill="none" stroke="#f5f5f5"
          stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>


                    <svg className={'hovered'} xmlns="http://www.w3.org/2000/svg" width="11.207" height="7.414"
                         viewBox="0 0 11.207 7.414">
  <g id="Arrow" transform="translate(-0.5 0.707)">
    <line id="Line_10751" data-name="Line 10751" x2="10" transform="translate(1 3)" fill="none" stroke="#212158"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10752" data-name="Line 10752" x2="3" y2="3" transform="translate(8)" fill="none" stroke="#212158"
          stroke-linecap="round" stroke-width="1"/>
    <line id="Line_10753" data-name="Line 10753" x1="3" y2="3" transform="translate(8 3)" fill="none" stroke="#212158"
          stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>


</span>
                </button>}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || 'fit-content'};
    cursor: pointer;

    a, button {
      cursor: pointer;
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '15'}px;
      font-weight: ${props => props.fontWeight || 400};
      margin: 0;
      line-height: ${props => props.lineHeight || '20'}px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      color: ${p => p.color || '#FFF'};
      background-color: transparent;
      border: 0;
      box-shadow: none;
      padding: 0;

      &:focus {
        color: ${p => p.color || '#FFF'} !important;
      }

      svg {
        transition: all .6s cubic-bezier(0.4, 0, 0, 1);

        &.initial {
          line {
            stroke: ${p => p.color};
          }
        }

        &.hovered {
          position: absolute;
          transform: translateX(-40px);

          line {
            stroke: ${p => p.hoverColor};
          }
        }
      }

      span {
        transition: color .3s ease;
        color: ${props => props.color || `#FFF`};
        position: relative;
        z-index: 2;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid ${p => p.borderColor || `#FFF`};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        overflow: hidden;
        background-color: ${p => p.background || 'transparent'};

        &:before {
          bottom: 0;
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: ${p => p.hoverBackground || '#FFF'};
          border-radius: 50%;
          transform: translateX(-40px);
          transition: all .6s cubic-bezier(0.4, 0, 0, 1);
        }
      }


      &:hover {
        color: ${p => p.color || '#FFF'} !important;

        span:before {
          transform: none;
        }

        .initial {
          transform: translateX(40px);
        }

        .hovered {
          transform: none;
        }

      }

      &:focus {
        color: #222222;
      }
    }
  }

`;


export default Button;
