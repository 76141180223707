import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useSelector} from "react-redux";
import SapphireSeriesList from "../../components/project/SapphireSeriesList";
import {title} from "../../styles/globalStyleVars";

const MyComponent = () => {
    let getPost = useSelector(state => state.projectReducer)
    const pageData = getPost?.data?.project_data?.page_data;

    return (
        <HelmetProvider>

            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:title" content={pageData?.og_title}/>
                <meta property="og:description" content={pageData?.og_description}/>
                <meta name="description" content={pageData?.meta_description}/>
                <title>
                    {pageData?.meta_title ? pageData?.meta_title : 'Sapphire Series | Sheltech Pvt Ltd.'}
                </title>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={getPost?.fetchSapphireData?.project_data?.page_images?.list?.find((f) => f.thumb === "on")?.full_path}
                             subTitle={getPost?.fetchSapphireData?.project_data?.page_images?.list?.find((f) => f.thumb === "on")?.short_title}
                             title={getPost?.fetchSapphireData?.project_data?.page_images?.list?.find((f) => f.thumb === "on")?.short_desc}/>
                <SapphireSeriesList data={getPost}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
