import React from 'react';
import styled from 'styled-components';
import {hover, text, title, title_ms60} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const Title = ({
                   text,
                   fontSize,
                   fontWeight,
                   color,
                   letterSpacing,
                   lineHeight,
                   textTransform,
                   margin,
                   padding,
                   borderColor,
                   varient,
                   center,
                   classname,
                   small_text
               }) => {


    return (

        <StyledTitle className={`title ${classname}`}
                     fontSize={fontSize}
                     fontWeight={fontWeight}
                     color={color}
                     lineHeight={lineHeight}
                     LetterSpacing={letterSpacing}
                     textTransform={textTransform}
                     margin={margin}
                     padding={padding}
                     varient={varient}
                     center={center}
                     borderColor={borderColor}>
            <h2 className={'split-up'}>{ReactHtmlParser(text)} </h2>


        </StyledTitle>

    )
};


const StyledTitle = styled.div`
    margin: ${props => props.margin || '0 0 40px 0'};
    position: relative;
    width: ${p => p.center ? '100%' : 'fit-content'};
    padding: ${p => p.padding};
    text-align: ${p => p.center ? 'center' : ''};

    h2 {
        font-size: ${props => props.fontSize || 44}px;
        line-height: ${props => props.lineHeight || 52}px;
        text-transform: uppercase;
        font-weight: ${props => props.fontWeight || '400'};
        color: ${props => props.color || text};
        font-family: ${title};
    }


    @media (max-width: 767px) {
        margin-bottom: 40px;
        h2 {
            font-size: 25px !important;
            line-height: 35px !important;
        }
    }
`;


export default Title;














