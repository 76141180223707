import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import GoogleMapsComponent from "../GoogleMapsComponent";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 30)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 30)
        })
    }, [])


    return (
        <StyledComponent className={'address'}>
            <Container fluid className={'p-0'}>
                <Row>
                    <Col md={5} sm={12} className={'address__content'} style={{paddingLeft: offset + 'px'}}>
                        <Title text={'Keep in Touch'}/>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="25.377" height="30"*/}
                                {/*     viewBox="0 0 25.377 30">*/}
                                {/*    <g id="Group_22803" data-name="Group 22803" transform="translate(-39.451)">*/}
                                {/*        <g id="Group_22804" data-name="Group 22804" transform="translate(39.451)">*/}
                                {/*            <g id="Group_22803-2" data-name="Group 22803" transform="translate(0)">*/}
                                {/*                <path id="Path_10702" data-name="Path 10702"*/}
                                {/*                      d="M61.112,3.716a12.689,12.689,0,0,0-21.661,8.972c0,6.856,6.483,12.559,9.965,15.623.484.426.9.793,1.235,1.1a2.186,2.186,0,0,0,2.976,0c.333-.311.751-.679,1.235-1.1,3.483-3.064,9.965-8.766,9.965-15.623A12.606,12.606,0,0,0,61.112,3.716ZM53.7,26.992c-.495.435-.922.811-1.274,1.14a.424.424,0,0,1-.577,0c-.352-.329-.779-.7-1.274-1.14-3.274-2.88-9.369-8.241-9.369-14.3a10.931,10.931,0,0,1,21.862,0C63.071,18.75,56.976,24.111,53.7,26.992Z"*/}
                                {/*                      transform="translate(-39.451 0)" fill="#f5f5f5"/>*/}
                                {/*            </g>*/}
                                {/*        </g>*/}
                                {/*        <g id="Group_22806" data-name="Group 22806" transform="translate(46.548 6.617)">*/}
                                {/*            <g id="Group_22805" data-name="Group 22805" transform="translate(0)">*/}
                                {/*                <path id="Path_10703" data-name="Path 10703"*/}
                                {/*                      d="M166.162,112.938a5.592,5.592,0,1,0,5.592,5.592A5.6,5.6,0,0,0,166.162,112.938Zm0,9.426A3.834,3.834,0,1,1,170,118.53,3.839,3.839,0,0,1,166.162,122.364Z"*/}
                                {/*                      transform="translate(-160.57 -112.938)" fill="#f5f5f5"/>*/}
                                {/*            </g>*/}
                                {/*        </g>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[0]?.images?.[0]?.full_path} alt=""/>
                            </h6>

                            <p>
                                <span>{data?.posts?.list?.[0]?.data?.title}</span>
                                {reactHtmlParser(data?.posts?.list?.[0]?.data?.short_desc)}
                            </p>

                        </div>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="30" height="22.5" viewBox="0 0 30 22.5">*/}
                                {/*    <path id="Path_10756" data-name="Path 10756"*/}
                                {/*          d="M27.187,59.882H2.813A2.816,2.816,0,0,0,0,62.695V79.57a2.816,2.816,0,0,0,2.813,2.813H27.187A2.816,2.816,0,0,0,30,79.57V62.695A2.816,2.816,0,0,0,27.187,59.882Zm0,1.875a.932.932,0,0,1,.359.073L15,72.7,2.453,61.83a.933.933,0,0,1,.359-.073Zm0,18.75H2.813a.938.938,0,0,1-.938-.938V63.811L14.386,74.653a.939.939,0,0,0,1.229,0L28.125,63.811V79.57A.938.938,0,0,1,27.187,80.507Z"*/}
                                {/*          transform="translate(0 -59.882)" fill="#f5f5f5"/>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[1]?.images?.[0]?.full_path} alt=""/>
                            </h6>

                            <p>
                                <span>{data?.posts?.list?.[1]?.data?.title}</span>
                                <a href={`mailto:${data?.posts?.list?.[1]?.data?.short_desc}`}>{data?.posts?.list?.[1]?.data?.short_desc}</a>
                            </p>

                        </div>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.882" height="25.625"*/}
                                {/*     viewBox="0 0 23.882 25.625">*/}
                                {/*    <g id="Group_23649" data-name="Group 23649" transform="translate(-0.872 0)">*/}
                                {/*        <path id="Path_10757" data-name="Path 10757"*/}
                                {/*              d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-.8l-.913.8c-.668.58-1.91,3.29-6.712-2.234S7.862,9.22,8.531,8.645l.918-.8c1.521-1.325.947-2.993-.15-4.71L8.637,2.1C7.535.382,6.335-.743,4.81.58l-.824.72A7.735,7.735,0,0,0,.971,6.419c-.55,3.638,1.185,7.8,5.16,12.375s7.857,6.87,11.539,6.83a7.756,7.756,0,0,0,5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995Z"*/}
                                {/*              fill="#f5f5f5"/>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[2]?.images?.[0]?.full_path} alt=""/>
                            </h6>
                            <p>
                                <span>{data?.posts?.list?.[2]?.data?.title}</span>
                                <a href={`tel:${data?.posts?.list?.[2]?.data?.short_desc}`}>{data?.posts?.list?.[2]?.data?.short_desc}</a>
                            </p>

                        </div>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.882" height="25.625"*/}
                                {/*     viewBox="0 0 23.882 25.625">*/}
                                {/*    <g id="Group_23650" data-name="Group 23650" transform="translate(-0.872 0)">*/}
                                {/*        <path id="Path_10757" data-name="Path 10757"*/}
                                {/*              d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-.8l-.913.8c-.668.58-1.91,3.29-6.712-2.234S7.862,9.22,8.531,8.645l.918-.8c1.521-1.325.947-2.993-.15-4.71L8.637,2.1C7.535.382,6.335-.743,4.81.58l-.824.72A7.735,7.735,0,0,0,.971,6.419c-.55,3.638,1.185,7.8,5.16,12.375s7.857,6.87,11.539,6.83a7.756,7.756,0,0,0,5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995Z"*/}
                                {/*              fill="#f5f5f5"/>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[3]?.images?.[0]?.full_path} alt=""/>
                            </h6>
                            <p>
                                <span>{data?.posts?.list?.[3]?.data?.title}</span>
                                <a href={`tel:${data?.posts?.list?.[3]?.data?.short_desc}`}>{data?.posts?.list?.[3]?.data?.short_desc}</a>
                            </p>

                        </div>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.882" height="25.625"*/}
                                {/*     viewBox="0 0 23.882 25.625">*/}
                                {/*    <g id="Group_23650" data-name="Group 23650" transform="translate(-0.872 0)">*/}
                                {/*        <path id="Path_10757" data-name="Path 10757"*/}
                                {/*              d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-.8l-.913.8c-.668.58-1.91,3.29-6.712-2.234S7.862,9.22,8.531,8.645l.918-.8c1.521-1.325.947-2.993-.15-4.71L8.637,2.1C7.535.382,6.335-.743,4.81.58l-.824.72A7.735,7.735,0,0,0,.971,6.419c-.55,3.638,1.185,7.8,5.16,12.375s7.857,6.87,11.539,6.83a7.756,7.756,0,0,0,5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995Z"*/}
                                {/*              fill="#f5f5f5"/>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[4]?.images?.[0]?.full_path} alt=""/>
                            </h6>
                            <p>
                                <span>{data?.posts?.list?.[4]?.data?.title}</span>
                                <a href={`tel:${data?.posts?.list?.[4]?.data?.short_desc}`}>{data?.posts?.list?.[4]?.data?.short_desc}</a>
                            </p>

                        </div>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.882" height="25.625"*/}
                                {/*     viewBox="0 0 23.882 25.625">*/}
                                {/*    <g id="Group_23650" data-name="Group 23650" transform="translate(-0.872 0)">*/}
                                {/*        <path id="Path_10757" data-name="Path 10757"*/}
                                {/*              d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-.8l-.913.8c-.668.58-1.91,3.29-6.712-2.234S7.862,9.22,8.531,8.645l.918-.8c1.521-1.325.947-2.993-.15-4.71L8.637,2.1C7.535.382,6.335-.743,4.81.58l-.824.72A7.735,7.735,0,0,0,.971,6.419c-.55,3.638,1.185,7.8,5.16,12.375s7.857,6.87,11.539,6.83a7.756,7.756,0,0,0,5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995Z"*/}
                                {/*              fill="#f5f5f5"/>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[5]?.images?.[0]?.full_path} alt=""/>
                            </h6>
                            <p>
                                <span>{data?.posts?.list?.[5]?.data?.title}</span>
                                <a href={`tel:${data?.posts?.list?.[5]?.data?.short_desc}`}>{data?.posts?.list?.[5]?.data?.short_desc}</a>
                            </p>

                        </div>

                        <div className="address__single">
                            <h6>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.882" height="25.625"*/}
                                {/*     viewBox="0 0 23.882 25.625">*/}
                                {/*    <g id="Group_23650" data-name="Group 23650" transform="translate(-0.872 0)">*/}
                                {/*        <path id="Path_10757" data-name="Path 10757"*/}
                                {/*              d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-.8l-.913.8c-.668.58-1.91,3.29-6.712-2.234S7.862,9.22,8.531,8.645l.918-.8c1.521-1.325.947-2.993-.15-4.71L8.637,2.1C7.535.382,6.335-.743,4.81.58l-.824.72A7.735,7.735,0,0,0,.971,6.419c-.55,3.638,1.185,7.8,5.16,12.375s7.857,6.87,11.539,6.83a7.756,7.756,0,0,0,5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995Z"*/}
                                {/*              fill="#f5f5f5"/>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}
                                <img src={data?.posts?.list?.[6]?.images?.[0]?.full_path} alt=""/>
                            </h6>
                            <p>
                                <span>{data?.posts?.list?.[6]?.data?.title}</span>
                                <a href={`tel:${data?.posts?.list?.[6]?.data?.short_desc}`}>{data?.posts?.list?.[6]?.data?.short_desc}</a>
                            </p>

                        </div>
                    </Col>

                    <Col md={7} sm={12} className={'address__map'}>

                        <GoogleMapsComponent/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  background-color: ${whites};
  overflow: hidden;

  .address__content {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .address__single {
    display: flex;
    width: 80%;

    &:not(:nth-last-child(1)) {
      margin-bottom: 30px;
    }

    img {
      height: 35px;
      filter: invert(8%) sepia(27%) saturate(73%) hue-rotate(6deg) brightness(6%) contrast(90%);
    }

    svg {
      margin-top: 5px;

      path {
        fill: rgb(8, 8, 43);
      }
    }

    h6 {
      min-width: 70px;
      width: 60px;
    }

    p, a {
      span {
        //color: rgba(245, 245, 245, 0.5);
        display: block;
        width: 100%;
      }

        // color: ${whites};
    }

    a:hover {
      text-decoration: underline;
      color: ${whites} !important;
    }
  }

  .address__map__img {
    min-height: 100%;
    position: relative;
    overflow: hidden;
  }

  .address__map {
    position: relative;
    min-height: 50vh;
    overflow: hidden;

    .noise {
      position: absolute;
      inset: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;

      &:after {
        content: '';
        background-image: url('/images/static/background-noise.png');
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        opacity: 0.2;
      }

    }
  }

  .map-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dc-btn {
      position: relative;
      z-index: 3;
    }

    &:after {
      content: '';
      background-image: url('/images/static/maploader.jpg');
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
    }

    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.73);
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 2;
    }
  }

  @media (max-width: 767px) {
    .address__single h6 {
      min-width: 50px;
      width: 50px;
    }

    .address__map__img {
      height: 60vh;
    }
  }

`;

export default MyComponent;
