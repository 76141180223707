import { Route, Switch, useLocation } from "react-router-dom";
import Error from "./pages/404";
import GlobalStyle from "./styles/globalStyle";
import { ImageReveal } from "./components/animations/ImageReveal";
import { Parallax, ParallaxImg } from "./components/animations/Parallax";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { SplitText } from "gsap/SplitText";

// page imports
import About from "./pages/about";
import Awards from "./pages/awards";
import CSRDetail from "./pages/about/Details";
import Components from "./pages/components";
import Home from "./pages/home";
import Project from "./pages/projects";
import { SplitUp } from "./components/animations/TextAnimation";
import { useEffect, useLayoutEffect, useRef } from "react";
import PageTransition from "./components/PageTransition";
import MediaCenter from "./pages/media-center";
import Newsletters from "./pages/newsletters";
import MediaCenterDetail from "./pages/media-center/single";
import Landowner from "./pages/landowner";
import Customer from "./pages/customer";
import Career from "./pages/career";
import Projects from "./pages/projects";
import SapphireSeries from "./pages/projects/sapphire";
import LandProject from "./pages/projects/land";
import ProjectDetail from "./pages/projects/single";
import landDetail from "./pages/projects/landSingle";
import Contact from "./pages/contact";
import Privacy from "./pages/privacy-policy";
import SignIn from "./pages/user/signIn";
import SignUp from "./pages/user/signUp";
import Reset from "./pages/user/resetPassword";
import Favorites from "./pages/projects/favourites";
import { Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import ManagementTeam from "./pages/management-team";
import FloatingBtn from "./components/FloatingBtn";
import DarkModeToggle from "./components/DarkModeToggle";
import DarkModeStyle from "./styles/DarkModeStyle";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Loading } from "./components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { RequireAuth } from "./components/RequireAuth";
import Cursor from "./components/Cursor";
import { AnimatePresence } from "framer-motion";
import Popup from "./components/home/Popup";
import { cleanData } from "./api/redux/user";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  const store = useSelector((store) => store);
  const el = useRef();
  let smoother;
  useLayoutEffect(() => {
    ScrollTrigger.refresh();
    if (window.innerWidth > 768) {
      smoother = ScrollSmoother.create({
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        // speed: 2,
        smoothTouch: 0.1,
        // ignoreMobileResize: true,
      });
    }
    setTimeout(() => {
      if (location.hash) {
        smoother?.scrollTo(location.hash, 3);
      }
    }, 500);
    window.scroll(0, 0);
  }, [location, location.pathname, location.hash]);

  // useEffect(() => {
  //   document
  //     .querySelector(".main-menu__logo")
  //     .addEventListener("click", function () {
  //       window.scrollTo(0, 0);
  //     });
  // }, [location.pathname]);

  // route change scroll top & page class
  useEffect(() => {
    if (location.pathname === "/") {
      document.body.classList.add("in-home-page");
    } else {
      document.body.classList.remove("in-home-page");
    }

    setTimeout(() => {
      window.scroll(0, 0);
    }, 100);
  }, [location.pathname]);

  const success = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  // const popupData = getPost?.data?.data?.sections?.find(
  //   (f) => f?.section_data?.id === 125
  // );

  // popup data
  const PopData = store?.homeReducer?.data?.data?.sections?.find(
    (f) => f?.section_data?.id === 125
  );

  // add to favourite message
  useEffect(() => {
    if (!store?.userReducer?.formLoading && store?.userReducer?.data?.status) {
      success(store?.userReducer?.data?.message);
      setTimeout(() => {
        dispatch(cleanData());
      }, 300);
    }
  }, [store]);
  return (
    <>
      <PageTransition />
      <GlobalStyle />
      <DarkModeToggle />
      {store.careerReducer?.formLoading && <Loading />}
      {store.projectReducer?.formLoading && <Loading />}
      {/*{location.pathname !== "/" && store.projectReducer?.loading && <Loading/>}*/}
      {store.landReducer?.formLoading && <Loading />}
      {store.contactReducer?.formLoading && <Loading />}
      {store.userReducer?.formLoading && <Loading />}

      <Container />
      <ToastContainer />
      <Menu />
      <FloatingBtn />
      {location.pathname === "/" && PopData?.images?.list?.[0] && (
        <Popup data={PopData} />
      )}
      <DarkModeStyle />
      <div className="App" ref={el} id="smooth-wrapper">
        <div id="smooth-content">
          <AnimatePresence
            location={location}
            key={location.pathname}
            exitBeforeEnter
          >
            <Switch location={location} key={"location.pathname"}>
              <Route exact path="/" component={Home} />
              <Route exact path="/components" component={Components} />
              <Route exact path="/about" component={About} />
              <Route exact path="/awards" component={Awards} />
              <Route exact path={`/media-center`} component={MediaCenter} />
              <Route exact path={`/newsletters`} component={Newsletters} />
              <Route
                exact
                path={`/media-center/:slug`}
                component={MediaCenterDetail}
              />
              <Route exact path={`/csr/:slug`} component={CSRDetail} />
              <Route exact path={`/landowner`} component={Landowner} />
              <Route exact path={`/customer`} component={Customer} />
              <Route exact path={`/career`} component={Career} />
              <Route exact path={`/projects`} component={Projects} />
              <Route
                exact
                path={`/projects/sapphire-series`}
                component={SapphireSeries}
              />
              <Route exact path={`/projects/land`} component={LandProject} />
              <Route
                exact
                path={`/projects/favourites`}
                component={Favorites}
              />
              <Route exact path={`/project/:slug`} component={ProjectDetail} />
              <Route exact path={`/land/:slug`} component={landDetail} />
              <Route exact path={`/contact`} component={Contact} />
              <Route exact path={`/privacy-policy`} component={Privacy} />
              <Route exact path={`/user/sign-in`}>
                <RequireAuth>
                  <SignIn />
                </RequireAuth>
              </Route>
              <Route exact path={`/user/sign-up`}>
                <RequireAuth>
                  <SignUp />
                </RequireAuth>
              </Route>
              <Route exact path={`/user/reset-password`} component={Reset} />
              <Route
                exact
                path={`/management-team`}
                component={ManagementTeam}
              />
              <Route component={Error} />
            </Switch>
          </AnimatePresence>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
