import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "../Button";
import {whites} from "../../styles/globalStyleVars";
import Title from "../Title";

const MyComponent = () => {
    return (
        <StyledComponent className={'no-favourites'}>
            <Container>
                <Row>
                    <Col md={5} sm={12}>
                        <Title color={whites} text={'Favourites'}/>
                        <p>You can save your favourite <br/> projects here </p>
                        <Button margin={'25px 0 0 0 '} text={'Explore All Projects'} src={'/projects'}/>
                    </Col>

                    <Col md={7} sm={12}>
                        <img src="/images/static/favourites.png" alt=""/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #08082B;
  padding-top: 200px;
  padding-bottom: 100px;

  .title {
    margin-bottom: 100px;
  }

  p {
    color: ${whites};
  }

  @media (max-width: 767px) {
    padding-top: 120px;
    .title {
      margin-bottom: 40px;
    }

    .dc-btn {
      margin-bottom: 20px;
    }
  }
`;

export default MyComponent;
