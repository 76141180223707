import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Newsletters | Sheltech Pvt Ltd</title>
            </Helmet>

            <StyledComponent>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
