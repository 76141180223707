import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import SliderPrevWhite from "../SliderPrevWhite";
import SliderNextWhite from "../SliderNextWhite";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [index, setIndex] = useState(1)

    return (
        <StyledComponent className={'excellence pt-200 no-line'}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="excellence__img">
                            <Title text={data?.section_data?.short_desc}/>
                            {data?.posts?.list?.length > 0 &&
                                <Swiper loop={true}
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        allowSlideNext={true}
                                        allowSlidePrev={true}
                                        allowTouchMove={false}
                                        speed={500}
                                        effect={'fade'}
                                        fadeEffect={{
                                            crossFade: true
                                        }}
                                    // pagination={{
                                    //     type: "fraction",
                                    // }}
                                        navigation={{
                                            prevEl: '.excellence .slider_prev',
                                            nextEl: '.excellence .slider_next',
                                        }}
                                        modules={[Autoplay, Pagination, Navigation, EffectFade]}

                                        autoplay={false}

                                    // autoplay={{
                                    //     delay: 5000,
                                    //     disableOnInteraction: false,
                                    // }}
                                >
                                    {data?.posts?.list?.length > 0 && data?.posts?.list?.map(i => (
                                        <SwiperSlide key={i?.data?.id}>
                                            <img src={i?.images?.[0]?.full_path} alt=""/>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            }
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="excellence__content">
                            <Title text={data?.section_data?.short_desc}/>
                            <div className="excellence__content__top">
                                {/*<p>{index}<span>/{data && data?.posts?.list?.length}</span></p>*/}
                                <ul className="nav">
                                    <li className={'slider_prev'}><SliderPrevWhite borderColor={'#08082B'}
                                                                                   color={'#08082B'}
                                                                                   hoverBorder={'#08082B'}
                                                                                   hoverBackground={'#08082B'}
                                                                                   hoverColor={'#FFF'}/></li>
                                    <li className={'slider_next'}><SliderNextWhite borderColor={'#08082B'}
                                                                                   color={'#08082B'}
                                                                                   hoverBorder={'#08082B'}
                                                                                   hoverBackground={'#08082B'}
                                                                                   hoverColor={'#FFF'}/></li>
                                </ul>

                            </div>

                            <div className="excellence__content__slider">
                                {data?.posts?.list?.length > 0 &&
                                    <Swiper loop={true}
                                            spaceBetween={30}
                                            slidesPerView={1}
                                            allowSlideNext={true}
                                            allowSlidePrev={true}
                                            allowTouchMove={false}
                                            speed={900}
                                            infinity={true}
                                        // pagination={{
                                        //     type: "fraction",
                                        // }}
                                            navigation={{
                                                prevEl: '.excellence .slider_prev',
                                                nextEl: '.excellence .slider_next ',
                                            }}
                                            modules={[Autoplay, Pagination, Navigation]}

                                            autoplay={false}

                                        // autoplay={{
                                        //     delay: 5000,
                                        //     disableOnInteraction: false,
                                        // }}
                                            onSlideChange={(swiper) => setIndex(swiper.activeIndex + 1)}
                                    >
                                        {data?.posts?.list?.length > 0 && data?.posts?.list?.map(i => (
                                            <SwiperSlide key={i?.data?.id}>
                                                <h4>{i?.data?.title} </h4>
                                                <p>{reactHtmlParser(i?.data?.description)}</p>
                                            </SwiperSlide>
                                        ))}


                                    </Swiper>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #D6D6D6;
  position: relative;

  .excellence__img {
      .title{
          display: none;
      }
    min-height: 100%;
    display: flex;
    align-items: flex-end;

    svg {
      max-width: 100%;
      position: relative;
      z-index: 2;
    }

    img {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(245deg) brightness(0) contrast(56%);
    }
      @media(max-width: 767px){
          display: unset;
          .title{
              display: block;
          }
      }
  }

  .excellence__content {
    &__top {
      margin-top: 80px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(8, 8, 43, 0.5);

      p {
        font-size: 24px;
        line-height: 32px;
        color: #08082b;

        span {
          font-size: 12px;
          line-height: 16px;
          color: rgba(8, 8, 43, 0.63);
          display: inline-block;
          padding-left: 3px;
        }
      }
    }

    &__slider {
      h4 {
        font-size: 24px;
        line-height: 32px;
        color: #08082b;
        margin-bottom: 20px;
      }

      p {
        color: rgba(8, 8, 43, 0.6);

      }
    }
      .title{
          display: block;
          @media(max-width: 767px){
              display: none;
          }
      }
  }

  @media (max-width: 767px) {
    .excellence__content {
      margin-top: 40px;

      &__top {
        margin-top: 40px;
      }
    }
  }
`;

export default MyComponent;
