import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import Select, {components} from "react-select";

// const SelectField = ({next_id, prev_id, color, placeholder, selection, onSelect}) => {
//     const selectLocationInputRef = useRef();
//     const [selectLocation, setSelectLocation] = useState('')
//
//     const types = [
//         {value: 'commercial', label: 'Commercial'},
//         {value: 'residential', label: 'Residential'},
//     ]
//
//
//     const customStyles = {
//         dropdownIndicator: (base, state) => ({
//             ...base,
//             transition: "all .2s ease",
//             transform: state.selectProps.menuIsOpen && "rotate(180deg)"
//         }),
//         option: (provided, state) => ({
//             ...provided,
//             borderRadius: 0,
//             color: state.isSelected ? `${text}` : '#221F1F',
//             backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
//             margin: 0,
//             fontSize: 14,
//             cursor: 'pointer',
//             paddingLeft: 10,
//             paddingRight: 10,
//             fontWeight: state.isSelected ? 700 : 400,
//             "&:hover": {
//                 backgroundColor: `${text}`,
//                 color: '#FFF',
//                 cursor: 'pointer'
//             },
//
//         }), menu: (provided, state) => ({
//             ...provided,
//             color: '#FFF',
//             backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255,0)',
//             margin: 0,
//             borderRadius: 0,
//             fontSize: 14,
//             zIndex: 10
//             // width: 200,
//         }), menuList: (provided, state) => ({
//             ...provided,
//             backgroundColor: state.isSelected ? '#191818' : '#FFF',
//             borderRadius: 0,
//             fontWeight: '400',
//             color: '#FFF',
//             fontSize: 14,
//             // zIndex: 9999
//         }),
//
//     };
//
//     const DropdownIndicator = props => {
//         return (
//             <components.DropdownIndicator {...props}>
//                 <CaretDownIcon/>
//             </components.DropdownIndicator>
//         );
//     };
//     const CaretDownIcon = () => {
//         return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
//             <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
//                 <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
//                       stroke="#7E7576" stroke-linecap="round" stroke-width="1"/>
//                 <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
//                       stroke="#7E7576" stroke-linecap="round" stroke-width="1"/>
//             </g>
//         </svg>
//             ;
//     };
//
//
//     const handleLocation = (e) => {
//         setSelectLocation(e);
//     }
//
//     return (
//         <StyledSelectField>
//             <div className="form-group">
//                 <Form.Group controlId="formBasicPhone">
//                     <Select
//                         classNamePrefix="filter"
//                         ref={selectLocationInputRef}
//                         isSearchable={false}
//                         options={selection ? selection : types}
//                         // onChange={e => {
//                         //     handleLocation(e?.value);
//                         // }}
//                         onChange={(selectedOption) => {
//                             onSelect(selectedOption); // Call the callback function with the selected option
//                         }}
//                         placeholder={`${placeholder ? placeholder : 'Select Location'}`} styles={customStyles}
//                         components={{DropdownIndicator}}
//                     />
//                 </Form.Group>
//             </div>
//         </StyledSelectField>
//     )
// };
const SelectField = ({ next_id, prev_id, color, placeholder, selection, onSelect }) => {
    const selectLocationInputRef = useRef();
    const [selectLocation, setSelectLocation] = useState(null); // Track selected option

    const types = [
        { value: 'commercial', label: 'Commercial' },
        { value: 'residential', label: 'Residential' },
    ];

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? `#FFF` : '#221F1F',
            backgroundColor: state.isSelected ? `#08082b` : '#FFF',
            fontSize: 15,
            cursor: 'pointer',
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                cursor: 'pointer'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#FFF',
            fontSize: 15,
            zIndex: 10
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: '#FFF',
            fontSize: 15,
        }),
    };

    // Dropdown indicator component with custom caret icon
    const DropdownIndicator = props => (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );

    // Custom caret icon SVG
    const CaretDownIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#7E7576" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#7E7576" strokeLinecap="round" strokeWidth="1"/>
            </g>
        </svg>
    );

    // Handle selection
    const handleLocation = (selectedOption) => {
        setSelectLocation(selectedOption);
        onSelect(selectedOption); // Call parent onSelect
    };

    // Clear selection
    const clearSelection = () => {
        setSelectLocation(null);
        onSelect(null); // Clear selection in parent
    };

    return (
        <StyledSelectField>
            <div className="form-group" style={{ position: 'relative' }}>
                <Form.Group controlId="formBasicPhone">
                    <Select
                        classNamePrefix="filter"
                        ref={selectLocationInputRef}
                        isSearchable={false}
                        options={selection ? selection : types}
                        value={selectLocation} // Track selected option
                        onChange={handleLocation}
                        placeholder={placeholder || 'Select Location'}
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                    />
                    {/* Clear icon */}
                    {selectLocation && (
                        <span
                            className="clear-icon"
                            onClick={clearSelection}
                        >
                            ×
                        </span>
                    )}
                </Form.Group>
            </div>
        </StyledSelectField>
    );
};

const StyledSelectField = styled.div`
  position: relative;


  //react select

  .filter__control {
    height: 50px;
    border-radius: 0 !important;
    font-size: 16px;
    outline: none !important;
    border-color: rgba(38, 32, 30, 0.5) !important;
    box-shadow: none !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    padding: 0;
    cursor: pointer;
    background: transparent;


    .filter__value-container, .filter__indicator {
      padding-left: 0;
      padding-right: 0;
    }

    .filter__placeholder {
      //color: rgba(38, 32, 30, 0.5);
      outline: none;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-transform: capitalize;
    }

    .filter__single-value {
      color: rgba(38, 32, 30, 1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-transform: capitalize;
    }

    .filter__indicator-separator {
      display: none;
    }

    .filter__indicators, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .filter__control.filter__control--is-focused {
    border-color: rgba(38, 32, 30, 1) !important;

    .filter__indicator {
      svg {
        line {
          stroke: rgba(38, 32, 30, 1) !important;;
        }
      }
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }

    .clear-icon {
        position: absolute;
        right: 40px !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 16px;
        color: #08082B !important;
    }
    .clear-icon:hover {
        color: #888;
    }


`;

export default React.memo(SelectField);














