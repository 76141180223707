import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Text from "../../components/project/Text";
import AtGlance from "../../components/project/AtGlance";
import Video from "../../components/project/Video";
import LocationMap from "../../components/project/LocationMap";
import Form from "../../components/project/Form";
import DetailBanner from "../../components/project/DetailBanner";
import Timeline from "../../components/project/Timeline";
import Amenities from "../../components/project/Amenities";
import FloorPlan from "../../components/project/FloorPlan";
import Gallery from "../../components/project/Gallery";
import RelatedProjects from "../../components/project/RelatedProjects";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchData, fetchDetail } from "../../api/redux/projects";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


const MyComponent = () => {
  const dispatch = useDispatch();
  let getPost = useSelector((state) => state.projectReducer);
  const endPoints = apiEndPoints.PROJECT_DETAIL;
  const location = useLocation();
  const store = useSelector((e) => e);

  const params = {
    [ApiParam.type]: "slug",
    [ApiParam.value]: location.pathname.replace("/project/", ""),
    [ApiParam.location]: "",
    [ApiParam.image]: "yes",
    [ApiParam.file]: "yes",
    [ApiParam.post]: "yes",
    [ApiParam.id]: localStorage.getItem("sheltechuserid"),
  };

  useEffect(() => {
    dispatch(fetchDetail([endPoints, params]));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchDetail([endPoints, params]));
    }, 600);
  }, [store?.userReducer.formLoading]);

  const Detail = getPost?.detail?.data;

  // section data refactor
  const overview = Detail?.posts?.list?.find(
    (f) => f.data?.template === "overview"
  );
  const atGlance = Detail?.posts?.list?.find(
    (f) => f.data?.template === "at_a_glance"
  );
  const progress = Detail?.posts?.list?.find(
    (f) => f.data?.template === "progress"
  );
  const feature = Detail?.posts?.list?.find(
    (f) => f.data?.template === "feature_amenity"
  );
  const floor = Detail?.posts?.list?.find(
    (f) => f.data?.template === "floor_plan"
  );
  const floorFile = Detail?.files?.list?.[0];
  const gallery = Detail?.posts?.list?.find(
    (f) => f.data?.template === "gallery"
  );
  const video = Detail?.posts?.list?.find((f) => f.data?.template === "video");
  const map = Detail?.posts?.list?.find((f) => f.data?.template === "map");
  const projectData = Detail?.product_data;
  const ReletedProduct = getPost?.detail?.related_projects?.list;
  const ProductProgress = getPost?.detail?.data?.product_data?.progress;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        {Detail?.product_data?.title ? (
            <title>
              {" "}
              {Detail && Detail?.product_data?.title} | Sheltech Pvt Ltd{" "}
            </title>
        ) : (
            <title> Sheltech Pvt Ltd </title>
        )}

        <meta name="description" content={Detail?.product_data?.meta_description}/>
        <meta property="og:title" content={Detail?.product_data?.og_title}/>
        <meta property="og:description" content={Detail?.product_data?.og_description}/>
      </Helmet>

      <StyledComponent className={"project-single"}>
        <DetailBanner
            posts={Detail?.posts?.list}
            favourite={Detail?.product_data?.is_favorite}
            id={Detail?.product_data?.id}
          address={Detail?.product_data?.location}
          subTitle={Detail?.product_data?.types}
          img={
            Detail?.images?.list?.find((f) => f?.desktop === "on")?.full_path
          }
          title={Detail?.product_data?.title}
        />
        {overview?.data?.description && (
          <Text id={overview?.data?.slug} data={overview} />
        )}
        {atGlance?.data?.title && (
          <AtGlance id={atGlance?.data?.slug} data={atGlance} />
        )}
        {ProductProgress && (
          <Timeline progress={ProductProgress} id={"progress"} />
        )}
        {feature?.data?.title && (
          <Amenities id={feature?.data?.slug} data={feature} />
        )}
        {floor?.data?.title && (
          <FloorPlan file={floorFile} id={floor?.data?.slug} data={floor} />
        )}
        {gallery?.data?.title && (
          <Gallery id={gallery?.data?.slug} data={gallery} />
        )}
        {video?.data?.title && <Video id={video?.data?.slug} data={video} />}
        {map?.data?.title && (
          <LocationMap
            projectData={projectData}
            id={map?.data?.slug}
            data={map}
          />
        )}
        <Form id={"contact"} />
        {ReletedProduct && <RelatedProjects data={ReletedProduct} />}
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  .video-modal {
    padding-top: 100px;
  }

  //.texts-section {
  //  padding-bottom: 0 !important;
  //}

  //section:not(.detail-banner) {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
