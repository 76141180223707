import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  body,
  ease,
  hover,
  text,
  title,
  whites,
} from "../styles/globalStyleVars";
import { CSSPlugin, gsap } from "gsap";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import CloseButton from "./CloseButton";
import Select, { components } from "react-select";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../api/network/apiEndPoints";
import { ApiParam } from "../api/network/apiParams";
import { fetchData } from "../api/redux/projects";
import { toast } from "react-toastify";
import { lock, unlock } from "tua-body-scroll-lock";

const MyComponent = () => {
  const dispatch = useDispatch();
  const getProductData = useSelector((store) => store.projectReducer);
  gsap.registerPlugin(CSSPlugin);
  // offset
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(document.querySelector(".container")?.offsetLeft + 16);
    window.addEventListener("resize", () => {
      setOffset(document.querySelector(".container")?.offsetLeft + 16);
    });
  }, []);

  const location = useLocation();

  // dropdown style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? `#FFF` : "#221F1F",
      backgroundColor: state.isSelected ? `#08082b` : "#FFF",
      margin: 0,
      cursor: "pointer",
      zIndex: 9999999999,
      ":hover": {
        backgroundColor: "#08082B",
        color: "#FFF",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "rgba(0,0,0,1)",
      backgroundColor: state.isSelected
        ? "rgba(0,0,0,0)"
        : "rgba(255,255,255,0)",
      margin: 0,
      zIndex: 9999999999,
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : "#FFF",
      borderRadius: 0,
      cursor: "pointer",
      zIndex: 9999999999,
    }),
  };

  // options
  const dis = [
    { value: "All", label: "All " },
    { value: "Dhaka", label: "Dhaka " },
    { value: "Chittagong", label: "Chittagong " },
  ];
  // const type = [
  //     {value: 'residential', label: 'Residential '},
  //     {value: 'completed', label: 'Completed '},
  //     {value: 'upcoming', label: 'Upcoming '},
  // ];
  // const status = [
  //     {value: '1200', label: 'Ongoing '},
  //     {value: '1400', label: 'Completed'},
  //     {value: '1600', label: 'Upcoming'},
  // ];
  // const Location = [
  //     {value: '1200', label: 'Dhaka '},
  //     {value: '1400', label: 'Chittagong'},
  // ];

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.732"
              height="7.003"
              viewBox="0 0 11.732 7.003"
            >
              <g
                id="Group_24047"
                data-name="Group 24047"
                transform="translate(18853.867 -10435.998)"
              >
                <g
                  id="Group_24046"
                  data-name="Group 24046"
                  transform="translate(-18852.863 10442.258) rotate(-93)"
                >
                  <line
                    id="Line_12380"
                    data-name="Line 12380"
                    x2="5"
                    y2="5"
                    fill="none"
                    stroke="#08082b"
                    stroke-linecap="round"
                    stroke-width="1"
                  />
                  <line
                    id="Line_12381"
                    data-name="Line 12381"
                    y1="5"
                    x2="5"
                    transform="translate(0 5)"
                    fill="none"
                    stroke="#08082b"
                    stroke-linecap="round"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.414"
              height="6.414"
              viewBox="0 0 11.414 6.414"
            >
              <g
                id="Group_22451"
                data-name="Group 22451"
                transform="translate(10.707 0.707) rotate(90)"
              >
                <line
                  id="Line_12380"
                  data-name="Line 12380"
                  x2="5"
                  y2="5"
                  fill="none"
                  stroke="#08082b"
                  stroke-linecap="round"
                  stroke-width="1"
                />
                <line
                  id="Line_12381"
                  data-name="Line 12381"
                  y1="5"
                  x2="5"
                  transform="translate(0 5)"
                  fill="none"
                  stroke="#08082b"
                  stroke-linecap="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  // menu action
  useEffect(() => {
    // menu open
    let getMenu = document.querySelector(".slide-menu");
    let getClick = document.querySelector(".hamburger");
    let getCloseClick = document.querySelector(".slide-menu__close");
    const ulWrapper = document.querySelector("#project-search__right");
    let tl = gsap.timeline();
    getClick.addEventListener("click", function (e) {
      document.body.classList.add("menu-open");
      // if (window.innerWidth > 1200) {
      //   setTimeout(() => {
      //     disableBodyScroll(e);
      //   }, 300);
      // }

      tl.to(getMenu, {
        display: "flex",
        duration: 0,
      })
        .to(
          ".menu-overlay",
          {
            display: "block",
            duration: 0,
          },
          "-=.3"
        )
        .to(".menu-overlay", {
          opacity: 1,
        })
        .to(
          getMenu,
          {
            x: 0,
            // duration: 1,
            ease: "Power4.easeInOut",
          },
          "-=.5"
        )
        .to(
          ".slide-menu li,.accordion-item ",
          {
            y: 0,
            opacity: 1,
            ease: "Power4.easeInOut",
            stagger: 0.02,
          },
          "-=.3"
        );
    });

    // menu close
    window.addEventListener("click", (e) => {
      if (document.body.classList.contains("menu-open")) {
        if (
          !e.target.matches(
            ".search-action,.search-action img, .hamburger,.hamburger span, .accordion-header, .accordion-header button,.accordion-header span, .accordion-header img, .accordion-collapse , .accordion-collapse div, .accordion-collapse ul, .accordion-collapse li,.slide-menu,.accordion, .accordion li"
          )
        ) {
          tl.to(".slide-menu li,.accordion-item ", {
            y: 20,
            opacity: 0,
            ease: "Power4.easeInOut",
            stagger: 0.01,
            duration: 0.4,
          })
            .to(
              getMenu,
              {
                x: "100%",
              },
              "-=.3"
            )
            .to(getMenu, {
              display: "node",
              duration: 0,
            })
            .to(
              ".menu-overlay",
              {
                display: "none",
                opacity: 0,
              },
              "-=.3"
            );
          document.body.classList.remove("menu-open");
        }
      }
    });
  }, []);

  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains("scroll-down")) {
      document.body.classList.remove("scroll-down");
    }
  });

  useEffect(() => {
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;
    let howMuchScroll;

    if (window.screen.width < 991) {
      howMuchScroll = 150;
    } else {
      howMuchScroll = 150;
    }

    window.addEventListener("scroll", () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        body.classList.remove(scrollUp);
        body.classList.remove(scrollDown);
        return;
      }

      if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }

      lastScroll = currentScroll;
    });
  }, []);

  // search toggle action
  useEffect(() => {
    const tl = gsap.timeline();
    const searchClick = document.querySelectorAll(".search-action");

    searchClick.forEach((e) => {
      e.addEventListener("click", function (e) {
        e.stopPropagation();
        document.body.classList.add("open-search");
        if (window.innerWidth > 600) {
          setTimeout(() => {
            disableBodyScroll(e);
          }, 300);
        }
        tl.to(".project-search", {
          display: "block",
          duration: 0,
        })
          .to(".menu-overlay", {
            display: "block",
            opacity: 1,
            // duration: .3,
          })
          .to(
            ".project-search",
            {
              x: 0,
              ease: "Power4.easeInOut",
              duration: 0.8,
            },
            "-=.3"
          )
          .to(
            ".project-search__left,.project-search__right",
            {
              y: 0,
              opacity: 1,
              stagger: 0.08,
            },
            "-=.4"
          );
      });
    });

    // search close
    window.addEventListener("click", (e) => {
      if (
        !e.target.matches(
          ".search-action,.search-action img, .search-action svg,.hamburger,.hamburger span,.search-action path, .project-search, .project-search__wrap,.project-search__left__selects, .project-search__left, .project-search__left input, .project-search__left button , .project-search__left svg, .project-search__left path, .project-search__left__selects div, .project-search__left__selects span, .project-search__left__selects svg, .project-search__right , .project-search__right h3, .project-search__right div,.custom__control,.custom__control div,.custom__option,.custom__option div, .custom__option .custom__option--is-focused "
        )
      ) {
        if (document.body.classList.contains("open-search")) {
          if (window.innerWidth > 600) {
            setTimeout(() => {
              enableBodyScroll(e);
            }, 300);
          }

          tl.to(".project-search__left,.project-search__right", {
            y: 50,
            opacity: 0,
            stagger: 0.08,
            duration: 0.5,
          })
            .to(
              ".project-search",
              {
                x: "100%",
                ease: "Power4.easeInOut",
                duration: 0.6,
              },
              "-=.5"
            )
            .to(".project-search", {
              display: "none",
              duration: 0,
            })
            .to(
              ".menu-overlay",
              {
                display: "none",
                opacity: 0,
              },
              "-=.3"
            );
          document.body.classList.remove("open-search");
        }
      }
    });
  }, []);

  // ------  search action
  const data = getProductData;
  const [searchNumber, setSearchNumber] = useState("");
  // data refactor
  const catFilter = data?.data?.filter?.category;
  const locationFilter = data?.data?.filter?.area_list;
  const sizeFilter = data?.data?.filter?.size;
  const typeFilter = data?.data?.filter?.type;

  // options
  const optionsOne = typeFilter?.map((i) => ({
    value: i?.id,
    label: i?.title,
  }));

  const optionsTwo = catFilter?.map((i) => ({ value: i?.id, label: i?.title }));

  const Location = locationFilter?.map((e, i) => ({
    value: e?.area,
    label: e?.area,
  }));

  const size = sizeFilter?.map((i) => ({ value: i?.size, label: i?.size }));

  // dropdown handler
  const [area, setArea] = useState("All");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [sizes, setSizes] = useState("");
  const [locations, setLocations] = useState("");

  const handleArea = (e) => {
    setArea(e || "");
  };
  const handleType = (e) => {
    setType(e || "");
  };
  const handleStatus = (e) => {
    setStatus(e || "");
  };
  const handleSize = (e) => {
    setSizes(e || "");
  };
  const handleLocation = (e) => {
    setLocations(e?.toLowerCase() || "");
  };

  const endPoints = apiEndPoints.PROJECT;
  // api call
  useEffect(() => {
    if (area || type || status || sizes || locations) {
      const params = {
        [ApiParam.type]: type,
        [ApiParam.category]: status,
        [ApiParam.location]: area,
        [ApiParam.area]: locations,
        [ApiParam.image]: "yes",
        [ApiParam.size]: sizes,
      };
      dispatch(fetchData([endPoints, params]));
      // console.log('type call list')
    }
    setSearchNumber("");
    document.querySelector(".project-search__left input").value = "";
    // onTypeSearch()
  }, [area, type, status, sizes, locations]);

  // filters on input type

  const onTypeSearch = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.querySelector(".project-search__left input");
    filter = input?.value.toLowerCase();
    ul = document.querySelector(".place ul");

    const array = [];

    li = document.querySelectorAll(
      ".project-search__right__list .single-project"
    );

    for (i = 0; i < li.length; i++) {
      txtValue = li[i].querySelector("h4").innerText;

      if (txtValue.toLowerCase().indexOf(filter) > -1) {
        li[i].style.display = "";
        array.push(i);
      } else {
        li[i].style.display = "none";
      }
    }

    // console.log('array', array)
    setSearchNumber(array?.length);
  };

  const success = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  // login check
  const [login, setLogin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("sheltechuser")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  });

  // logout
  const handleLogout = () => {
    localStorage.clear();
    success("Logout Success");
    setLogin(false);
  };

  // menu show hide on router change
  useEffect(() => {
    gsap
      .timeline()
      .to(".main-menu", {
        opacity: 0,
        duration: 0.03,
      })
      .to(".main-menu", {
        opacity: 1,
        delay: 0.8,
        duration: 0.5,
      });
  }, [location.pathname]);

  return (
    <StyledComponent
      className={"main-menu"}
      style={{ paddingLeft: offset + "px" }}
    >
      <div className="menu-overlay" />
      <div className="main-menu__logo">
        {location.pathname === "/" ? (
          <>
            <a>
              <img src="/images/static/logo.svg" alt="" />
            </a>
            <a>
              <img src="/images/static/logo-colored.svg" alt="" />
            </a>
          </>
        ) : (
          <>
            <Link to={"/"}>
              <img src="/images/static/logo.svg" alt="" />
            </Link>
            <Link to={"/"}>
              <img src="/images/static/logo-colored.svg" alt="" />
            </Link>
          </>
        )}
      </div>

      {/*menu bar */}
      <div className="main-menu__bar">
        <ul>
          {login ? (
            <li className={"user-thumb"}>
              <Link className={"slide"} to={"#"}>
                <svg width="16" height="18.909" viewBox="0 0 16 18.909">
                  <g id="User" transform="translate(-5 -3)">
                    <path
                      id="Path_10783"
                      data-name="Path 10783"
                      d="M18.627,16.1A.727.727,0,1,0,17.6,17.136a6.5,6.5,0,0,1,1.941,4.652c0,.89-2.55,2.182-6.545,2.182s-6.545-1.293-6.545-2.183A6.5,6.5,0,0,1,8.371,17.16a.727.727,0,1,0-1.028-1.028A7.947,7.947,0,0,0,5,21.789c0,2.362,4.121,3.636,8,3.636s8-1.274,8-3.636A7.942,7.942,0,0,0,18.627,16.1Z"
                      transform="translate(0 -3.516)"
                      fill="#f5f5f5"
                    />
                    <path
                      id="Path_10784"
                      data-name="Path 10784"
                      d="M14.091,13.182A5.091,5.091,0,1,0,9,8.091,5.091,5.091,0,0,0,14.091,13.182Zm0-8.727a3.636,3.636,0,1,1-3.636,3.636,3.636,3.636,0,0,1,3.636-3.636Z"
                      transform="translate(-1.091 0)"
                      fill="#f5f5f5"
                    />
                  </g>
                </svg>
              </Link>
              <p onClick={handleLogout}>Logout</p>
            </li>
          ) : (
            <li>
              <OverlayTrigger
                placement={"bottom"}
                overlay={<Tooltip id={`Login`}>Login</Tooltip>}
              >
                <Link className={"slide"} to={"/user/sign-in"}>
                  <svg width="16" height="18.909" viewBox="0 0 16 18.909">
                    <g id="User" transform="translate(-5 -3)">
                      <path
                        id="Path_10783"
                        data-name="Path 10783"
                        d="M18.627,16.1A.727.727,0,1,0,17.6,17.136a6.5,6.5,0,0,1,1.941,4.652c0,.89-2.55,2.182-6.545,2.182s-6.545-1.293-6.545-2.183A6.5,6.5,0,0,1,8.371,17.16a.727.727,0,1,0-1.028-1.028A7.947,7.947,0,0,0,5,21.789c0,2.362,4.121,3.636,8,3.636s8-1.274,8-3.636A7.942,7.942,0,0,0,18.627,16.1Z"
                        transform="translate(0 -3.516)"
                        fill="#f5f5f5"
                      />
                      <path
                        id="Path_10784"
                        data-name="Path 10784"
                        d="M14.091,13.182A5.091,5.091,0,1,0,9,8.091,5.091,5.091,0,0,0,14.091,13.182Zm0-8.727a3.636,3.636,0,1,1-3.636,3.636,3.636,3.636,0,0,1,3.636-3.636Z"
                        transform="translate(-1.091 0)"
                        fill="#f5f5f5"
                      />
                    </g>
                  </svg>
                </Link>
              </OverlayTrigger>
            </li>
          )}

          <li>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`fav`}>My Favorites</Tooltip>}
            >
              <Link className={"slide"} to={"/projects/favourites"}>
                <svg width="18.506" height="16.5" viewBox="0 0 18.506 16.5">
                  <path
                    id="Favorite_1_"
                    data-name="Favorite (1)"
                    d="M13.6,0h0c-1.9,0-3.5,1.5-4.3,2.5C8.4,1.5,6.8,0,4.9,0h0A5.216,5.216,0,0,0,0,5.4,5.238,5.238,0,0,0,1.8,9.5a.1.1,0,0,0,.1.1l6.9,6.7a.758.758,0,0,0,.4.2h0a.52.52,0,0,0,.4-.2l7.2-6.9.1-.1c.1,0,.1-.1.2-.2l.1-.1a5.876,5.876,0,0,0,1.3-3.6A5.216,5.216,0,0,0,13.6,0Zm2.6,8.3-.1.1L9.2,15,2.9,8.8a.1.1,0,0,0-.1-.1A4.13,4.13,0,0,1,1.3,5.4,3.848,3.848,0,0,1,5,1.3c2,0,3.7,2.5,3.8,2.6,0,.1.2.2.4.2A.9.9,0,0,0,9.7,4l.1-.1s1.8-2.6,3.8-2.6a3.937,3.937,0,0,1,3.7,4.2,4.3,4.3,0,0,1-1.1,2.8Z"
                    transform="translate(0.003)"
                    fill="#f5f5f5"
                  />
                </svg>
              </Link>
            </OverlayTrigger>
          </li>

          <li className={"slide search-action"}>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`search`}>Search Projects</Tooltip>}
            >
              <div>
                <svg width="16" height="16.003" viewBox="0 0 16 16.003">
                  <path
                    id="Search"
                    d="M22.851,21.51l-3.076-3.076a7.064,7.064,0,1,0-1.341,1.341l3.076,3.076a.948.948,0,0,0,1.341-1.341Zm-12.321-3.7a5.15,5.15,0,1,1,3.642,1.508,5.156,5.156,0,0,1-3.642-1.508Z"
                    transform="translate(-7.129 -7.125)"
                    fill="#f5f5f5"
                  />
                </svg>
              </div>
            </OverlayTrigger>
          </li>

          <li
            style={{ paddingRight: offset + 30 + "px" }}
            className={"hamburger slide"}
          >
            <span />
            <span />
            <span />
          </li>
        </ul>
      </div>

      {/*menu item slide */}
      <div className="slide-menu">
        <CloseButton />

        <ul>
          <div className="goto-buttons">
            <li className={"search-action"} to={"#"}>
              <img src="/images/static/search-mobile.svg" alt="" />
            </li>
            <li>
              <Link to={"/projects/favourites"}>
                <img src="/images/static/favourite.svg" alt="" />
              </Link>
            </li>
          </div>
          <Accordion>
            <li className={location?.pathname === "/" ? "active" : ""}>
              <Link to={"/"}>Home</Link>
            </li>
            <Accordion.Item
              className={
                location?.pathname === "/about" ||
                location?.pathname === "/management-team"
                  ? "active"
                  : ""
              }
              eventKey="0"
            >
              <Accordion.Header>
                <span>About Us</span>{" "}
                <img src="/images/static/caret-down.svg" alt="" />
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to={"/about"}>CORPORATE INFO</Link>
                  </li>
                  <li>
                    <Link to={"/management-team"}>OUR LEADERS</Link>
                  </li>
                  <li>
                    <Link to={"/awards"}>Awards & Accolades </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              className={
                location?.pathname.startsWith("/projects") ? "active" : ""
              }
              eventKey="1"
            >
              <Accordion.Header>
                <span>Projects</span>{" "}
                <img src="/images/static/caret-down.svg" alt="" />
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to="/projects?type=residential">Residential</Link>
                  </li>
                  <li>
                    <Link to="/projects?type=commercial">Commercial</Link>
                  </li>
                  <li>
                    <Link to="/projects/sapphire-series">Sapphire Series</Link>
                  </li>
                  <li>
                    <Link to="/projects/land">Land</Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <li className={location?.pathname === "/landowner" ? "active" : ""}>
              <Link to={"/landowner"}>Landowner</Link>
            </li>
            <li
              className={location?.pathname === "/media-center" ? "active" : ""}
            >
              <Link to={"/media-center"}>Media Center</Link>
            </li>
            {/*<Accordion.Item className={location?.pathname === '/media-center' ? 'active' : ''} eventKey="3">*/}
            {/*    <Accordion.Header><span>Media Center</span> <img src="/images/static/caret-down.svg"*/}
            {/*                                                     alt=""/></Accordion.Header>*/}
            {/*    <Accordion.Body>*/}
            {/*        <ul>*/}
            {/*            <li><Link to="/media-center"> Media Center</Link></li>*/}
            {/*            <li><Link to="/media-center"> Newsletters </Link></li>*/}
            {/*        </ul>*/}
            {/*    </Accordion.Body>*/}
            {/*</Accordion.Item>*/}

            <li className={location?.pathname === "/career" ? "active" : ""}>
              <Link to={"/career"}>Career</Link>
            </li>
            <li className={location?.pathname === "/customer" ? "active" : ""}>
              <Link to={"/customer"}>Customer Enquiry</Link>
            </li>
            <li className={location?.pathname === "/contact" ? "active" : ""}>
              <Link to={"/contact"}>Contact</Link>
            </li>
          </Accordion>
        </ul>
      </div>

      {/*search */}
      <div className="project-search" id={"project-search"}>
        <CloseButton />
        <div className="project-search__wrap">
          <div className="project-search__left">
            <input onKeyUp={onTypeSearch} placeholder={"Search"} type="text" />
            <button type={"submit"}>
              <svg
                id="Search_Icon"
                data-name="Search Icon"
                width="17"
                height="17"
                viewBox="0 0 17 17"
              >
                <g id="search_1_" transform="translate(0)">
                  <path
                    id="Path_2196"
                    data-name="Path 2196"
                    d="M65.629,14.959a7.437,7.437,0,1,0-7.435-7.437A7.436,7.436,0,0,0,65.629,14.959Zm0-12.75a5.312,5.312,0,1,1-5.311,5.312A5.312,5.312,0,0,1,65.629,2.209Z"
                    transform="translate(-58.194 -0.084)"
                    fill="#f5f5f5"
                  />
                  <path
                    id="Path_2197"
                    data-name="Path 2197"
                    d="M5.079,303.381a1.063,1.063,0,0,0,.76-1.811l-.006-.006-3.994-3.995a1.086,1.086,0,1,0-1.508,1.562l3.994,3.942a1.062,1.062,0,0,0,.754.308Z"
                    transform="translate(10.853 -286.381)"
                    fill="#f5f5f5"
                  />
                </g>
              </svg>
            </button>

            <div className="project-search__left__selects">
              <Select
                onChange={(e) => handleArea(e?.value)}
                components={{ DropdownIndicator }}
                styles={customStyles}
                classNamePrefix={"custom"}
                className="select-here "
                placeholder={"Division"}
                options={dis}
                isClearable={true}
                isSearchable={false}
              />

              <Select
                onChange={(e) => handleType(e?.value)}
                components={{ DropdownIndicator }}
                styles={customStyles}
                classNamePrefix={"custom"}
                className="select-here "
                placeholder={"Type"}
                options={optionsOne}
                isClearable={true}
                isSearchable={false}
              />

              <Select
                onChange={(e) => handleStatus(e?.value)}
                components={{ DropdownIndicator }}
                styles={customStyles}
                classNamePrefix={"custom"}
                className="select-here "
                placeholder={"Status"}
                options={optionsTwo}
                isClearable={true}
                isSearchable={false}
              />

              <Select
                onChange={(e) => handleLocation(e?.value)}
                components={{ DropdownIndicator }}
                styles={customStyles}
                classNamePrefix={"custom"}
                className="select-here"
                placeholder={"Location"}
                options={Location}
                isClearable={true}
                isSearchable={false}
              />
            </div>
          </div>

          <div className="project-search__right" id={"project-search__right"}>
            <h3>
              <span>
                {searchNumber !== ""
                  ? searchNumber
                  : getProductData?.data?.data?.length}
              </span>{" "}
              Projects Found
            </h3>
            {/*<h3>{getProductData?.data?.data?.length} Results Found</h3>*/}

            {getProductData?.data?.data?.length > 0 &&
            window.innerWidth > 768 ? (
              <SimpleBar style={{ maxHeight: window.innerHeight - 150 + "px" }}>
                <div className="project-search__right__list">
                  {getProductData?.data?.data?.length > 0 &&
                    getProductData?.data?.data?.map((i) => (
                      <div key={i?.product_data?.id} className="single-project">
                        <Link to={`/project/${i?.product_data?.slug}`} />
                        <img
                          src={
                            i?.images?.list?.find((f) => f?.thumb === "on")
                              ?.full_path
                          }
                          alt=""
                        />
                        <div className="single-project__desc">
                          <h4>{i?.product_data?.title}</h4>
                          <p>
                            {i?.product_data?.type_title} <span></span>{" "}
                            {i?.product_data?.area}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </SimpleBar>
            ) : (
              <div className="project-search__right__list">
                {getProductData?.data?.data?.length > 0 &&
                  getProductData?.data?.data?.map((i) => (
                    <div key={i?.product_data?.id} className="single-project">
                      <Link to={`/project/${i?.product_data?.slug}`} />
                      <img
                        src={
                          i?.images?.list?.find((f) => f?.thumb === "on")
                            ?.full_path
                        }
                        alt=""
                      />
                      <div className="single-project__desc">
                        <h4>{i?.product_data?.title}</h4>
                        <p>
                          {i?.product_data?.type_title} <span></span>{" "}
                          {i?.product_data?.area}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  height: 64px;
  border-bottom: 1px solid #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  transition: all 0.6s ${ease};
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;

  .menu-overlay {
    position: fixed;
    height: 100svh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    inset: 0;
    display: none;
    opacity: 0;
  }

  .main-menu__logo {
    img {
      height: 32px;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  .main-menu__bar {
    .user-thumb {
      position: relative;

      p {
        position: absolute;
        background-color: #fff;
        top: 63px;
        padding: 10px 25px;
        left: 0;
        opacity: 0;
        transition: 0.5s ease;
        visibility: hidden;
      }

      &:hover {
        p {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    ul {
      display: flex;
      height: 100%;

      .slide {
        position: relative;

        &:after {
          content: "";
          height: 100%;
          left: 0;
          background-color: ${whites};
          position: absolute;
          width: 0;
          transition: width 0.6s ${ease};
        }
      }

      .search-action {
        position: relative;
        > div {
          /* background-color: #fff; */
          height: 100%;
          width: 100%;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      li {
        height: 100%;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        // &:after {
        //   content: '';
        //   height: 100%;
        //   left: 0;
        //   background-color: ${whites};
        //   position: absolute;
        //   width: 0;
        //   transition: width .6s ${ease};
        // }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
        }

        svg {
          position: relative;
          z-index: 3;

          path {
            transition: all 0.6s ease;
          }
        }

        &:nth-of-type(1) {
          border-left: 1px solid ${whites};
        }

        &:not(:nth-last-child(1)) {
          border-right: 1px solid ${whites};
        }

        //hover

        &:hover {
          &.slide:after,
          .slide:after {
            width: 100%;
          }

          path {
            //stroke: #212158;
            fill: #212158;
          }
        }
      }

      .hamburger {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 25px;

        span {
          height: 2px;
          width: 25px;
          background-color: ${whites};
          border-radius: 2px;
          display: block;
          position: relative;
          z-index: 2;
          transition: all 0.6s ${ease};

          &:nth-of-type(2) {
            margin: 5px 0;
            width: 15px;
          }

          &:nth-of-type(3) {
          }
        }

        &:hover {
          span {
            background-color: ${text};
          }
        }
      }
    }
  }

  //slide menu

  .slide-menu {
    position: fixed;
    height: ${`100svh`};
    width: 460px;
    right: 0;
    z-index: 9999;
    background-color: rgba(8, 8, 43, 0.87);
    padding: 80px 100px;
    //display: flex;
    align-items: center;
    display: none;
    transform: translateX(100%);
    flex-wrap: wrap;

    &__close {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      z-index: 2;

      #Ellipse_637,
      line {
        transition: all 0.5s ease;
      }

      &:hover {
        line {
          stroke: ${text};
        }

        #Ellipse_637 {
          cy: -20;
        }

        #Group_23147 {
          cy: -10;
        }
      }
    }

    .active a,
    .active span {
      border-bottom: 2px solid ${whites};
      color: #ffffff;
    }

    a {
      font-size: 20px;
      line-height: 28px;
      color: rgba(245, 245, 245, 0.5);
      font-family: ${title};
      text-transform: uppercase;

      &:hover {
        color: rgb(245, 245, 245) !important;
      }
    }

    ul {
      width: 100%;

      li {
        &:not(:nth-last-child(1)) {
          margin-bottom: 20px;
        }

        transform: translateY(20px);
        opacity: 0;
      }
    }

    .accordion-item {
      transform: translateY(20px);
      opacity: 0;

      a {
        border: none;
      }
    }

    .accordion-header {
      font-size: unset;

      button {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        border: none;
        font-size: 20px;
        line-height: 28px;
        color: rgba(245, 245, 245, 0.6);
        font-family: ${title};
        margin-bottom: 20px;
        transition: color 0.6s ease;
        width: 100%;
        text-align: left;
        position: relative;
        color: ${whites};
        text-transform: uppercase;

        img {
          position: absolute;
          right: 0;
          top: 11px;
          transform: rotate(180deg);
        }

        &:hover {
          color: rgb(245, 245, 245) !important;
        }

        &.collapsed {
          color: rgba(245, 245, 245, 0.5);

          img {
            transform: rotate(0deg);
          }
        }
      }
    }

    .accordion-body {
      margin-bottom: 20px;

      ul {
        padding-left: 20px;
      }

      a {
        font-size: 15px;
        line-height: 20px;
        font-family: ${body};
        color: ${whites};
        text-transform: uppercase;

        &:hover {
          color: rgba(245, 245, 245, 0.5) !important;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .goto-buttons {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .menu-overlay {
      height: 100svh;
    }

    .slide-menu {
      height: 100svh;
    }
    .main-menu__logo {
      img {
        width: 85%;
      }
    }

    .main-menu__bar {
      ul li {
        width: 55px;

        &:nth-of-type(2),
        &:nth-of-type(3) {
          display: none;
        }

        &.hamburger {
          padding-left: 19px;
          padding-right: 15px !important;
          width: 60px !important;
        }
      }
    }

    .slide-menu {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      align-items: normal;
      overflow: auto;
      padding-top: 100px;

      .goto-buttons {
        height: fit-content;

        img {
          width: 100%;
          display: block;

          &:nth-of-type(1) {
            margin-bottom: 20px;
          }
        }
      }

      .accordion {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    .slide-menu {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 40px;
      align-items: normal;
      overflow: auto;
      padding-top: 100px;
    }
  }

  //search

  .project-search {
    position: fixed;
    height: ${`100svh`};
    width: 70%;
    z-index: 99999;
    background-color: #08082b;
    right: 0;
    top: 0;
    padding: 100px 0px 0 90px;
    display: none;
    //opacity: 0;
    transform: translateX(100%);

    &__wrap {
      display: flex;
      justify-content: space-between;
    }

    &__left {
      width: 35%;
      position: relative;
      transform: translateY(50px);
      opacity: 0;
      z-index: 99;
      input {
        width: 100%;
        border: 1px solid ${whites};
        border-radius: 100px;
        background: transparent;
        height: 33px;
        padding-left: 24px;
        padding-right: 60px;
        outline: none;
        box-shadow: none;
        color: ${whites};

        &::placeholder {
          color: ${whites};
        }
      }

      button {
        position: absolute;
        right: 15px;
        outline: none;
        box-shadow: none;
        background: transparent;
        border: none;
        top: 3px;
      }

      &__selects {
        margin-top: 40px;
        //transform: translateY(50px);
      }
    }

    &__right {
      width: 50%;
      padding-right: 6px;
      transform: translateY(50px);
      opacity: 0;
      position: relative;
      z-index: 9;
      h3 {
        font-size: 15px;
        line-height: 20px;
        color: ${whites};
        padding-bottom: 15px;
        border-bottom: 1px solid ${whites};
        margin-bottom: 40px;
        width: calc(100% - 90px);

        span {
          min-width: 16px;
          display: inline-block;
        }
      }

      &__list {
        //margin-top: 40px;
        padding-right: 90px;
        padding-bottom: 50px;

        .single-project {
          display: flex;
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;

          &:not(:nth-last-child(1)) {
            border-bottom: 1px solid rgba(245, 245, 245, 0.5);
          }

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }

          img {
            height: 110px;
            width: 70px;
            object-fit: cover;
          }

          &__desc {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h4 {
              font-size: 20px;
              line-height: 28px;
              font-weight: 300;
              color: ${whites};
            }

            p {
              display: flex;
              align-items: center;
              font-size: 15px;
              line-height: 20px;
              font-weight: bold;
              color: #f5f5f5;

              span {
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background-color: ${whites};
                margin: 0 8px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      width: 95%;
      padding-left: 30px;
      .project-search__right {
        h3 {
          width: calc(100% - 30px);
        }

        &__list {
          padding-right: 30px;
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-top: 70px;
      //overflow-y: scroll;
      //overflow: hidden;
      padding-bottom: 180px;
      .close-button {
        top: 15px !important;
      }

      .project-search__wrap {
        flex-wrap: wrap;

        .project-search__left {
          min-width: calc(100% - 30px);
        }

        .project-search__right {
          min-width: 100%;
          margin-top: 50px;
          height: calc(100svh - 400px);
          overflow: scroll;
          position: relative;
          //z-index: -3;
          .single-project__desc {
            margin-left: 20px;

            h4 {
              font-size: 18px;
              line-height: 24px;
            }

            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  //caret

  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(245, 245, 245, 0.5) !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;
    margin-bottom: 20px;

    svg line {
      stroke: ${whites};
    }

    .custom__single-value {
      color: ${whites};
      font-size: 15px;
      line-height: 20px;
    }

    .custom__placeholder {
      color: rgba(245, 245, 245, 1);
      font-size: 15px;
      line-height: 20px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {
    }
  }

  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    border-color: ${whites};
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }
`;

export default MyComponent;
