import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";

const MyComponent = ({img, title, date, category, link}) => {
    return (
        <StyledComponent className={'media-single '}>
            {link.includes('www') || link.includes('https') ? (
                <a href={link} target="_blank" rel="noopener noreferrer"/>
            ) : (
                <Link to={`/media-center/${link}`}/>
            )}
            <div className="media-single__img">
                <Img src={img}/>
            </div>
            <div className="media-single__content">
                <h4>{title}</h4>
                <p><span>{date}</span> <span>{category}</span></p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .media-single__img {
    padding-top: calc(520 / 370 * 100%);
    position: relative;
    overflow: hidden;
  }

  .media-single__content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      color: #F5F5F5;
      font-size: 24px;
      line-height: 32px;
      width: 100%;
    }

    p {
      color: #F5F5F5;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &:hover {
    img {
      transform: scale(1.04);
    }
  }

  @media (max-width: 767px) {
    .media-single__content {
      padding: 20px;

      h4 {
        font-size: 20px;
        line-height: 26px;
      }

    }
  }
`;

export default MyComponent;
