import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'department pt-200 pb-120'}>
            <Container>
                <Title center margin={'0 0 80px 0'} color={whites} text={'Departments'}/>

                <ul>
                    {data?.images?.list?.map(i => (
                        <li key={i?.id}>
                            <img src={i?.full_path} alt=""/>
                            <p>{reactHtmlParser(i?.short_title)}</p>
                        </li>
                    ))}


                </ul>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: ${whites};

    p {
        color: rgb(8, 8, 43);
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 16%;
            text-align: center;
            margin-bottom: 60px;

            img {
                margin-bottom: 20px;
                transition: transform .4s ease;
                transform: scale(1.01);
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
            }


            p {
                width: 95%;
            }

            &:hover {
                img {
                    transform: scale(1.15);
                }
            }
        }
    }

    @media (max-width: 991px) {
        ul li {
            width: 20%;
        }
    }

    @media (max-width: 768px) {
        ul li {
            width: 25%;
        }
    }
    @media (max-width: 500px) {
        ul li {
            width: 33.33%;
        }
    }
    //@media (max-width: 450px) {
    //  ul li {
    //    width: 50%;
    //  }
    //}
`;

export default MyComponent;
