import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import LandSlider from "../../components/landowner/LandSlider";
import Form from "../../components/customer/Form";
import Testimonial from "../../components/Testimonial";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchPosts} from "../../api/redux/landowner";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";
import {title} from "../../styles/globalStyleVars";

const MyComponent = () => {


    const dispatch = useDispatch()
    let getPost = useSelector(state => state.landReducer)

    // api config
    const endPoints = apiEndPoints.SECTIONS
    const params = {
        [ApiParam.type]: 'slug',
        [ApiParam.value]: 'customer-inquiry',
        [ApiParam.get_section]: 'yes',
        [ApiParam.image]: 'yes',
        [ApiParam.post]: 'yes',
    }
    // api call
    useEffect(() => {
        dispatch(fetchPosts([endPoints, params]))
    }, [])

    const innerBannerTitle = getPost?.data?.data?.page_data?.short_desc;
    const innerBannerImage = getPost?.data?.data?.page_images?.list?.[0]?.full_path;
    const Testimonials = getPost?.data?.data?.sections?.find(f => f.section_data?.id == 119);


    const testimonial = getPost?.data?.success_story?.list;
    const pageData = getPost?.data?.data?.page_data;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:title" content={pageData?.og_title}/>
                <meta property="og:description" content={pageData?.og_description}/>
                <meta name="description" content={pageData?.meta_description}/>
                <title>
                    {pageData?.meta_title ? pageData?.meta_title : 'Customer | Sheltech Pvt Ltd.'}
                </title>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={'Customer Enquiry'}
                             img={innerBannerImage}/>
                <Form/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
