import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Autoplay, Navigation, Pagination, EffectFade, Grid} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/grid";

const MyComponent = ({data, id}) => {

    return (
        <StyledComponent id={id} className={'amenities pt-200  pb-200'}>
            <Container>
                <Title center margin={' 0 0 80px 0'} text={'Features & Amenities'}/>
                <div className="amenities__slider">
                    {data && data?.images?.length > 0 &&
                        <Swiper
                            slidesPerView={2}
                            grid={{
                                rows: 2,
                                fill: "row",
                            }}
                            // spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{

                                991: {
                                    slidesPerView: 5,
                                },
                                767: {
                                    slidesPerView: 4,
                                },
                                500: {
                                    slidesPerView: 3,
                                },

                            }}
                            modules={[Grid, Pagination]}
                            className="mySwiper"
                            allowTouchMove={true}
                        >
                            {data?.images?.length > 0 && data?.images?.map(i => (
                                <SwiperSlide key={i?.data?.id}>
                                    <div className="amenities__slider__items">
                                        <div className="amenities__slider__items__inner">
                                            <div className="amenities__slider__items__inner__content">
                                                <img src={i?.full_path} alt=""/>
                                                <p>{i?.short_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .amenities__slider {
        &__items {
            text-align: center;
            border-right: 1px solid rgba(8, 8, 43, 0.2);
            border-top: 1px solid rgba(8, 8, 43, 0.2);
            padding-top: 50px;
            padding-bottom: 50px;
            height: 100%;
            width: 99.9%;
            img {
                height: 75px;
                margin-bottom: 20px;
                transform: scale(1.01);
                filter: invert(45%) sepia(74%) saturate(73%) hue-rotate(226deg) brightness(24%) contrast(90%);
                transition: .4s ease;
            }

            p {
                padding: 0 10px;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    .swiper-slide {
        margin: 0 !important;
    }

    .swiper {
        &:before {
            content: '';
            position: absolute;
            width: 1px;
            left: 0;
            top: 0;
            bottom: 59px;
            //height: 100%;
            background-color: rgba(8, 8, 43, 0.2);
        }

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 59px;
            height: 1px;
            background-color: rgba(8, 8, 43, 0.2);
        }
    }

    .swiper-wrapper {
        padding-bottom: 60px;
    }

    .swiper-pagination-bullet {
        background-color: rgba(8, 8, 43, 0.5);
        opacity: .5;
        height: 5px;
        padding: 0;
        border-radius: 2px;
        width: 20px;
        margin-right: 20px !important;
        @media (max-width: 600px) {
            width: 15px;
            margin-right: 10px !important;
        }

        &.swiper-pagination-bullet-active {
            width: 35px;
            opacity: 1;
            background-color: #08082B;
            @media (max-width: 600px) {
                width: 25px;
            }
        }
    }

    @media (min-width: 1550px) {
        .amenities__slider {
            padding-right: 5px;
        }
    }

    @media (max-width: 600px) {
        .swiper-pagination {
            text-align: left;
        }
    }

    .swiper-slide {
        height: auto;
    }
`;


export default MyComponent;
