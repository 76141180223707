import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import ButtonDownload from "../ButtonDownload";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, id}) => {

    const thumbImage = data?.images?.find(f => f?.thumb === 'on');
    const glanceImage = data?.images?.filter(f => f?.thumb !== 'on');


    return (
        <StyledComponent id={id} className={'at-glance pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col md={5} sm={12}>
                        <div className="at-glance__img">
                            <Img src={thumbImage?.full_path}/>
                        </div>
                    </Col>

                    <Col md={{span: 6, offset: 1}} sm={12} className={'at-glance__text'}>
                        <Title color={'#08082B'} text={'At a Glance'}/>

                        {glanceImage?.map(i => (
                            <div key={i?.id} className="address__single">
                                <h6>
                                    <img src={i?.full_path} alt=""/>
                                </h6>

                                <p>
                                    <span>{i?.short_title}</span>
                                    {reactHtmlParser(i?.short_desc)}
                                </p>

                            </div>
                        ))}


                        {/*<ButtonDownload hoverBackground={'#D6D6D6'} hoverColor={'#212158'} background={'#212158'}*/}
                        {/*                margin={'50px 0 0 0'} color={'#08082B'} src={'#'}*/}
                        {/*                text={'Download Brochure'}/>*/}

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

    background-color: #F5F5F5;

    .at-glance__img {
        //padding-top: calc(740 / 500 * 100%);
        padding-top: calc(700 / 500 * 100%);
        position: relative;
    }

    .address__single {
        display: flex;
        width: 80%;

        img {
            filter: invert(100%) sepia(11%) saturate(414%) hue-rotate(212deg) brightness(108%) contrast(73%);
            height: 30px;
        }

        &:not(:nth-last-child(1)) {
            margin-bottom: 30px;
        }

        svg {
            margin-top: 5px;

            path {
                fill: rgba(8, 8, 43, 0.5);
            }
        }

        h6 {
            min-width: 70px;
            width: 60px;
            margin-top: 6px;
        }


        p, a {
            span {
                color: rgba(8, 8, 43, 0.5);
                display: block;
                width: 100%;
            }

                // color: ${whites};
        }

        a:hover {
            text-decoration: underline;
            color: ${whites} !important;
        }
    }

    @media (min-width: 1600px) {
        .container {
            max-width: 70%;
            min-width: 70%;
        }
    }

    @media (max-width: 767px) {
        .address__single:not(:nth-last-child(1)) {
            width: 100%;
        }

        .at-glance__text {
            margin-top: 40px;
        }

        .address__single h6 {
            min-width: 50px;
            width: 50px;
        }

        .address__map__img {
            height: 60vh;
        }
    }
`;

export default MyComponent;
