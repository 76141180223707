import { createGlobalStyle } from "styled-components";
import { hover, whites } from "./globalStyleVars";

export default createGlobalStyle`
    .dark-mode {
        //home page dark mode
        //.in-home {

        .feature-project {
            background-color: #000000;

            h2 {
                color: ${whites};
            }  
        
            &__left{
                .swiper-slide {
                //background-color: #000000;
                //background-color: #212158;
                // @media (max-width: 767px) {
                    //   background-color: ${hover};
                // }

                p, h4 {
                    color: ${whites};

                    span {
                        background-color: ${whites};
                    }
                }

                }

                .nav{
                    .slider-prev,.slider-next{
                        border:1px solid ${whites};
                        &:before{
                            background-color: ${whites};
                        }
                    }
                    svg.initial line{
                        stroke: ${whites};
                    }
                    svg.hovered line{
                        stroke:#08082B;        
                    }
                }
            } 
        }

        .video-modal {
            background-color: #212158;

            &:after {
                background-color: #212158;
            }
        }

        .excellence {
            background-color: #08082B;

            .excellence__content {
                &__top {
                    border-bottom: 1px solid ${whites};
                }

                .slider-prev, .slider-next {
                    border-color: ${whites} !important;

                    &:before {
                        background-color: ${whites};
                    }

                    line {
                        stroke: rgb(8, 8, 43);
                    }

                    .initial line {
                        stroke: ${whites} !important;;
                    }
                }

                h2, p, span, h4 {
                    color: ${whites};
                }
            }
        }

        .testimonial {
            background-color: #08082B;

            h2 {
                color: ${whites}
            }

            .slider-prev, .slider-next {
                border-color: ${whites} !important;

                &:before {
                    background-color: ${whites};
                }

                line {
                    stroke: rgb(8, 8, 43);
                }

                .initial line {
                    stroke: ${whites} !important;;
                }
            }

            &__slider__single__content {
                h4, p,a {
                    color: ${whites} !important;
                }
            }
        }

        .insights {
            background-color: #08082B;

            .slider-prev, .slider-next {
                border-color: ${whites} !important;

                &:before {
                    background-color: ${whites};
                }

                line {
                    stroke: rgb(8, 8, 43);
                }

                .initial line {
                    stroke: ${whites} !important;;
                }
            }

            .dc-btn a {
                color: ${whites};

                &:hover {
                    color: ${whites} !important;
                }

                span {
                    border-color: ${whites};

                    line {
                        stroke: rgb(8, 8, 43);
                    }

                    .initial line {
                        stroke: ${whites} !important;;
                    }

                    &:before {
                        background-color: ${whites};
                    }
                }
            }

            &:after {
                background-color: #000000;
            }

            h2 {
                color: ${whites};
            }
        }

        //multiple page dark mode

        .text-image, .mission-vision, .make-best, .founder .row, .team-list, .management-team, .associate, .awards, .csr, .media-list, .project-list, .texts-section, .at-glance, .amenities, .floor-plan, .gallery, .BuilHome {
            background-color: #08082B;
            .plain-text{
                color:  ${whites} !important;
            }
            h2, p, h4 {
                color: ${whites} !important;
            }

            .slider-prev, .slider-next {
                border-color: ${whites} !important;

                &:before {
                    background-color: ${whites};
                }

                line {
                    stroke: rgb(8, 8, 43);
                }

                .initial line {
                    stroke: ${whites} !important;;
                }
            }

            .dc-btn a {
                color: ${whites};

                &:hover {
                    color: ${whites} !important;
                }

                span {
                    border-color: ${whites};

                    line {
                        stroke: rgb(8, 8, 43);
                    }

                    .initial line {
                        stroke: ${whites} !important;;
                    }

                    &:before {
                        background-color: ${whites};
                    }
                }
            }
        }

        .founder__text h3 {
            color: ${whites};
        }

        .csr {
            background-color: #000000;
        }

        .awards .container-fluid::after {
            background-color: rgb(8, 8, 43);
        }

        .awards__slider__item p {
            color: rgb(8, 8, 43)
        }

        .sapphire__project:after {
            background-color: rgb(8, 8, 43);
        }

        .media-list {
            &__top ul li {
                color: ${whites};
                border-color: ${whites};

                &:hover, &.active {
                    background-color: ${whites};
                    color: rgb(8, 8, 43);
                }
            }

            .search {
                path {
                    fill: ${whites};
                }

                input {
                    border: 1px solid ${whites};
                    background-color: transparent;

                    &::placeholder {
                        color: ${whites};
                    }
                }
            }
        }

        .founder:after, .founder:before {
            background-color: #08082B;
        }

        .project-list {
            &__dis ul {
                border-color: ${whites} !important;;

                li {
                    color: ${whites};

                    &:not(:nth-last-child(1)) {
                        border-color: ${whites} !important;
                    }

                    &:before {
                        background: ${whites};;
                    }

                    &:hover, &.active {
                        color: rgb(8, 8, 43);
                    }
                }
            }

            //caret

            .custom__control {
                border-bottom: 1px solid ${whites} !important;

                .custom__placeholder, .custom__single-value {
                    color: ${whites};
                }

                line {
                    stroke: ${whites};
                }
            }
        }

        .at-glance {
            .address__single p span, .htKkL .address__single a span {
                color: rgba(245, 245, 245, 0.5);
            }

            svg {
                path {
                    fill: #F5F5F5;
                }
            }

            .dc-btn {
                span {
                    background-color: transparent;
                }

                svg:nth-of-type(2) {
                    path {
                        fill: rgb(8, 8, 43);
                    }
                }
            }
        }

        .land-single .at-glance {
            background-color: rgb(33, 33, 88);

            .address__single img {
                filter: invert(45%) sepia(40%) saturate(3%) hue-rotate(226deg) brightness(208%) contrast(4017%)
            }
        }

        .amenities {
            .amenities__slider__items {
                border-color: ${whites};

            }

            .swiper::before, .swiper::after {
                background-color: ${whites};
            }

            .amenities__slider__items__inner__content img {
                //filter: invert(98%) sepia(13%) saturate(57%) hue-rotate(201deg) brightness(116%) contrast(100%);
                filter: invert(45%) sepia(74%) saturate(73%) hue-rotate(226deg) brightness(108%) contrast(417%)
            }
        }

        .project-single {
            .location-map {
                background-color: #000000;

                h2 {
                    color: ${whites};
                }
            }

            .video-modal {
                background-color: #08082B;


                &::after {
                    background-color: #000000;
                }
            }
        }

        .excellence__img {
            h2{
                color: #FFF;
            }
            img {
                //filter: invert(99%) sepia(96%) saturate(21%) hue-rotate(290deg) brightness(106%) contrast(100%);
                filter: none;
            }
        }

        .address__single img {
            filter: none;
        }

        //}
        // dark mode close 

        .BuilHome {
            .filter__control .filter__placeholder {
                color: ${whites};
            }

            .filter__control {
                border-bottom: 1px solid ${whites} !important;
            }

            .filter__indicator svg line {
                stroke: ${whites} !important;
            }
        }

        .department {
            background-color: #000;

            p, h4, h2 {
                color: ${whites};
            }

            img {
                filter: none;
            }
        }

        .address {
            background-color: #08082B;

            h2, h4, p, span, a {
                color: ${whites};
            }

            svg path {
                fill: ${whites};
            }
        }

        .user {
            background-color: #08082B;

            h2, h4, p, .form-control {
                color: ${whites};
            }

            .form-control {
                border-color: ${whites};

                &::placeholder {
                    font-size: 15px;
                    color: ${whites};
                }
            }

            button {
                color: ${whites};

                span {
                    border-color: ${whites};

                    svg line {
                        stroke: ${whites} !important;
                    }
                }

                &:hover {
                    color: ${whites} !important;

                    span:before {
                        background-color: ${whites};
                    }

                    svg line {
                        stroke: #08082B !important;
                    }
                }
            }
            input:-webkit-autofill {
                
                &:hover {
                    border-bottom: 1px solid #FFF !important;
                }

                &:focus {
                    border-bottom: 1px solid #FFF !important;
                }
            }
        }


        .filter__control.filter__control--is-focused {
            border-color: ${whites} !important;

            .filter__indicator {
                svg {
                    line {
                        stroke: ${whites} !important;;
                    }
                }
            }
        }

        .filter__control {
            color: ${whites};

        }

        .filter__single-value {
            color: ${whites} !important;
        }

        .filter__control.filter__control--is-focused {
            border-bottom-color: ${whites} !important;

            .filter__placeholder {
                //color: #08082B;
                color: ${whites};
            }

            .filter__single-value {
                //color: #08082B
                color: ${whites};
            }
        }

        .filter__control {
            border-bottom-color: ${whites} !important;

            .filter__placeholder {
                color: ${whites};
            }
        }

        .filter__indicator svg line {
            stroke: ${whites} !important;
        }

        .user-alter a:hover {
            color: ${whites} !important;
        }

        .land-form {
            background-color: #212158;

            &__input {
                h4 {
                    color: ${whites};
                }
            }

            .form-control {
                border-bottom: 1px solid #F5F5F5;
                color: #F5F5F5;

                &::placeholder {
                    color: ${whites};
                }
            }

            .custom__control {
                border-bottom: 1px solid ${whites} !important;

                .custom__placeholder, .custom__single-value {
                    color: ${whites};
                }
            }

            .custom__indicator {
                img {
                    filter: none
                }
            }

            .dc-btn {
                button {
                    color: #ffffff;

                    span {
                        color: #ffffff;
                        border: 1px solid #FFF;

                        &:before {
                            background-color: #fff;
                        }
                    }

                    svg.initial {
                        line {
                            stroke: #ffffff;;
                        }
                    }

                    svg.hovered line {
                        stroke: #212158;
                    }
                }

                &:hover {
                    button {
                        color: #ffffff !important;
                    }
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                color: #FFF !important;
                -webkit-text-fill-color: #FFF !important;
                border: none;
                border-bottom: 1px solid #FFF;
                -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
                -webkit-background-clip: text !important;
                background-clip: text !important;

            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            color: #FFF !important;
            //border: 1px solid rgba(0, 0, 0, 0);
            -webkit-text-fill-color: #FFF !important;
            -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        .management-team {
            background-color: #08082B;

            .team-single {

                &__content {
                    border-color: rgba(245, 245, 245, 0.5);

                    h4 {
                        color: ${whites};
                    }

                    p {
                        color: rgba(245, 245, 245, 0.5);
                    }
                }

                @media (max-width: 600px) {
                    margin-bottom: 40px;
                }
            }
        }

        .land-form__title {
            h3 {
                color: ${whites};
            }

            a {
                background-color: ${whites};

                span {
                    color: rgba(33, 33, 88, 0.5);
                }

                p {
                    color: rgb(33, 33, 88);
                }

                svg path {
                    fill: rgb(33, 33, 88);
                }
            }
        }
        .clear-icon{
            color: #FFF !important;
        }
    }


    body:not(.dark-mode) {
        .no-favourites {
            background-color: #FFF;

            p, h2, a {
                color: rgb(8, 8, 43) !important;
            }

            a:hover {
                color: rgb(8, 8, 43) !important;

                span:before {
                    background-color: rgb(8, 8, 43);
                }

                .hovered line {
                    stroke: #FFF !important;
                }
            }

            .dc-btn span {
                border: 1px solid rgb(8, 8, 43);

                line {
                    stroke: rgb(8, 8, 43);
                }
            }

            img {
                filter: invert(77%) sepia(22%) saturate(40%) hue-rotate(23deg) brightness(115%) contrast(60%)
            }
        }

        .department .title h2 {
            color: rgb(8, 8, 43);
        }

        .detail-page {
            background-color: #fff;

            p, h2, h1, h3, a {
                color: #08082b;
            }

            .full-detail h3 {
                border-bottom: 1px solid #08082b;
            }   
        }
        
        

    }

`;
