import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import styled from "styled-components";
import { hover, title } from "../../styles/globalStyleVars";
import FeatureProject from "../../components/home/FeatureProject";
import Video from "../../components/home/Video";
import Sapphire from "../../components/home/Sapphire";
import Excellence from "../../components/home/Excellence";
import Testimonial from "../../components/Testimonial";
import Insigths from "../../components/home/Insigths";
import { ApiParam } from "../../api/network/apiParams";
import { fetchData } from "../../api/redux/home";
import GoogleMap from "../../components/home/GoogleMap";
import BannerSlider from "../../components/home/BannerSlider";
import { HelmetProvider, Helmet } from "react-helmet-async";


const Home = () => {
  const dispatch = useDispatch();
  let getPost = useSelector((state) => state.homeReducer);

  // api config
  const endPoints = apiEndPoints.SECTIONS;
  const params = {
    [ApiParam.type]: "slug",
    [ApiParam.value]: "home",
    [ApiParam.get_section]: "yes",
    [ApiParam.image]: "yes",
    [ApiParam.post]: "yes",
  };
  // api call
  useEffect(() => {
    dispatch(fetchData([endPoints, params]));
  }, []);

  // home data refactor
  const featureProject = getPost?.data?.featured_products?.list;
  const PromotionalPopup = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 125
  );
  const banner = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 47
  );
  const Videos = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 2
  );
  const SapphireData = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 56
  );
  const Heritage = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 3
  );
  const Testimonials = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 102
  );
  const mapSection = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 141
  );
  const featureProjectSection = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 101
  );
  const mediaSection = getPost?.data?.data?.sections?.find(
      (f) => f.section_data?.id == 142
  );
  const TestimonialsList = getPost?.data?.success_story?.list;
  const mediaCenter = getPost?.data?.media_center;
  const dynamicMap = getPost?.data?.products_map_list?.list;

  const pageData = getPost?.data?.data?.page_data;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta property="og:title" content={pageData?.og_title}/>
        <meta property="og:description"
              content={pageData?.og_description}/>
        <meta name="description"
              content={pageData?.meta_description}/>
        <title>
          {pageData?.meta_title? pageData?.meta_title : "Best Real Estate Developer in Bangladesh | Sheltech"}
        </title>
      </Helmet>
      <StyledSection className={"in-home"}>
        {
          banner?.section_data?.status === 1 ?
              <BannerSlider data={banner}/>:
              ''
        }
        {
          featureProjectSection?.section_data?.status === 1 ?
              <FeatureProject data={featureProject}/>:
              ''
        }
        {
          Videos?.section_data?.status === 1 ?
              <Video data={Videos} />:
              ''
        }
        {
          SapphireData?.section_data?.status === 1 ?
              <Sapphire list={getPost?.data?.sapphire_series} data={SapphireData} />:
              ''
        }
        {
          mapSection?.section_data?.status === 1 ?
              <GoogleMap data={dynamicMap} />:
              ''
        }
        {
          Heritage?.section_data?.status === 1 ?
              <Excellence data={Heritage} />:
              ''
        }
        {
          Testimonials?.section_data?.status === 1 ?
              <Testimonial testimonial={TestimonialsList} data={Testimonials} />:
              ''
        }
        {
          mediaSection?.section_data?.status === 1 ?
              <Insigths data={mediaCenter} sectionData={mediaSection} />:
              ''
        }

      </StyledSection>
    </HelmetProvider>
  );
};

const StyledSection = styled.section`
  overflow: hidden;

  .video-modal {
    background-color: rgb(33, 33, 88);

    &:after {
      background-color: rgb(33, 33, 88);
    }
  }
  
`;

export default Home;
