import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, title, whites} from "../../styles/globalStyleVars";
import Title from "../Title";
import Select, {components} from "react-select";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {PostForm} from "../../api/redux/landowner";

const MyComponent = () => {
    const store = useSelector(store => store)

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#FFF` : '#221F1F',
            backgroundColor: state.isSelected ? `#08082b` : '#FFF',
            margin: 0,
            cursor: 'pointer',
            ":hover": {
                backgroundColor: "#08082B",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    const facing = [
        {value: 'north', label: 'North '},
        {value: 'south', label: 'South '},
        {value: 'east', label: 'East '},
        {value: 'west', label: 'West '},

    ];

    const optionsOne = [
        {value: 'Freehold', label: 'Freehold '},
        {value: 'Leasehold', label: 'Leasehold '},
    ];

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ? <img src={'/images/static/caret-white-v2.svg'} alt=""/> :
                        <img src={'/images/static/caret-white.svg'} alt=""/>}
                </components.DropdownIndicator>
            )
        );
    };

    const [Facing, setFacing] = useState('')
    const [landCat, setlandCat] = useState('')
    // const [Facing,setFacing] = useState('')
    const handleFacing = (e) => {
        setFacing(e)
    }
    const handleCat = (e) => {
        setlandCat(e)
    }


    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.POST_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'landowner-form');
        formData.append('locality', e?.locality);
        formData.append('size_of_land', e?.size);
        formData.append('Width_of_the_road_in_front', e?.road);
        formData.append('facing', Facing);
        formData.append('category_of_land', landCat);
        formData.append('attractive_feature', e?.feature);
        formData.append('contact_person', e?.contactPerson);
        formData.append('address', e?.address);
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.number);

        dispatch(PostForm([apiEndPoint, formData]))
    }

    useEffect(() => {

        if (!store?.landReducer?.formLoading && isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
        }
    }, [isSubmitSuccessful, store])


    return (
        <StyledComponent className={'land-form pt-150 pb-150'}>
            <Container>
                <Row className={'land-form__title'}>
                    <Col md={12} xl={6}>
                        <h3>Start Your Joint Venture Today</h3>
                    </Col>

                    <Col md={12} xl={6}>

                        <a href="tel:09612 5555 66 ">
                            <svg id="Group_23649" data-name="Group 23649" xmlns="http://www.w3.org/2000/svg"
                                 width="26.944" height="28.91" viewBox="0 0 26.944 28.91">
                                <path id="Path_10757" data-name="Path 10757"
                                      d="M24.8,20.122c-1.746-1.494-3.519-2.4-5.244-.907l-1.03.9c-.754.654-2.155,3.712-7.573-2.52S8.758,10.4,9.513,9.753l1.036-.9c1.716-1.495,1.068-3.377-.169-5.314L9.633,2.364C8.389.431,7.035-.838,5.315.654l-.93.812a8.727,8.727,0,0,0-3.4,5.775c-.621,4.1,1.337,8.8,5.822,13.962s8.864,7.751,13.018,7.706a8.75,8.75,0,0,0,6.189-2.563l.933-.813c1.716-1.491.65-3.01-1.1-4.507Z"
                                      transform="translate(-0.872 0)" fill="#212158"/>
                            </svg>
                            <p><span>Talk to our Land Experts </span> 09612 5555 66 </p>
                        </a>
                    </Col>

                </Row>
                <Form>
                    <Row className={'land-form__input'}>

                        <Col md={6} sm={12}>
                            <h4>Land Information</h4>

                            <Form.Group className={'from-group'}>
                                <Form.Control  {...register('locality', {
                                    required: {
                                        value: true,
                                        message: "Please enter Locality",
                                    }
                                })} type="text" placeholder="Locality*"/>
                                <p className={'form-error'}>{errors.locality?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('address', {
                                    required: {
                                        value: true,
                                        message: "Please enter Address",
                                    }
                                })} type="text" placeholder="Address*"/>
                                <p className={'form-error'}>{errors.address?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('size')} type="text" placeholder="Land Size"/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('road')} type="text"
                                              placeholder="Front Road Width"/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Select onChange={(e) => handleCat(e.value)} components={{DropdownIndicator}}
                                        styles={customStyles}
                                        classNamePrefix={'custom'} className='select-here files'
                                        placeholder={'Land Category'}
                                        options={optionsOne} isSearchable={false}/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Select onChange={(e) => handleFacing(e.value)} components={{DropdownIndicator}}
                                        styles={customStyles}
                                        classNamePrefix={'custom'} className='select-here files'
                                        placeholder={'Facing'}
                                        options={facing} isSearchable={false}/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('feature')} type="text"
                                              placeholder="Attractive Benefits (if any)"/>
                            </Form.Group>

                        </Col>

                        <Col md={6} sm={12}>
                            <h4>Landowner's Profile</h4>

                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }
                                })} type="text" placeholder="Landowner Name*"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('contactPerson')} type="text" placeholder="Contact Person"/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('email', {
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register("number", {
                                    required: {
                                        value: true,
                                        message: "Enter your valid phone number",
                                    },
                                    pattern: {
                                        value: /^01[0-9]{9}$/,
                                        message: "Enter a valid 11 digit phone number",
                                    },
                                })} type="number" placeholder="Phone*"/>
                                <p className={'form-error'}>{errors.number?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('message')} type="text" placeholder="Message"/>
                                <p className={'form-error'}>{errors.message?.message}</p>
                            </Form.Group>
                            <div onClick={handleSubmit(handleFormSubmit)}>
                                <Button color={'#212158'} borderColor={"#212158"} hoverColor={"#FFF"}
                                        hoverBackground={"#212158"}
                                        margin={'35px 0 0px 0'} submitButton
                                        text={'Submit'}/>
                            </div>

                        </Col>
                    </Row>
                </Form>
            </Container>
        </StyledComponent>
    )
        ;
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;

  .land-form__title {
    h3 {
      font-size: 36px;
      line-height: 44px;
      font-family: ${title};
      text-transform: uppercase;
      color: #212158;
    }

    a {
      height: 84px;
      padding: 20px 80px 20px 40px;
      background-color: #212158;
      border-radius: 40px;
      display: flex;
      width: fit-content;
      font-size: 24px;
      line-height: 32px;
      align-items: center;
      transition: .3s ease;

      svg path {
        fill: ${whites}
      }

      span {
        font-size: 15px;
        line-height: 20px;
        display: block;

        color: ${whites};
      }

      p {
        margin-left: 15px;
        color: ${whites};
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  .land-form__input {
    margin-top: 60px;

    .col-md-6 {
      padding-right: 60px;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
      color: #212158;
      margin-bottom: 40px;
    }

    form {
      width: 100%;
    }

    .from-group {
      margin-bottom: 25px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #212158;
      padding-left: 0;
      color: #212158;
      height: 45px;
      //margin-bottom: 25px;

      &::placeholder {
        font-size: 15px;
        color: #212158;
      }
    }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
          color: #000 !important;
          -webkit-text-fill-color: #000 !important;
          border: none;
          border-bottom: 1px solid #000;
          -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
          -webkit-background-clip: text !important;
          background-clip: text !important;

      }
  }

  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #212158 !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;

    .custom__placeholder, .custom__single-value {
      color: #212158;
      font-size: 15px;
      line-height: 20px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }

  .css-t3ipsp-control:hover {
    border-color: #212158;
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;

    img {
      filter: invert(110%) sepia(11%) saturate(7489%) hue-rotate(217deg) brightness(105%) contrast(100%);
    }
  }

  @media (max-width: 1199px) {
    .land-form__title {
      a {
        margin-top: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    .land-form__title h3 {
      font-size: 30px;
      line-height: 36px;
    }

    .land-form__input {
      .col-md-6 {
        padding-right: 15px;

        &:nth-of-type(1) {
          margin-bottom: 50px;
        }
      }
    }
  }

`;

export default MyComponent;
