import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import mediaReducer from '../redux/media'
import careerReducer from '../redux/career'
import contactReducer from '../redux/contact'
import privacyReducer from '../redux/privacy'
import landReducer from '../redux/landowner'
import projectReducer from '../redux/projects'
import managementReducer from '../redux/management'
import userReducer from '../redux/user'

const store = configureStore({
    reducer: {
        userReducer,
        postReducer,
        globalReducer,
        homeReducer,
        aboutReducer,
        mediaReducer,
        careerReducer,
        contactReducer,
        landReducer,
        projectReducer,
        managementReducer,privacyReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

