import React, {useEffect} from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {whites, title, text} from "../../styles/globalStyleVars";
import {Link, useHistory} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {addToFav} from "../../api/redux/user";

const InnerBanner = ({img, title, subTitle, address, id, favourite, posts}) => {


    // useEffect(() => {
    //     ScrollTrigger.create({
    //         trigger: ".detail-banner__menu",
    //         pin: true,
    //         start: "top 65px",
    //         end: document.body.offsetHeight
    //     });
    // }, [])
    const history = useHistory()
    const dispatch = useDispatch()
    const store = useSelector(store => store.userReducer)
    const handleFav = (id, status) => {
        if (localStorage.getItem('sheltechuser')) {
            const endPoints = apiEndPoints.FAVOURITE;
            const formData = new FormData()
            formData.append("id", localStorage.getItem('sheltechuserid'))
            formData.append("project_id", id)
            formData.append("status", status)
            formData.append("token", localStorage.getItem('sheltechuser'))

            dispatch(addToFav([endPoints, formData]))
        } else {
            history.push('/user/sign-in')
        }
    }


    return (
        <StyledInnerBanner className='detail-banner'>
            <Img banner={true} src={img}/>
            <Container>
                <Row>
                    {/*<Col md={{span: 10, offset: 1}} sm={12}>*/}
                    {/*    <Link to={`/projects?type=${subTitle && subTitle.toLowerCase()}`}>{subTitle}</Link>*/}
                    {/*    <h2 className={`anim-active fade-up`}>{reactHtmlParser(title)}</h2>*/}
                    {/*    <p>{address}</p>*/}

                    {/*</Col>*/}
                    <Col md={{span: 10, offset: 1}} sm={12}>
                        <div className="type-wrap">
                            {subTitle?.map((item, index) => (
                                <p className={'types'}
                                   key={index}
                                >
                                    {item?.title}
                                    {index < subTitle.length - 1 && <span />}
                                </p>
                            ))}
                        </div>

                        <h2 className="anim-active fade-up">{reactHtmlParser(title)}</h2>
                        <p>{address}</p>
                    </Col>
                </Row>

                {favourite == 0 ?
                    <h6 onClick={() => handleFav(id, 1)}>
                        <svg width="28" height="28"
                             viewBox="0 0 28 28">
                            <defs>
                                <clipPath id="clip-path">
                                    <circle id="Mask" cx="14" cy="14" r="14"
                                            transform="translate(1092 18)" fill="none" stroke="#f5f5f5"
                                            stroke-width="1"/>
                                </clipPath>
                            </defs>
                            <g id="Group_23337" data-name="Group 23337"
                               transform="translate(-920 -1066)">
                                <g id="Button_-_Favorite" data-name="Button - Favorite"
                                   transform="translate(920 1066)">
                                    <g id="Mask_Group_19911" data-name="Mask Group 19911"
                                       transform="translate(-1092 -18)" clip-path="url(#clip-path)">
                                        <g id="Base" transform="translate(1092 18)" fill="none"
                                           stroke="#f5f5f5" stroke-width="1">
                                            <circle cx="14" cy="14" r="14" stroke="none"/>
                                            <circle cx="14" cy="14" r="13.5" fill="none"/>
                                        </g>
                                        <circle id="Hover" cx="14" cy="14" r="14"
                                                transform="translate(1092 46)" fill="#f5f5f5"/>
                                        <path id="Path_1920" data-name="Path 1920"
                                              d="M10.542,0C8.961,0,7.655,1.47,7.126,2.16,6.6,1.47,5.292,0,3.711,0A3.98,3.98,0,0,0,0,4.2,4.383,4.383,0,0,0,1.378,7.438a.328.328,0,0,0,.047.061L6.917,12.98a.293.293,0,0,0,.209.086.288.288,0,0,0,.209-.086l5.676-5.673.058-.056c.047-.045.092-.089.145-.145a.307.307,0,0,0,.053-.07A4.475,4.475,0,0,0,14.253,4.2,3.981,3.981,0,0,0,10.542,0Zm2.23,6.7a.124.124,0,0,0-.022.031c-.028.031-.058.061-.089.089L7.126,12.348,1.912,7.143a.267.267,0,0,0-.07-.089A3.78,3.78,0,0,1,.593,4.192,3.388,3.388,0,0,1,3.711.593c1.7,0,3.151,2.216,3.165,2.238a.307.307,0,0,0,.5,0C7.388,2.809,8.838.593,10.539.593a3.388,3.388,0,0,1,3.118,3.6A3.855,3.855,0,0,1,12.772,6.7Z"
                                              transform="translate(1098.874 25.467)" fill="#f5f5f5"
                                              stroke="#f5f5f5" stroke-width="0.5"/>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        Add to Favourites

                    </h6> :
                    <h6 onClick={() => handleFav(id, 0)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28"
                             height="28" viewBox="0 0 28 28">
                            <defs>
                                <clipPath id="clip-path">
                                    <circle id="Mask" cx="14" cy="14" r="14" transform="translate(1092 18)" fill="none"
                                            stroke="#f5f5f5" stroke-width="1"/>
                                </clipPath>
                            </defs>
                            <g id="Group_24046" data-name="Group 24046" transform="translate(17665 1456)">
                                <g id="Button_-_Favorite" data-name="Button - Favorite"
                                   transform="translate(-17665 -1456)">
                                    <g id="Mask_Group_19911" data-name="Mask Group 19911"
                                       transform="translate(-1092 -18)"
                                       clip-path="url(#clip-path)">
                                        <g id="Base" transform="translate(1092 18)" fill="none" stroke="#f5f5f5"
                                           stroke-width="1">
                                            <circle cx="14" cy="14" r="14" stroke="none"/>
                                            <circle cx="14" cy="14" r="13.5" fill="none"/>
                                        </g>
                                        <circle id="Hover" cx="14" cy="14" r="14" transform="translate(1092 18)"
                                                fill="#f5f5f5"/>
                                        <path id="Path_1920" data-name="Path 1920"
                                              d="M10.542,0C8.961,0,7.655,1.47,7.126,2.16,6.6,1.47,5.292,0,3.711,0A3.98,3.98,0,0,0,0,4.2,4.383,4.383,0,0,0,1.378,7.438a.328.328,0,0,0,.047.061L6.917,12.98a.293.293,0,0,0,.209.086.288.288,0,0,0,.209-.086l5.676-5.673.058-.056c.047-.045.092-.089.145-.145a.307.307,0,0,0,.053-.07A4.475,4.475,0,0,0,14.253,4.2,3.981,3.981,0,0,0,10.542,0Zm2.23,6.7a.124.124,0,0,0-.022.031c-.028.031-.058.061-.089.089L7.126,12.348,1.912,7.143a.267.267,0,0,0-.07-.089A3.78,3.78,0,0,1,.593,4.192,3.388,3.388,0,0,1,3.711.593c1.7,0,3.151,2.216,3.165,2.238a.307.307,0,0,0,.5,0C7.388,2.809,8.838.593,10.539.593a3.388,3.388,0,0,1,3.118,3.6A3.855,3.855,0,0,1,12.772,6.7Z"
                                              transform="translate(1098.874 25.467)" fill="#212158" stroke="#212158"
                                              stroke-width="0.5"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        Remove from Favorites
                    </h6>

                }

            </Container>

            <div className="detail-banner__menu">
                <ul>
                    {posts?.map(i => (
                        <li key={i?.data?.id}><Link to={`#${i?.data?.slug}`}>{i?.data?.title}</Link></li>
                    ))}


                    {/*<li><Link to={'#overview'}>Overview</Link></li>*/}
                    {/*<li><Link to={"#timeline"}>At a Glance</Link></li>*/}
                    {/*<li><Link to={"#progress"}>Progression</Link></li>*/}
                    {/*<li><Link to={"#amenities"}>Features & Amenities</Link></li>*/}
                    {/*<li><Link to={"#floor-plan"}>Floor Plans</Link></li>*/}
                    {/*<li><Link to={"#gallery"}>Gallery</Link></li>*/}
                    {/*/!*<li><Link to={"#video"}>Video</Link></li>*!/*/}
                    {/*<li><Link to={"#map"}>Map</Link></li>*/}
                    {/*<li><Link to={"#map"}>contact</Link></li>*/}
                </ul>
            </div>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
    //padding-top: calc(600 / 1366 * 100%);
    position: relative;
    background-color: #DDD;
    height: 100vh;

    .container {
        position: absolute;
        height: 100%;
        inset: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        p, a {
            font-size: 30px;
        }
    }

    .row {
        width: 100%;
    }

    .type-wrap{
        display: flex;
        justify-content: center;
    }
    
    .types{
        font-size: 30px;
        color: white;
        span {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background-color: white;
            margin: 0 8px;
            display: inline-block;
        }
        
        &:hover{
            color: white !important;
        }
    }

    //&:after {
    //  content: '';
    //  position: absolute;
    //  background-color: rgba(0, 0, 0, 0.25);
    //  height: 100%;
    //  width: 100%;
    //  left: 0;
    //  top: 0;
    //}
    //

    p, a {
        color: ${whites};
        z-index: 3;
        position: relative;
        text-transform: uppercase;

    }

    h2 {
        //position: absolute;
        left: 0px;
        //bottom: 94px;
        color: #ffffff;
        font-size: 44px;
        font-weight: 300;
        line-height: 52px;
        //text-transform: capitalize;
        z-index: 3;
        position: relative;
        text-transform: uppercase;
        font-family: ${title};
        margin-top: 15px;
        right: 0;
        margin-bottom: 15px;
    }

    h6 {
        position: absolute;
        bottom: 125px;
        z-index: 4;
        cursor: pointer;
        left: 0;
        right: 0;
        margin: auto;
        top: auto;
        color: ${whites};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-right: 10px;

            circle, path {
                transition: .6s ease;
            }
        }

        &:hover {
            svg {
                #Hover {
                    cy: -13px;
                }

                #Path_1920 {
                    stroke: ${text};
                }
            }
        }
    }

    .detail-banner__menu {
        position: absolute;
        height: 57px;
        bottom: 0;
        left: 0;
        background-color: #212158;
        z-index: 99;
        width: 100%;
        @media (max-width: 991px) {
            display: none;
        }

        ul {
            display: flex;
            height: 100%;
            justify-content: center;
            text-align: center;

            li {
                a {
                    color: rgba(245, 245, 245, 0.5);
                    display: flex;
                    height: 100%;
                    align-items: center;

                    &:hover {
                        color: rgb(245, 245, 245) !important;
                    }
                }

                &:not(:nth-last-child(1)) {
                    margin-right: 40px;
                }
            }
        }

    }

    @media (max-width: 991px) {
        h6 {
            bottom: 60px;
        }
    }

    @media (max-width: 767px) {
        //padding-top: 0;
        padding-top: calc(600 / 375 * 100%);

        .container {
            a, p {
                font-size: 20px;
            }
        }

        h2 {
            font-size: 32px;
            line-height: 44px;
        }

        h6 {
            bottom: 30px;
        }
    }
`;

export default InnerBanner;
