import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import {whites} from "../../styles/globalStyleVars";
import SliderNextWhite from "../SliderNextWhite";
import SliderPrevWhite from "../SliderPrevWhite";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import ButtonDownload from "../ButtonDownload";
import CloseButton from "../CloseButton";
import SimpleBar from "simplebar-react";
import Title from "../Title";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import {useHistory} from 'react-router-dom';


const MyComponent = ({data, id, file}) => {
    const store = useSelector(store => store.contactReducer)
    const [show, setShow] = useState(false);
    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.POST_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'download-borchure');
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.number);
        dispatch(postForm([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (!store?.formLoading && isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
            handleClose()
            window.open(file?.full_path, '_blank');
        }
    }, [isSubmitSuccessful, store])


    return (
        <StyledComponent id={id} className={'floor-plan'}>
            <Container fluid className={'p-0'}>
                <Row>
                    <Col sm={12} md={6} className={'p-0'}>
                        {data && data?.images?.length > 0 &&

                            <Swiper loop={true}
                                    slidesPerView={1}
                                    allowSlideNext={true}
                                    allowSlidePrev={true}
                                    allowTouchMove={false}
                                    grabCursor={false}
                                    speed={500}

                                // pagination={{
                                //     type: "fraction",
                                // }}
                                    navigation={{
                                        prevEl: '.floor-plan .slider_prev',
                                        nextEl: '.floor-plan .slider_next',
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}

                                // autoplay={false}

                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                            >
                                {data && data?.images?.length > 0 && data?.images?.map(i => (
                                    <SwiperSlide key={i?.id}>
                                        <div className="floor-plan__slider">
                                            <div className="floor-plan__slider__img">
                                                <Img src={i?.full_path}/>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        }
                    </Col>

                    <Col sm={12} md={6} className={'p-0'}>
                        <div className={'floor-plan__text'}>
                            <div style={{width: 'fit-content'}} onClick={handleShow}>
                                <ButtonDownload hoverBackground={'#D6D6D6'} hoverColor={'#212158'}
                                                background={'#212158'}
                                                margin={'50px 0 0 0'} color={'#FFF'} src={'#'}
                                                text={'Download Brochure'}/>
                                <a className={'click-for'} href="/file/simple.pdf"
                                   style={{opacity: 0, visibility: 'hidden', position: 'absolute'}}
                                   target={'_blank'}/>
                            </div>


                            <div className="floor-plan__text__inner">
                                {data && data?.images?.length > 0 &&
                                    <Swiper loop={true}
                                        // spaceBetween={30}
                                            slidesPerView={1}
                                            allowSlideNext={true}
                                            allowSlidePrev={true}
                                            allowTouchMove={false}
                                            speed={500}
                                            effect={'fade'}
                                        // pagination={{
                                        //     type: "fraction",
                                        // }}
                                            navigation={{
                                                prevEl: '.floor-plan .slider_prev',
                                                nextEl: '.floor-plan .slider_next',
                                            }}
                                            modules={[Autoplay, Pagination, Navigation, EffectFade]}

                                        // autoplay={false}

                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                    >
                                        {data && data?.images?.length > 0 && data?.images?.map(i => (
                                            <SwiperSlide key={i?.id}>
                                                <h4>{i?.short_title}</h4>
                                            </SwiperSlide>
                                        ))}

                                    </Swiper>
                                }
                                <ul>
                                    <li className={'slider_prev'}><SliderPrevWhite borderColor={'#F5F5F5'}/></li>
                                    <li className={'slider_next'}><SliderNextWhite borderColor={'#F5F5F5'}/></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


            {/*download modal*/}
            <Modal className={'team-modal subscribe-modal'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>
                <div className="team-modal__content">
                    <Title margin={'0 0 50px 0'} color={whites} text={'DOWNLOAD BROCHURE'}/>

                    <Form>
                        <Form.Group className={'from-group'}>
                            <Form.Control {...register('name', {
                                required: {
                                    value: true,
                                    message: "Please enter your full name",
                                }

                            })} type="text" placeholder="Name*"/>
                            <p className={'form-error'}>{errors.name?.message}</p>
                        </Form.Group>
                        <Form.Group className={'from-group'}>
                            <Form.Control {...register('email', {
                                required: {
                                    value: true,
                                    message: "Please enter a valid email address"
                                },
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Please enter a valid email address"
                                }
                            })} type="email" placeholder="Email*"/>
                            <p className={'form-error'}>{errors.email?.message}</p>
                        </Form.Group>
                        <Form.Group className={'from-group'}>
                            <Form.Control {...register('number', {
                                required: {
                                    value: true,
                                    message: "Please enter a your phone number"
                                },
                                pattern: {
                                    value: /^01[0-9]{9}$/,
                                    message: "Enter a valid 11 digit phone number",
                                },
                            })} type="number" placeholder="Phone*"/>
                            <p className={'form-error'}>{errors.number?.message}</p>
                        </Form.Group>
                        <div onClick={handleSubmit(handleFormSubmit)}>
                            <Button margin={'40px 0px 0 0 '} text={'Submit'}/>
                        </div>
                    </Form>
                </div>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    overflow: hidden;
    background-color: #D6D6D6;

    .floor-plan__slider {
        &__img {
            padding-top: calc(588 / 698 * 100%);
            position: relative;
        }
    }

    .floor-plan__text {
        height: 65%;
        background-color: #212158;
        padding: 60px 90px 60px 60px;
        position: relative;

        .dc-btn {
            position: absolute;
            top: 100px;
            @media (max-width: 1024px) {
                top: 75px;
            }
        }

        &__inner {
            h4 {
                font-size: 35px;
                line-height: 52px;
                color: ${whites};
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            ul {
                display: flex;
                position: absolute;
                bottom: 60px;
            }
        }
    }

    .swiper-slide {
        background-color: rgb(33, 33, 88);
    }


    @media (max-width: 990px) {
        .floor-plan__text {
            height: 95%;
            padding: 50px 30px;

            h4 {
                font-size: 32px;
                height: 44px;
            }

            &__inner ul {
                //position: initial;
                //margin-top: 40px;
            }
        }
    }

    @media (max-width: 767px) {
        .floor-plan__text__inner ul {
            position: initial;
            margin-top: 100px;
        }
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: ${whites} !important;
        border-bottom: 1px solid #F5F5F5;

        &:hover {
            border-bottom: 1px solid #F5F5F5 !important;
        }

        &:focus {
            border-bottom: 1px solid #F5F5F5 !important;
        }
    }
`;

export default MyComponent;
