import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useHistory, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Title from "../../components/Title";
import {whites} from "../../styles/globalStyleVars";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../../components/Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {signIn, cleanData} from "../../api/redux/user";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";


const MyComponent = () => {


    const location = useLocation()
    const store = useSelector(store => store.userReducer)
    const history = useHistory()

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.SIGN_IN

    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'visitor-login');
        formData.append('VisitorLoginForm[username]', e?.email);
        formData.append('VisitorLoginForm[password]', e?.password);
        dispatch(signIn([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (!store?.formLoading && store?.data?.status !== 0 && isSubmitSuccessful) {
            reset()
            setTimeout(() => {
                dispatch(cleanData())
                history.push('/projects')
            }, 300)
            if (store?.data?.token) {
                localStorage.setItem('sheltechuser', store?.data?.token)
                localStorage.setItem('sheltechuserid', store?.data?.id)
            }
        }
        if (!store?.formLoading && store?.data?.status === 0) {
            error(store?.data?.message)
            setTimeout(() => {
                dispatch(cleanData())
            }, 300)
        }

    }, [isSubmitSuccessful, store])


    return (
        <HelmetProvider>
            <motion.div key={`1823jj`} className="page-loader" exit="exit" animate="anim"
                        style={{height: `${document.body.clientHeight}px`}}
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sign In | Sheltech Pvt Ltd</title>
            </Helmet>

            <StyledComponent className={'user pt-200 pb-200'}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Title margin={'0 0 60px 0'} color={"#08082B"} text={'User Sign in'}/>
                        </Col>

                        <Col md={6} sm={12}>
                            <Form>
                                <Form.Group className={'from-group'}>
                                    <Form.Control {...register('email', {
                                        required: {
                                            value: true,
                                            message: "Please enter a valid email address"
                                        },
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Please enter a valid email address"
                                        }
                                    })} type="email*" placeholder="Email*"/>
                                    <p className={'form-error'}>{errors.email?.message}</p>
                                </Form.Group>
                                <Form.Group className={'from-group'}>
                                    <Form.Control {...register('password', {
                                        required: {
                                            value: true,
                                            message: "Please enter password"
                                        },

                                    })} type="password" placeholder="Password*"/>
                                    <p className={'form-error'}>{errors.password?.message}</p>
                                </Form.Group>
                                <div style={{width: 'fit-content'}} onClick={handleSubmit(handleFormSubmit)}>
                                    <Button color={'#08082B'} borderColor={"#08082B"} hoverColor={"#FFF"}
                                            hoverBackground={"#08082B"}
                                            submitButton
                                            margin={'60px 0 0 0'}
                                            text={'Sign In'}/>
                                </div>
                            </Form>

                            <div className="user-alter">
                                <p>Do not have an account? <Link to={'/user/sign-up'}> Sign Up</Link></p>
                                {/*<p>Forgot your password? <Link to={'/user/reset-password'}> Recover your account </Link>*/}
                                {/*</p>*/}
                            </div>


                        </Col>
                    </Row>

                </Container>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  //background-color: #08082B;
  background-color: rgb(214, 214, 214);

  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}

  .from-group {
    margin-bottom: 25px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #08082B;
    padding-left: 0;
    height: 45px;
      line-height: unset;
      margin: 0;
      transform: none;
    //margin-bottom: 25px;
    color: #08082B;
      &:hover{
          line-height: unset;
          margin: 0;
          transform: none; 
      }
      &:focus{
          line-height: unset;
          margin: 0;
          transform: none;
      }

    &::placeholder {
      font-size: 15px;
      color: #08082B;
    }
  }

  input:-webkit-autofill {
    // -webkit-text-fill-color: ${whites} !important;
    // border-bottom: 1px solid #F5F5F5;

    &:hover {
      border-bottom: 1px solid #000 !important;
    }

    &:focus {
      border-bottom: 1px solid #000 !important;
    }
  }

  .user-alter {
    margin-top: 60px;

    p {
      color: #08082B;

      &:nth-of-type(1) {
        margin-bottom: 20px;
      }
    }

    a {
      color: #ec1c24;
      text-decoration: underline;

      &:hover {
        color: #08082B !important;
      }
    }
  }



`;

export default MyComponent;
