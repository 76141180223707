import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import disableScroll from "disable-scroll";

const MyComponent = ({ data }) => {
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    console.log("top:", top, "left:", left, "width:", width);
  }, [left, width, top]);

  useEffect(() => {
    let tl = gsap.timeline();
    let tl2 = gsap.timeline();
    tl.to(".promotional-popup", {
      duration: 0,
      display: "flex",
      delay: 3,
      onComplete: () => {
        disableScroll.on();
        setLeft(document.querySelector(".main-img").offsetLeft);
        setWidth(document.querySelector(".main-img").clientWidth);
        setTop(document.querySelector(".main-img").offsetTop);
      },
    })
      .to(".promotional-popup", {
        opacity: 1,
      })
      .to(".popup-close", {
        duration: 0.3,
        opacity: 1,
      })
      .to(".promotional-popup", {
        opacity: 0,
        delay: 10,
        duration: 0.4,
      })
      .to(".promotional-popup", {
        display: "none",
        duration: 0,
      })
      .to(".popup-close", {
        duration: 0,
        opacity: 0,
        onComplete(e) {
          disableScroll.off();
        },
      });

    document
      .querySelector(".popup-close")
      .addEventListener("click", function () {
        disableScroll.off();
        tl2
          .to(".promotional-popup", {
            opacity: 0,
            duration: 0.4,
          })
          .to(".promotional-popup", {
            display: "none",
            duration: 0,
          })
          .to(".popup-close", {
            duration: 0,
            opacity: 0,
          });
      });
  }, []);

  return (
    <StyledComponent
      className={"promotional-popup"}
      top={top}
      left={left}
      width={width}
    >
      <div className="promotional-popup__img">
        <img
          className="main-img"
          src={data?.images?.list?.[0]?.full_path}
          alt=""
        />
        {data?.images?.list?.[0]?.short_desc && (
          <a href={data?.images?.list?.[0]?.short_desc} />
        )}

        <img
          className={"popup-close"}
          src="/images/static/popup-close.svg"
          alt=""
        />
      </div>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  align-items: center;
  display: flex;
  /* opacity: 0; */

  .promotional-popup__img {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    //height: fit-content;
    position: relative;
    justify-content: center;
    height: 80%;

    .popup-close {
      position: absolute;
      top: ${(p) => p.top}px;
      //right: 5px;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
      left: ${(p) => p.left + p.width + 10}px;
      /* right: 0; */
      margin: auto;
      filter: invert(130%) sepia(0%) saturate(5%) hue-rotate(81deg)
        brightness(97%) contrast(108%);
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    img {
      max-width: 65%;
      object-fit: contain;
    }
  }

  @media (max-width: 767px) {
    .promotional-popup__img {
      width: 100%;

      img:not(.popup-close) {
        width: 80%;
        max-width: 80%;
      }

      .popup-close {
        height: 30px;
        left: unset;
        right: 30px;
        top: 50px;
      }
    }
  }
`;

export default MyComponent;
