import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import CloseButton from "../CloseButton";
import SimpleBar from "simplebar-react";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SliderPrevWhite from "../SliderPrevWhite";
import SliderNextWhite from "../SliderNextWhite";


const MyComponent = ({data}) => {

    const [show, setShow] = useState(false);
    const [popupData, setPopupData] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = (i) => {
        setShow(true);
        setPopupData(i)
    }


    return (
        <StyledComponent className={'associate pt-150 no-line'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title margin={'0 0 80px 0'} text={'Associate Companies'}/>
                    </Col>

                    {data?.posts?.list?.map(i => (
                        <Col onClick={() => handleShow(i)} key={i?.data?.id} md={2} sm={3}
                             className="associate__single">
                            <div className="associate__single__img">
                                <Img src={i?.images?.find(f => f?.is_icon == 'on')?.full_path}/>
                            </div>
                            <div className="associate__single__content">
                                <p>{i?.data?.title}</p>
                            </div>
                        </Col>
                    ))}


                </Row>
            </Container>

            <Modal className={'founder-modal associate-modal'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>

                <Container>
                    {window?.innerWidth > 767 ?

                        <SimpleBar style={{maxHeight: window.innerHeight - 100 + 'px'}}>
                    <Row>

                        <Col md={4}>
                            {/*<h3>{popupData?.data?.title}</h3>*/}
                            <div className="founder-modal__img">
                                <Img
                                    src={popupData?.images?.find(f => f?.thumb == 'on')?.full_path ? popupData?.images?.find(f => f?.thumb == 'on')?.full_path : popupData?.images?.find(f => f?.is_icon == 'on')?.full_path}/>
                            </div>
                            <div className="founder-modal__add">
                                {/*{reactHtmlParser(popupData?.data?.short_desc)}*/}
                                <h6>{reactHtmlParser(popupData?.data?.address)}</h6>

                                {popupData?.data?.phone &&
                                    <p>
                                        <a
                                            href={`callto:${popupData?.data?.phone}`}>   <span> <img
                                            src="/images/static/call.svg" alt=""/></span> {popupData?.data?.phone}</a>
                                    </p>}
                                {popupData?.data?.website &&
                                    <p><a
                                        href={popupData?.data?.website.startsWith('https') ? popupData?.data?.website : `//${popupData?.data?.website}`}
                                        target={"_blank"}> <span><img src="/images/static/web.svg"
                                                                      alt=""/> </span> {popupData?.data?.website}</a>
                                    </p>}
                                {popupData?.data?.facebook && <p><span>
                                    <a href={popupData?.data?.facebook.startsWith('https') ? popupData?.data?.facebook : `//${popupData?.data?.facebook}`}
                                       target={"_blank"}><img
                                    src="/images/static/fb-2.svg"
                                    alt=""/></a></span>
                                </p>}
                                {popupData?.data?.linkedin && <p><span><a href={popupData?.data?.linkedin}
                                                                          target={"_blank"}><img
                                    src="/images/static/in.svg"
                                    alt=""/></a></span>
                                </p>}
                            </div>

                        </Col>

                        <Col md={{span: 7, offset: 1}}>

                                    <h3>{popupData?.data?.title}</h3>
                                    <h4>{reactHtmlParser(popupData?.data?.description)}</h4>
                                    {reactHtmlParser(data?.section_data?.description)}

                                    <div className="associate-modal__slide">
                                        <Swiper loop={true}
                                                spaceBetween={0}
                                                slidesPerView={1}
                                                allowSlideNext={true}
                                                allowSlidePrev={true}
                                                allowTouchMove={true}
                                                speed={500}

                                                navigation={{
                                                    prevEl: '.associate-modal__slide .slider_prev',
                                                    nextEl: '.associate-modal__slide .slider_next',
                                                }}
                                                modules={[Navigation, Autoplay]}
                                                autoplay={{
                                                    delay: 5000,
                                                    disableOnInteraction: false,
                                                }}
                                        >
                                            {popupData?.images?.filter(f => f?.background === "on")?.map(i => (
                                                <SwiperSlide key={i?.id}>
                                                    <div className="associate-modal__slide__single">
                                                        <Img src={i.full_path} alt=""/>
                                                    </div>

                                                </SwiperSlide>
                                            ))
                                            }
                                        </Swiper>

                                        <ul>
                                            <li className={'slider_prev'}><SliderPrevWhite borderColor={'#FFF'}
                                                                                           color={'#FFF'}
                                                                                           hoverBackground={'#FFF'}
                                                                                           hoverColor={'#08082B'}/></li>
                                            <li className={'slider_next'}><SliderNextWhite borderColor={'#FFF'}
                                                                                           color={'#FFF'}
                                                                                           hoverBackground={'#FFF'}
                                                                                           hoverColor={'#08082B'}/></li>
                                        </ul>
                                    </div>

                        </Col>

                    </Row>
                        </SimpleBar>

                        :
                        <Row>

                            <Col md={4}>

                                <div className="founder-modal__img">

                                    <Img
                                        src={popupData?.images?.find(f => f?.thumb == 'on')?.full_path ? popupData?.images?.find(f => f?.thumb == 'on')?.full_path : popupData?.images?.find(f => f?.is_icon == 'on')?.full_path}/>
                                </div>
                                <h3 className={'mobile-title'}>{popupData?.data?.title}</h3>


                            </Col>

                            <Col md={{span: 7, offset: 1}}>

                                <h3 className={'desktop-title'}>{popupData?.data?.title}</h3>
                                <h4>{reactHtmlParser(popupData?.data?.description)}</h4>
                                {reactHtmlParser(data?.section_data?.description)}

                                <div className="associate-modal__slide">
                                    <Swiper loop={true}
                                            spaceBetween={0}
                                            slidesPerView={1}
                                            allowSlideNext={true}
                                            allowSlidePrev={true}
                                            allowTouchMove={true}
                                            speed={500}

                                            navigation={{
                                                prevEl: '.associate-modal__slide .slider_prev',
                                                nextEl: '.associate-modal__slide .slider_next',
                                            }}
                                            modules={[Navigation, Autoplay]}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                    >
                                        {popupData?.images?.filter(f => f?.background === "on")?.map(i => (
                                            <SwiperSlide key={i?.id}>
                                                <div className="associate-modal__slide__single">
                                                    <Img src={i.full_path} alt=""/>
                                                </div>

                                            </SwiperSlide>
                                        ))
                                        }
                                    </Swiper>

                                    <ul>
                                        <li className={'slider_prev'}><SliderPrevWhite borderColor={'#FFF'}
                                                                                       color={'#FFF'}
                                                                                       hoverBackground={'#FFF'}
                                                                                       hoverColor={'#08082B'}/></li>
                                        <li className={'slider_next'}><SliderNextWhite borderColor={'#FFF'}
                                                                                       color={'#FFF'}
                                                                                       hoverBackground={'#FFF'}
                                                                                       hoverColor={'#08082B'}/></li>
                                    </ul>
                                </div>
                                <div className="founder-modal__add">
                                    {/*{reactHtmlParser(popupData?.data?.short_desc)}*/}
                                    <h6>{reactHtmlParser(popupData?.data?.address)}</h6>

                                    {popupData?.data?.phone &&
                                        <p>
                                            <a
                                                href={`callto:${popupData?.data?.phone}`}>   <span> <img
                                                src="/images/static/call.svg" alt=""/></span> {popupData?.data?.phone}
                                            </a>
                                        </p>}
                                    {popupData?.data?.website &&
                                        <p><a
                                            href={popupData?.data?.website.startsWith('https') ? popupData?.data?.website : `//${popupData?.data?.website}`}
                                            target={"_blank"}> <span><img src="/images/static/web.svg"
                                                                          alt=""/> </span> {popupData?.data?.website}
                                        </a>
                                        </p>}
                                    {popupData?.data?.facebook && <p><span><a href={popupData?.data?.facebook}
                                                                              target={"_blank"}><img
                                        src="/images/static/fb-2.svg"
                                        alt=""/></a></span>
                                    </p>}
                                    {popupData?.data?.linkedin && <p><span><a href={popupData?.data?.linkedin}
                                                                              target={"_blank"}><img
                                        src="/images/static/in.svg"
                                        alt=""/></a></span>
                                    </p>}
                                </div>
                            </Col>

                        </Row>
                    }
                </Container>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    //background-color: #D6D6D6;
    //background-color: rgb(214, 214, 214);
    padding-bottom: 120px;

    .associate__single {
        margin-bottom: 40px;

        &__img {
            padding-top: 100%;
            position: relative;
            background-color: #fff;
            display: flex;
            align-items: center;
            cursor: pointer;
            overflow: hidden;

            img {
                position: absolute;
                inset: 0;
                margin: auto;
                max-width: 60%;
                object-fit: contain;
            }

            &:hover {
                img {
                    transform: scale(1.04);
                }
            }
        }

        &__content {
            margin-top: 15px;
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        .associate__single {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    @media (max-width: 600px) {
        .associate__single {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

`;

export default MyComponent;
