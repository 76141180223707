import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import MediaList from "../../components/MediaCenter/MediaList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchPosts} from "../../api/redux/media";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";
import {title} from "../../styles/globalStyleVars";


const MyComponent = () => {

    const dispatch = useDispatch()
    let getPost = useSelector(state => state.mediaReducer);


    // api config
    const endPoints = apiEndPoints.MEDIA
    const params = {
        [ApiParam.type]: '',
        [ApiParam.image]: 'yes',
        [ApiParam.file]: 'yes',
    }
    // api call
    useEffect(() => {
        dispatch(fetchPosts([endPoints, params]))
    }, [])
    const pageData = getPost?.data?.data?.page_data;
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:title" content={pageData?.og_title}/>
                <meta property="og:description" content={pageData?.og_description}/>
                <meta name="description" content={pageData?.meta_description}/>
                <title>
                    {pageData?.meta_title ? pageData?.meta_title : 'Media Center | Sheltech Pvt Ltd.'}
                </title>
            </Helmet>

            <StyledComponent className={'media-center'}>
                <InnerBanner title={getPost && getPost?.data?.data?.page_data?.short_desc}
                             subTitle={getPost && getPost?.data?.data?.page_data?.subtitle}
                             img={getPost && getPost?.data?.data?.page_images?.list?.[0]?.full_path}/>
                <MediaList data={getPost && getPost?.data?.media_center?.list}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
