import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import Title from "./../Title";
import SelectField from "./SelectField";
import GoogleMapsMarkers from "./../home/GoogleMapsMarkers";
import {hover, title, whites} from "../../styles/globalStyleVars";


// const GoogleMap = ({offset, revers, padding, data}) => {
//
// console.log(data)
//     const type = data && data?.map(item1 => ({
//         value: item1?.product_data?.type_title,
//         label: item1?.product_data?.type_title,
//     }));
//
//     const unique_type = Array.from(new Set(type?.map((option) => option.value)))?.map((value) => type.find((option) => option.value === value));
//
//     const status = data && data?.map(item1 => ({
//         value: item1?.product_data?.category_title,
//         label: item1?.product_data?.category_title,
//     }));
//
//     const unique_status = Array.from(new Set(status?.map((option) => option.value)))?.map((value) => status.find((option) => option.value === value));
//
//
//     const area = data && data?.map(item1 => ({
//         value: item1?.product_data?.area,
//         label: item1?.product_data?.area,
//     }));
//
//     const unique_area = Array.from(new Set(area?.map((option) => option.value)))?.map((value) => area.find((option) => option.value === value));
//
//
//     // Define state variables for selected filters
//     const [selectedType, setSelectedType] = useState();
//     const [selectedStatus, setSelectedStatus] = useState();
//     const [selectedArea, setSelectedArea] = useState();
//     const [filteredData, setFilteredData] = useState(data);
//
//
//     useEffect(() => {
//         // Filter the data based on selected filters
//         const newFilteredData = data && data.filter(item => {
//             const typeMatch = !selectedType || item.product_data.type_title === selectedType.value;
//             const statusMatch = !selectedStatus || item.product_data.category_title === selectedStatus.value;
//             const areaMatch = !selectedArea || item.product_data.area === selectedArea.value;
//             return typeMatch && statusMatch && areaMatch;
//         });
//
//         setFilteredData(newFilteredData);
//     }, [selectedType, selectedStatus, selectedArea, data]);
//
//
//     // Callback function to handle selected type
//     const handleTypeSelect = (selectedValue) => {
//         setSelectedType(selectedValue);
//     };
//
// // Callback function to handle selected status
//     const handleStatusSelect = (selectedValue) => {
//         setSelectedStatus(selectedValue);
//     };
//
// // Callback function to handle selected area
//     const handleAreaSelect = (selectedValue) => {
//         setSelectedArea(selectedValue);
//     };
//
//
//     return (
//         <>
//             <StyledGoogleMap offset={offset} className={`BuilHome ${padding ? padding : 'pt-200'}`}>
//                 <div className="noise"/>
//                 <Container className={'margin-bottom-fiv'}>
//                     <Row>
//                         <Col md={12} className={'text-center'}>
//                             <h2 className={"split-up"}>Find your desirable <br/> project's area</h2>
//                         </Col>
//                         <Col md={4}>
//                             <SelectField
//                                 placeholder={'Type'}
//                                 selection={unique_type}
//                                 onSelect={handleTypeSelect}
//                             />
//                         </Col>
//                         <Col md={4}>
//                             <SelectField
//                                 placeholder={'Status'}
//                                 selection={unique_status}
//                                 onSelect={handleStatusSelect}
//                             />
//                         </Col>
//                         <Col md={4}>
//                             <SelectField
//                                 placeholder={'Location'}
//                                 selection={unique_area}
//                                 onSelect={handleAreaSelect}
//                             />
//                         </Col>
//                     </Row>
//                 </Container>
//
//             </StyledGoogleMap>
//             <StyledMap className="map">
//                 <GoogleMapsMarkers data={filteredData}/>
//             </StyledMap>
//         </>
//     )
// };
const GoogleMap = ({ offset, revers, padding, data }) => {

    // const type = data && data.map(item1 => ({
    //     value: item1?.product_data?.type_title,
    //     label: item1?.product_data?.type_title,
    // }));

    const type = data && data.flatMap(item1 =>
        item1?.product_data?.types?.map(typeItem => ({
            value: typeItem.title,
            label: typeItem.title,
        })) || []
    );

    // const unique_type = Array.from(new Set(type?.map((option) => option.value)))
    //     .map((value) => type.find((option) => option.value === value));
    const unique_type = Array.from(new Set(type?.map((option) => option.value)))
        .map((value) => type.find((option) => option.value === value));

    const status = data && data.map(item1 => ({
        value: item1?.product_data?.category_title,
        label: item1?.product_data?.category_title,
    }));

    const unique_status = Array.from(new Set(status?.map((option) => option.value)))
        .map((value) => status.find((option) => option.value === value));

    const area = data && data.map(item1 => ({
        value: item1?.product_data?.area,
        label: item1?.product_data?.area,
    }));

    const unique_area = Array.from(new Set(area?.map((option) => option.value)))
        .map((value) => area.find((option) => option.value === value))
        .sort((a, b) => a.value.localeCompare(b.value));

    // Define state variables for selected filters
    const [selectedType, setSelectedType] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        const newFilteredData = data && data.filter(item => {
            const typeMatch = !selectedType?.value ||
                item.product_data.types.some(type => type.title === selectedType.value);
            const statusMatch = !selectedStatus?.value ||
                item.product_data.category_title === selectedStatus.value;
            const areaMatch = !selectedArea?.value ||
                item.product_data.area === selectedArea.value;
            return typeMatch && statusMatch && areaMatch;
        });

        setFilteredData(newFilteredData);
    }, [selectedType, selectedStatus, selectedArea, data]);


    return (
        <>
            <StyledGoogleMap offset={offset} className={`BuilHome ${padding ? padding : 'pt-200'}`}>
                <div className="noise" />
                <Container className={'margin-bottom-fiv'}>
                    <Row>
                        <Col md={12} className={'text-center'}>
                            <h2 className={"split-up"}>Find your desirable <br /> project's area</h2>
                        </Col>
                        <Col md={4}>
                            {/*<SelectField*/}
                            {/*    placeholder={'Type'}*/}
                            {/*    selection={unique_type}*/}
                            {/*    onSelect={setSelectedType}*/}
                            {/*    selectedOption={selectedType}*/}
                            {/*    onClear={() => setSelectedType(null)}*/}
                            {/*/>*/}
                            <SelectField
                                placeholder={'Type'}
                                selection={unique_type}
                                onSelect={setSelectedType}
                                selectedOption={selectedType}
                                onClear={() => setSelectedType(null)}
                            />
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Status'}
                                selection={unique_status}
                                onSelect={setSelectedStatus}
                                selectedOption={selectedStatus}
                                onClear={() => setSelectedStatus(null)}
                            />
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Location'}
                                selection={unique_area}
                                onSelect={setSelectedArea}
                                selectedOption={selectedArea}
                                onClear={() => setSelectedArea(null)}
                            />
                        </Col>
                    </Row>
                </Container>
            </StyledGoogleMap>
            <StyledMap className="map">
                <GoogleMapsMarkers data={filteredData} />
            </StyledMap>
        </>
    );
};

const StyledGoogleMap = styled.div`
    position: relative;
    //background: #26201E;
    background: #F5F5F5;
    //overflow: hidden;

    .margin-bottom-fiv {
        padding-bottom: 40px;
    }

    .noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;

        &:after {
            content: '';
            background-image: url('/images/static/background-noise.png');
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 0.2;
        }

    }

    .map {
        height: 70vh;
    }

    h2 {
        color: #08082b;
        font-size: 44px;
        line-height: 52px;
        font-family: ${title};
        text-transform: uppercase;
        margin-bottom: 70px;
    }

    .filter__control {
        color: #08082b;

    }

    .filter__single-value {
        color: #08082b;
    }

    .filter__control.filter__control--is-focused {
        border-bottom-color: #08082B !important;

        .filter__placeholder {
            //color: #08082B;
            color: #08082b;
        }

        .filter__single-value {
            //color: #08082B
            color: #08082b;
        }
    }

    .filter__control {
        border-bottom-color: #08082B !important;

        .filter__placeholder {
            color: #08082b;
        }
    }

    .filter__indicator svg line {
        stroke: #08082B !important;
    }

    @media (max-width: 767px) {
        .text-center {
            text-align: left !important;

            h2 {
                font-size: 30px;
                line-height: 37px;
            }
        }
    }

`;
const StyledMap = styled.div`

    height: 70vh;

    .gm-style .gm-style-iw-c {
        position: absolute;
        box-sizing: border-box;
        overflow: hidden;
        top: 0;
        left: 0;
        transform: translate3d(-50%, -100%, 0);
        background-color: ${hover};
        padding: 12px;
        box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .3);
        min-width: 370px !important;
        min-height: 125px;
        padding: 40px 20px 20px;

        ul {
            display: flex;

            li {
                font-size: 12px;
                font-weight: 500;
                color: #F1EEE9;
                text-transform: uppercase;
                position: relative;
                padding-left: 20px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                &:after {
                    content: '';
                    height: 5px;
                    width: 5px;
                    background: #F1EEE9;
                    position: absolute;
                    right: -12px;
                    top: 5px;
                    border-radius: 50%;

                }

            }
        }

        p {
            font-size: 20px;
            font-weight: 500;
            color: #F1EEE9;
            width: 85%;
            line-height: 25px;
        }

        .map-button {
            display: block;
            margin: 20px 0;
            transition: 0.7s all ease;

            svg {
                transition: 0.7s all ease;
            }

            &:hover {
                transform: translateX(20px);

            }
        }
    }

    .gm-ui-hover-effect > span {
        background-color: #F9F5F2;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: ${hover};

    }

    .gm-style .gm-style-iw-d {
        background-color: ${hover};

        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    @media (max-width: 767px) {
        height: 70vh;

    }
`

export default React.memo(GoogleMap);
