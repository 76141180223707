import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import TextImage from "../../components/TextImage";
import MissionVision from "../../components/about/MissionVision";
import WhatMakeBest from "../../components/about/WhatMakeBest";
import Team from "../../components/about/Team";
import ManagementTeam from "../../components/about/ManagementTeam";
import AssociateCompanies from "../../components/about/AssociateCompanies";
import AwardSlider from "../../components/about/AwardSlider";
import CSR from "../../components/about/CSR";
import Founder from "../../components/about/Founder";
import Counter from "../../components/about/Counter";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchPosts } from "../../api/redux/about";
import { useDispatch, useSelector } from "react-redux";
import { PageAnimation } from "../../components/animations/PageTransition";
import { motion } from "framer-motion";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import {title} from "../../styles/globalStyleVars";

const MyComponent = () => {
  const dispatch = useDispatch();
  let getPost = useSelector((state) => state.aboutReducer);

  // api config
  const endPoints = apiEndPoints.SECTIONS;
  const params = {
    [ApiParam.type]: "slug",
    [ApiParam.value]: "about-us",
    [ApiParam.get_section]: "yes",
    [ApiParam.image]: "yes",
    [ApiParam.post]: "yes",
  };

  // api call
  useEffect(() => {
    dispatch(fetchPosts([endPoints, params]));
  }, []);

  // data refactor

  const bannerData = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 80
  );
  const aboutOverview = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 81
  );
  const awardsData = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 88
  );
  const Csr = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 89
  );
  const Associate = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 87
  );
  const bestData = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 82
  );
  const counterData = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 83
  );
  const FounderData = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 84
  );
  const Leaders = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 85
  );
  const managementTeam = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 86
  );
  const missionVision = getPost?.data?.data?.sections?.find(
    (f) => f?.section_data?.id == 65
  );

  const csrList = getPost?.data?.csr?.list;
  const leaderList = getPost?.data?.team?.list;

  //Leaders List Start
  const leaders = leaderList?.filter(
    (item) => item?.page_data?.role === "leader"
  );

  //Management List Start
  const managementList = leaderList?.filter(
    (item) => item?.page_data?.role === "management"
  );

  const pageData = getPost?.data?.data?.page_data;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta property="og:title" content={pageData?.og_title}/>
        <meta property="og:description" content={pageData?.og_description}/>
        <meta name="description" content={pageData?.meta_description}/>
        <title>
          {pageData?.meta_title ? pageData?.meta_title : 'About | Sheltech Pvt Ltd.' }
        </title>
      </Helmet>

      <StyledComponent className={"about-page"}>
        <InnerBanner
          title={bannerData?.section_data?.short_desc}
          subTitle={bannerData?.section_data?.subtitle}
          img={bannerData?.images?.list?.[0]?.full_path}
        />
        <TextImage data={aboutOverview} />
        <Counter data={counterData} />
        <MissionVision data={missionVision} />
        <WhatMakeBest data={bestData} />
        {/* <AwardSlider data={awardsData} /> */}
        <AssociateCompanies data={Associate} />
        <CSR list={csrList} data={Csr} />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  background-color: #f5f5f5;

  @media (max-width: 600px) {
    .team-single {
      margin-bottom: 30px;
    }
  }

  //section {
  //  margin-bottom: -2px;
  //  overflow: hidden;
  //}
`;

export default MyComponent;
